// src/pages/PortfolioArena/RhoModeling.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  Option,
  CircularProgress,
  Slider,
} from '@mui/joy';
import { FormControl } from '@mui/material';
import PerformanceChart from '../../../components/charts/PerformanceChart';
import ConfigurableInlineAssetFolder from './ConfigurableInlineAssetFolder';
import {
  granularityOptions,
  generateRhoPortfolioWeights,
} from '../../../data/assetClassDataGenerators';

const RhoModeling = ({
  selectedAssets = [],
  clusteringOptions,
  rhoModelOptions,
  setRhoModelOptions,
}) => {
  const [granularity, setGranularity] = useState('1Y');
  const [runningTests, setRunningTests] = useState({});
  const [nodeResults, setNodeResults] = useState({});
  const [selectedModelRun, setSelectedModelRun] = useState('');
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [rhoAggressiveness, setRhoAggressiveness] = useState(5); // Scale from 0 to 10

  useEffect(() => {
    if (rhoModelOptions) {
      setNodeResults((prev) => ({ ...prev, ...rhoModelOptions }));
      const modelRunPaths = Object.keys(rhoModelOptions);
      if (modelRunPaths.length > 0) {
        setSelectedModelRun(modelRunPaths[modelRunPaths.length - 1]);
      }
    }
  }, [rhoModelOptions]);

  const toggleNode = (pathKey) => {
    setExpandedNodes((prev) => {
      const next = new Set(prev);
      if (next.has(pathKey)) {
        next.delete(pathKey);
      } else {
        next.add(pathKey);
      }
      return next;
    });
  };

  const handleRunModelForNode = (nodePath, assets) => {
    if (!assets || assets.length === 0) return;

    setRunningTests((prev) => ({ ...prev, [nodePath]: true }));

    // Simulate rho modeling using data generators
    setTimeout(() => {
      // Generate Rho Portfolio Weights
      const rhoWeightsData = generateRhoPortfolioWeights(
        assets,
        granularity,
        rhoAggressiveness
      );

      const rhoData = {
        chartData: rhoWeightsData,
      };

      // Update results specific to this node
      setRhoModelOptions({
        ...rhoModelOptions,
        [nodePath]: rhoData,
      });

      setRunningTests((prev) => ({ ...prev, [nodePath]: false }));
    }, 2000);
  };

  const handleSelectModelRun = (event, newValue) => {
    setSelectedModelRun(newValue);
  };

  const handleRhoAggressivenessChange = (event, newValue) => {
    setRhoAggressiveness(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography level="h5" sx={{ mb: 2 }}>
        Rho Modeling
      </Typography>

      {/* Rho Aggressiveness Slider */}
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 1 }}>
          Rho Aggressiveness: {rhoAggressiveness}
        </Typography>
        <Slider
          value={rhoAggressiveness}
          min={0}
          max={10}
          step={1}
          onChange={handleRhoAggressivenessChange}
          valueLabelDisplay="auto"
          marks={[
            { value: 0, label: '0' },
            { value: 5, label: '5' },
            { value: 10, label: '10' },
          ]}
        />
      </Box>

      {/* Granularity Selector */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            placeholder="Select Granularity"
            value={granularity}
            onChange={(e, newValue) => setGranularity(newValue)}
          >
            {granularityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Folder Structure Display */}
      <Box sx={{ mb: 4 }}>
        {clusteringOptions?.assetHierarchy ? (
          <ConfigurableInlineAssetFolder
            node={clusteringOptions.assetHierarchy}
            expandedNodes={expandedNodes}
            toggleNode={toggleNode}
            handleRunModelForNode={handleRunModelForNode}
            runningTests={runningTests}
            selectedAssets={selectedAssets}
          />
        ) : (
          <Typography>No asset hierarchy available.</Typography>
        )}
      </Box>

      {/* Model Run Dropdown Filter */}
      {Object.keys(nodeResults).length > 0 && (
        <Box sx={{ mb: 4 }}>
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              placeholder="Select Model Run to View"
              value={selectedModelRun}
              onChange={handleSelectModelRun}
            >
              {Object.keys(nodeResults).map((nodePath) => (
                <Option key={nodePath} value={nodePath}>
                  {nodePath}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Display Selected Model Run Results */}
      {selectedModelRun && nodeResults[selectedModelRun] && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {selectedModelRun}
          </Typography>
          <Box sx={{ height: 600, marginBottom: 4 }}>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#000000',
              }}
            >
              Risk-Free Rate Weight Over Time
            </Typography>
            <PerformanceChart
              data={nodeResults[selectedModelRun].chartData}
              granularity={granularity}
              yAxisLabel="Risk-Free Weight (%)"
              tooltipFormat={(value) => `${(value * 100).toFixed(2)}%`}
            />
          </Box>
        </Box>
      )}

      {/* Display Loading Indicator for Running Tests */}
      {Object.values(runningTests).some((isRunning) => isRunning) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default RhoModeling;
