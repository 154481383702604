// src/pages/PortfolioArena/ConfigurableInlineAssetFolder.js

import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/joy';
import { ChevronDown, ChevronRight } from 'lucide-react';

const ConfigurableInlineAssetFolder = ({
  node,
  path = [],
  depth = 0,
  expandedNodes,
  toggleNode,
  handleRunModelForNode, // Renamed prop
  runningTests,
  selectedAssets,
}) => {
  const renderNode = (node, path = [], depth = 0) => {
    return Object.entries(node || {}).map(([key, subNode]) => {
      const currentPath = [...path, key];
      const pathKey = currentPath.join(' > ');
      const isExpanded = expandedNodes.has(pathKey);

      if (Array.isArray(subNode)) {
        // This is a leaf node (group of assets)
        const filteredAssets = subNode.filter((asset) => selectedAssets.includes(asset));

        return (
          <Box
            key={pathKey}
            sx={{
              pl: depth * 3,
              py: 1,
              cursor: 'pointer',
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                sx={{
                  flex: 1,
                  fontSize: '0.9rem',
                  color: 'text.secondary',
                }}
              >
                {key.replace(/_/g, ' ')} ({filteredAssets.length} assets)
              </Typography>
              <Button
                variant="solid"
                color="primary"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRunModelForNode(pathKey, filteredAssets, 'leaf'); // Updated handler name
                }}
                disabled={runningTests[pathKey]}
                startDecorator={runningTests[pathKey] ? <CircularProgress size="sm" /> : null}
                sx={{ minWidth: '120px' }}
              >
                {runningTests[pathKey] ? 'Running...' : 'Run Model'}
              </Button>
            </Box>
          </Box>
        );
      }

      // This is a theme (non-leaf node)
      const immediateSubcategories = Object.keys(subNode || {});

      return (
        <Box key={pathKey}>
          <Box
            onClick={() => toggleNode(pathKey)}
            sx={{
              pl: depth * 3,
              py: 1,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
            }}
          >
            {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            <Typography
              sx={{
                fontWeight: depth === 0 ? 600 : 400,
                color: depth === 0 ? 'text.primary' : 'text.secondary',
              }}
            >
              {key.replace(/_/g, ' ')}
            </Typography>
            <Button
              variant="solid"
              color="primary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                handleRunModelForNode(pathKey, immediateSubcategories, 'theme'); // Updated handler name
              }}
              disabled={runningTests[pathKey]}
              startDecorator={runningTests[pathKey] ? <CircularProgress size="sm" /> : null}
              sx={{ minWidth: '120px', ml: 'auto' }}
            >
              {runningTests[pathKey] ? 'Running...' : 'Run Model'}
            </Button>
          </Box>
          {isExpanded && (
            <Box>
              {renderNode(subNode, currentPath, depth + 1)}
            </Box>
          )}
        </Box>
      );
    });

    return <Box>{renderNode(node, path, depth)}</Box>;
  };

  return <Box>{renderNode(node, path, depth)}</Box>;
};

export default ConfigurableInlineAssetFolder;