import React from 'react';
import { Box, Button, Link, Sheet } from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const TabBar = () => {
  const navLinks = [
    { label: 'Systematic', href: '/#auto-etf' },
    { label: 'Fund', href: '/#core-fund' },
    { label: 'Approach', href: '/#philosophy' },
    { label: 'Performance', href: '/#performance' },
    { label: 'Steps', href: '/#steps' },
    { label: 'Reviews', href: '/#reviews' },
    { label: 'FAQ', href: '/#faq' },
    { label: 'Contact', href: '/#cta' },
  ];

  return (
    <Sheet
      variant="soft"
      color="neutral"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
        boxShadow: 'sm',
        bgcolor: 'background.surface',
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '90%',
          margin: '0 auto',
          width: '100%',
          py: 1.5,
          px: 3,
        }}
      >
        {/* Logo */}
        <Link href="/" sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Algoinvest Logo" style={{ height: '45px' }} />
        </Link>

        {/* Navigation Links */}
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            gap: 3,
            alignItems: 'center',
          }}
        >
          {navLinks.map((link) => (
            <Link
              key={link.label}
              href={link.href}
              underline="none"
              sx={{
                fontWeight: '500',
                fontSize: '1.15rem',
                color: 'text.primary',
                '&:hover': { color: 'success.500' },
                transition: 'color 0.2s ease',
                cursor: 'pointer',
              }}
            >
              {link.label}
            </Link>
          ))}
        </Box>

        {/* Auth Buttons */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            component={RouterLink}
            to="/login"
            variant="outlined"
            color="success"
            sx={{
              fontSize: '1.15rem',
              padding: '10px 20px',
              fontWeight: '650',
              borderColor: '#40826D',
              borderRadius:'50px',
              color: '#40826D',
              // color: 'success.500',
              transition: 'background-color 0.3s ease, color 0.3s ease',
              '&:hover': {
                backgroundColor: '#e6f7f2',
              },
            }}
          >
            Log In
          </Button>
          <Button
            component={RouterLink}
            to="/signup"
            variant="solid"
            color="success"
            sx={{
              fontSize: '1.15rem',
              padding: '5px 15px',
              fontWeight: '650',
              bgcolor: '#40826D',
              borderColor: '#ffffff',
              borderWidth: '10px',
              borderRadius:'30px',
              transition: 'background-color 0.3s ease, color 0.3s ease',
              '&:hover': {
                backgroundColor: '#478778',
              },
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Sheet>
  );
};

export default TabBar;
