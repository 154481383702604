// ResultsSection.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemDecorator,
  ListItemContent,
  ListDivider,
  Button,
  Modal,
  ModalDialog,
  ModalClose,
  Input,
  Stack,
  Select,
  Option,
} from '@mui/joy';
import { FaChartLine, FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { getAccounts } from '../../utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ResultsSection = () => {
  const [accounts, setAccounts] = useState([]);
  const [openAddFundsModal, setOpenAddFundsModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  // Fetch accounts on component mount
  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const fetchedAccounts = await getAccounts();
      setAccounts(fetchedAccounts);
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
      toast.error('Failed to load accounts.');
      // Use hardcoded accounts as fallback
      setAccounts([
        {
          id: 1,
          name: 'Bank of America',
          account_type: 'Checking',
        },
        {
          id: 2,
          name: 'Fidelity',
          account_type: 'Investment',
        },
      ]);
    }
  };

  // Hardcoded funds data
  const fundsData = {
    1: {
      'Core Fund': { invested: 10000, gained: 500 },
      'AI Fund': { invested: 5000, gained: 200 },
    },
    2: {
      'Core Fund': { invested: 15000, gained: 750 },
      'AI Fund': { invested: 7000, gained: 350 },
    },
  };

  const handleAddFunds = (account) => {
    setSelectedAccount(account);
    setOpenAddFundsModal(true);
  };

  // Formik for Add Funds
  const addFundsFormik = useFormik({
    initialValues: {
      fund: '',
      amount: '',
    },
    validationSchema: Yup.object({
      fund: Yup.string().required('Please select a fund'),
      amount: Yup.number()
        .typeError('Amount must be a number')
        .positive('Amount must be positive')
        .required('Please enter an amount'),
    }),
    onSubmit: (values) => {
      // Implement the logic to add funds
      toast.success(
        `Added $${values.amount} to ${values.fund} in account ${selectedAccount.name}`
      );
      setOpenAddFundsModal(false);
      addFundsFormik.resetForm();
      // Update fundsData or refetch data to reflect changes
    },
  });

  return (
    <Box sx={{ padding: 3, backgroundColor: '#fefefe' }}>
      <Typography
        level="h5"
        gutterBottom
        sx={{ fontWeight: 'bold', color: '#40826D', marginBottom: 3 }}
      >
        Investment Results
      </Typography>

      {accounts.map((account) => (
        <Card
          key={account.id}
          sx={{
            marginBottom: 2,
            backgroundColor: '#ffffff',
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography level="h5" sx={{ color: '#40826D', fontWeight: 'bold' }}>
                {account.name} ({account.account_type})
              </Typography>
              <Button
                variant="solid"
                color="primary"
                startDecorator={<FaPlusCircle />}
                onClick={() => handleAddFunds(account)}
                sx={{
                  borderRadius: '30px',
                  padding: '8px 20px',
                  fontWeight: '600',
                  fontSize: '1rem',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#478778',
                  },
                }}
              >
                Add Funds
              </Button>
            </Box>
            <List sx={{ marginTop: 2 }}>
              {/* Header Row */}
              <ListItem>
                <ListItemDecorator>
                  <FaChartLine style={{ color: '#40826D' }} />
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="body2" fontWeight="bold">
                    Fund
                  </Typography>
                </ListItemContent>
                <Typography level="body2" fontWeight="bold" sx={{ width: '150px' }}>
                  Amount Invested
                </Typography>
                <Typography level="body2" fontWeight="bold" sx={{ width: '150px' }}>
                  Amount Gained
                </Typography>
              </ListItem>
              <ListDivider />
              {['Core Fund', 'AI Fund'].map((fund) => (
                <ListItem key={fund}>
                  <ListItemDecorator />
                  <ListItemContent>{fund}</ListItemContent>
                  <Typography sx={{ width: '150px' }}>
                    $
                    {fundsData[account.id] && fundsData[account.id][fund]
                      ? fundsData[account.id][fund].invested.toLocaleString()
                      : '0'}
                  </Typography>
                  <Typography sx={{ width: '150px' }}>
                    $
                    {fundsData[account.id] && fundsData[account.id][fund]
                      ? fundsData[account.id][fund].gained.toLocaleString()
                      : '0'}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      ))}

      {/* Add Funds Modal */}
      <Modal open={openAddFundsModal} onClose={() => setOpenAddFundsModal(false)}>
        <ModalDialog aria-labelledby="add-funds-dialog-title" size="md">
          <ModalClose />
          <Typography
            id="add-funds-dialog-title"
            level="h6"
            sx={{ color: '#40826D', fontWeight: 'bold' }}
          >
            Add Funds to {selectedAccount && selectedAccount.name}
          </Typography>
          <form onSubmit={addFundsFormik.handleSubmit}>
            <Stack spacing={2}>
              <Select
                name="fund"
                placeholder="Select Fund"
                value={addFundsFormik.values.fund}
                onChange={(e, value) => {
                  addFundsFormik.setFieldValue('fund', value);
                }}
                onBlur={addFundsFormik.handleBlur}
                error={addFundsFormik.touched.fund && Boolean(addFundsFormik.errors.fund)}
                sx={{
                  backgroundColor: '#ffffff',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: '#e6f7f2',
                  },
                }}
              >
                <Option value="">Select Fund</Option>
                <Option value="Core Fund">Core Fund</Option>
                <Option value="AI Fund">AI Fund</Option>
              </Select>
              {addFundsFormik.touched.fund && addFundsFormik.errors.fund && (
                <Typography color="danger" fontSize="sm">
                  {addFundsFormik.errors.fund}
                </Typography>
              )}
              <Input
                fullWidth
                id="amount"
                name="amount"
                placeholder="Amount"
                value={addFundsFormik.values.amount}
                onChange={addFundsFormik.handleChange}
                onBlur={addFundsFormik.handleBlur}
                error={
                  addFundsFormik.touched.amount && Boolean(addFundsFormik.errors.amount)
                }
                sx={{
                  backgroundColor: '#ffffff',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: '#e6f7f2',
                  },
                }}
              />
              {addFundsFormik.touched.amount && addFundsFormik.errors.amount && (
                <Typography color="danger" fontSize="sm">
                  {addFundsFormik.errors.amount}
                </Typography>
              )}
            </Stack>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <Button
                onClick={() => setOpenAddFundsModal(false)}
                variant="plain"
                color="neutral"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="solid"
                color="primary"
                disabled={!addFundsFormik.isValid || !addFundsFormik.dirty}
              >
                Add Funds
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
    </Box>
  );
};

export default ResultsSection;
