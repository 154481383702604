import React, { useState } from 'react';
import { Box, Typography, Button, Modal, ModalDialog, ModalClose, Input, Stack } from '@mui/joy';
import { FaLock, FaShieldAlt } from 'react-icons/fa';
import { changePassword, enable2FA, verify2FA } from '../../utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';


const SecuritySection = () => {
 const [openPasswordModal, setOpenPasswordModal] = useState(false);
 const [open2FAModal, setOpen2FAModal] = useState(false);
 const [twoFactorSecret, setTwoFactorSecret] = useState('');


 // Formik for Change Password
 const passwordFormik = useFormik({
   initialValues: {
     old_password: '',
     new_password: '',
     confirm_password: '',
   },
   validationSchema: Yup.object({
     old_password: Yup.string().required('Old password is required'),
     new_password: Yup.string().required('New password is required'),
     confirm_password: Yup.string()
       .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
       .required('Confirm password is required'),
   }),
   onSubmit: async (values) => {
     try {
       await changePassword(values.old_password, values.new_password);
       toast.success('Password changed successfully!');
       setOpenPasswordModal(false);
       passwordFormik.resetForm();
       window.location.reload();
     } catch (error) {
       console.error('Failed to change password:', error);
       toast.error('Failed to change password. Please try again.');
     }
   },
 });


 // Formik for Verify 2FA
 const twoFAFormik = useFormik({
   initialValues: {
     code: '',
   },
   validationSchema: Yup.object({
     code: Yup.string().required('2FA code is required'),
   }),
   onSubmit: async (values) => {
     try {
       await verify2FA(values.code);
       toast.success('2FA enabled successfully!');
       setOpen2FAModal(false);
       twoFAFormik.resetForm();
     } catch (error) {
       console.error('Failed to verify 2FA code:', error);
       toast.error('Failed to verify 2FA code. Please try again.');
     }
   },
 });


 const handleEnable2FA = async () => {
   try {
     const response = await enable2FA();
     setTwoFactorSecret(response.secret);
     setOpen2FAModal(true);
     toast.info('Scan the QR code with your authenticator app and enter the code.');
   } catch (error) {
     console.error('Failed to enable 2FA:', error);
     toast.error('Failed to enable 2FA. Please try again.');
   }
 };


 return (
   <Box sx={{ padding: 3, backgroundColor: '#fefefe' }}>
     <Typography
       level="h5"
       gutterBottom
       sx={{ fontWeight: 'bold', color: '#40826D', marginBottom: 3 }}
     >
       Security Settings
     </Typography>


     {/* Change Password Section */}
     <Box
       sx={{
         display: 'flex',
         justifyContent: 'space-between',
         alignItems: 'center',
         backgroundColor: '#ffffff',
         padding: '1rem',
         borderRadius: '8px',
         mb: 2,
         '&:hover': {
           backgroundColor: '#e6f7f2',
         },
       }}
     >
       <Box display="flex" alignItems="center" gap={1}>
         <FaLock size={20} style={{ color: '#40826D' }} />
         <Typography sx={{ color: '#333' }}>Change Password</Typography>
       </Box>
       <Button
         variant="solid"
         color="primary"
         onClick={() => setOpenPasswordModal(true)}
         sx={{
           borderRadius: '30px',
           padding: '8px 20px',
           fontWeight: '600',
           fontSize: '1rem',
           textTransform: 'none',
           '&:hover': {
             backgroundColor: '#478778',
           },
         }}
       >
         Change
       </Button>
     </Box>


     {/* Enable 2FA Section */}
     <Box
       sx={{
         display: 'flex',
         justifyContent: 'space-between',
         alignItems: 'center',
         backgroundColor: '#ffffff',
         padding: '1rem',
         borderRadius: '8px',
         mb: 2,
         '&:hover': {
           backgroundColor: '#e6f7f2',
         },
       }}
     >
       <Box display="flex" alignItems="center" gap={1}>
         <FaShieldAlt size={20} style={{ color: '#40826D' }} />
         <Typography sx={{ color: '#333' }}>Two-Factor Authentication</Typography>
       </Box>
       <Button
         variant="solid"
         color="primary"
         onClick={handleEnable2FA}
         sx={{
           borderRadius: '30px',
           padding: '8px 20px',
           fontWeight: '600',
           fontSize: '1rem',
           textTransform: 'none',
           '&:hover': {
             backgroundColor: '#478778',
           },
         }}
       >
         Enable
       </Button>
     </Box>


     {/* Change Password Modal */}
     <Modal open={openPasswordModal} onClose={() => setOpenPasswordModal(false)}>
       <ModalDialog aria-labelledby="change-password-dialog-title" size="md">
         <ModalClose />
         <Typography id="change-password-dialog-title" level="h6" sx={{ color: '#40826D', fontWeight: 'bold' }}>
           Change Password
         </Typography>
         <form onSubmit={passwordFormik.handleSubmit}>
           <Stack spacing={2}>
             {['old_password', 'new_password', 'confirm_password'].map((field) => (
               <Input
                 key={field}
                 fullWidth
                 id={field}
                 name={field}
                 placeholder={
                   field === 'old_password'
                     ? 'Old Password'
                     : field === 'new_password'
                     ? 'New Password'
                     : 'Confirm New Password'
                 }
                 type="password"
                 value={passwordFormik.values[field]}
                 onChange={passwordFormik.handleChange}
                 onBlur={passwordFormik.handleBlur}
                 error={passwordFormik.touched[field] && Boolean(passwordFormik.errors[field])}
                 helperText={passwordFormik.touched[field] && passwordFormik.errors[field]}
                 sx={{
                   backgroundColor: '#ffffff',
                   borderRadius: '8px',
                   '&:hover': {
                     backgroundColor: '#e6f7f2',
                   },
                 }}
               />
             ))}
           </Stack>
           <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
             <Button onClick={() => setOpenPasswordModal(false)} variant="plain" color="neutral">
               Cancel
             </Button>
             <Button
               type="submit"
               variant="solid"
               color="primary"
               disabled={!passwordFormik.isValid || !passwordFormik.dirty}
             >
               Change Password
             </Button>
           </Box>
         </form>
       </ModalDialog>
     </Modal>


     {/* 2FA Modal */}
     <Modal open={open2FAModal} onClose={() => setOpen2FAModal(false)}>
       <ModalDialog aria-labelledby="enable-2fa-dialog-title" size="md">
         <ModalClose />
         <Typography id="enable-2fa-dialog-title" level="h6" sx={{ color: '#40826D', fontWeight: 'bold' }}>
           Enable Two-Factor Authentication
         </Typography>
         <form onSubmit={twoFAFormik.handleSubmit}>
           <Stack spacing={2}>
             <Typography sx={{ color: '#333' }}>
               Scan the QR code below with your authenticator app and enter the generated code.
             </Typography>
             <Box display="flex" justifyContent="center" my={2}>
               <img
                 src={`https://api.qrserver.com/v1/create-qr-code/?data=${twoFactorSecret}&size=200x200`}
                 alt="2FA QR Code"
               />
             </Box>
             <Input
               fullWidth
               id="code"
               name="code"
               placeholder="Authentication Code"
               type="text"
               value={twoFAFormik.values.code}
               onChange={twoFAFormik.handleChange}
               onBlur={twoFAFormik.handleBlur}
               error={twoFAFormik.touched.code && Boolean(twoFAFormik.errors.code)}
               helperText={twoFAFormik.touched.code && twoFAFormik.errors.code}
               sx={{
                 backgroundColor: '#ffffff',
                 borderRadius: '8px',
                 '&:hover': {
                   backgroundColor: '#e6f7f2',
                 },
               }}
             />
           </Stack>
           <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
             <Button onClick={() => setOpen2FAModal(false)} variant="plain" color="neutral">
               Cancel
             </Button>
             <Button
               type="submit"
               variant="solid"
               color="primary"
               disabled={!twoFAFormik.isValid || !twoFAFormik.dirty}
             >
               Verify 2FA
             </Button>
           </Box>
         </form>
       </ModalDialog>
     </Modal>
   </Box>
 );
};

export default SecuritySection;