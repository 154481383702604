// Philosophy.js

import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import InternalTabBar from '../../../components/tabs/InternalTabBar';
import Overview from './Overview';
import Selection from './Selection';
import Clustering from './Clustering';
import Beta from './Beta';
import Rho from './Rho';
import Alpha from './Alpha';
import Portfolios from './Portfolios';
import Trading from './Trading';
import Taxes from './Taxes';
import Backtesting from './Backtesting';
import Deploy from './Deploy';

const Philosophy = () => {
  const [selectedTab, setSelectedTab] = useState('Overview');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'Overview':
        return <Overview />;
      case 'Selection':
        return <Selection />;
      case 'Clustering':
        return <Clustering />;
      case 'Beta':
        return <Beta />;
      case 'Rho':
        return <Rho />;
      case 'Alpha':
        return <Alpha />;
      case 'Portfolios':
        return <Portfolios />;
      case 'Trading':
        return <Trading />;
      case 'Taxes':
        return <Taxes />;
      case 'Backtesting':
        return <Backtesting />;
      case 'Deploy':
        return <Deploy />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: '#fefefe',
        minHeight: '100vh',
      }}
    >
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          marginLeft: '11%', // Account for the tab bar
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
        }}
      >
        {/* Tabs */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            marginBottom: 4,
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
          }}
        >
          <Tab value="Overview" label="Overview"/>
          <Tab value="Selection" label="Selection"/>
          <Tab value="Clustering" label="Clustering"/>
          <Tab value="Beta" label="Beta" />
          <Tab value="Rho" label="Rho" />
          <Tab value="Alpha" label="Alpha" />
          <Tab value="Portfolios" label="Portfolios"/>
          <Tab value="Trading" label="Trading"/>
          <Tab value="Taxes" label="Taxes" />
          <Tab value="Backtesting" label="Backtesting" />
          <Tab value="Deploy" label="Deploy" />

        </Tabs>

        {/* Rendered Content */}
        {renderContent()}
      </Box>
    </Box>
  );
};

export default Philosophy;
