// src/pages/PortfolioArena/AssetSelection.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Checkbox,
} from '@mui/joy';
import SelectionAssetFolder from './SelectionAssetFolder';

const AssetSelection = ({ selectedAssets, setSelectedAssets, clusteringOptions }) => {
  const [expandedNodes, setExpandedNodes] = useState(new Set());

  const toggleNode = (pathKey) => {
    setExpandedNodes((prev) => {
      const next = new Set(prev);
      if (next.has(pathKey)) {
        next.delete(pathKey);
      } else {
        next.add(pathKey);
      }
      return next;
    });
  };

  const getAllDescendantSymbols = (node) => {
    let symbols = [];
    if (Array.isArray(node)) {
      // Leaf node
      symbols = symbols.concat(node);
    } else {
      // Folder node
      Object.values(node).forEach((subNode) => {
        symbols = symbols.concat(getAllDescendantSymbols(subNode));
      });
    }
    return symbols;
  };

  const getCheckboxState = (node) => {
    const symbols = getAllDescendantSymbols(node);
    const selectedCount = symbols.filter((symbol) => selectedAssets.includes(symbol)).length;
    if (selectedCount === symbols.length) return 'checked';
    if (selectedCount === 0) return 'unchecked';
    return 'indeterminate';
  };

  const handleNodeToggle = (node, isChecked) => {
    const symbols = getAllDescendantSymbols(node);
    if (isChecked) {
      // Select all symbols in the node
      const newSelected = [...new Set([...selectedAssets, ...symbols])];
      setSelectedAssets(newSelected);
    } else {
      // Deselect all symbols in the node
      const newSelected = selectedAssets.filter((symbol) => !symbols.includes(symbol));
      setSelectedAssets(newSelected);
    }
  };

  const handleAssetToggle = (symbol) => {
    if (selectedAssets.includes(symbol)) {
      setSelectedAssets(selectedAssets.filter((s) => s !== symbol));
    } else {
      setSelectedAssets([...selectedAssets, symbol]);
    }
  };

  const renderControls = ({ node, path, depth, isLeaf }) => {
    if (isLeaf) {
      // Asset node
      const symbol = node;
      return (
        <Checkbox
          checked={selectedAssets.includes(symbol)}
          onChange={() => handleAssetToggle(symbol)}
          onClick={(e) => e.stopPropagation()}
          size="sm"
          sx={{ mr: 1 }}
        />
      );
    } else {
      // Folder node
      const checkboxState = getCheckboxState(node);
      return (
        <Checkbox
          checked={checkboxState === 'checked'}
          indeterminate={checkboxState === 'indeterminate'}
          onChange={(e) => handleNodeToggle(node, e.target.checked)}
          onClick={(e) => e.stopPropagation()}
          size="sm"
          sx={{ mr: 1 }}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: '#fefefe',
        maxHeight: '80vh',
        overflowY: 'auto',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: 'bold', color: '#40826D', mb: 3 }}
      >
        Select Assets
      </Typography>

      {clusteringOptions?.assetHierarchy ? (
        <SelectionAssetFolder
          node={clusteringOptions.assetHierarchy}
          expandedNodes={expandedNodes}
          toggleNode={toggleNode}
          renderControls={renderControls}
        />
      ) : (
        <Typography>No asset hierarchy available.</Typography>
      )}
    </Box>
  );
};

export default AssetSelection;