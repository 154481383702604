// src/pages/PortfolioArena/Backtesting.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  Option,
  CircularProgress,
} from '@mui/joy';
import PerformanceChart from '../../../components/charts/PerformanceChart';
import CorrelationHeatmap from '../../../components/charts/CorrelationHeatmap';
import MetricsList from '../../../components/charts/MetricsList';
import ConfigurableInlineAssetFolder from './ConfigurableInlineAssetFolder';
import {
  generateInvestmentData,
  generateCorrelationMatrix,
  getRandomStatistics,
  granularityOptions,
} from '../../../data/assetClassDataGenerators';

const Backtesting = ({
  selectedAssets = [],
  clusteringOptions,
  backtestResults,
  setBacktestResults,
}) => {
  const [granularity, setGranularity] = useState('1Y');
  const [runningTests, setRunningTests] = useState({});
  const [nodeResults, setNodeResults] = useState({});
  const [selectedBacktest, setSelectedBacktest] = useState('');
  const [expandedNodes, setExpandedNodes] = useState(new Set());

  useEffect(() => {
    if (backtestResults) {
      setNodeResults((prev) => ({ ...prev, ...backtestResults }));
      const backtestPaths = Object.keys(backtestResults);
      if (backtestPaths.length > 0) {
        setSelectedBacktest(backtestPaths[backtestPaths.length - 1]);
      }
    }
  }, [backtestResults]);

  const toggleNode = (pathKey) => {
    setExpandedNodes((prev) => {
      const next = new Set(prev);
      if (next.has(pathKey)) {
        next.delete(pathKey);
      } else {
        next.add(pathKey);
      }
      return next;
    });
  };

  const handleRunModelForNode = (nodePath, assets, type) => {
    if (!assets || assets.length === 0) return;

    setRunningTests((prev) => ({ ...prev, [nodePath]: true }));

    // Simulate backtesting using data generators
    setTimeout(() => {
      const updatedChartData = assets.map((asset) => ({
        label: asset,
        data: generateInvestmentData(granularity, 100000, asset),
      }));

      const updatedCorrelationData = generateCorrelationMatrix(assets);
      const updatedMetrics = assets.map((asset) => ({
        name: asset,
        ...getRandomStatistics(),
      }));

      // Update backtest results specific to this node
      setBacktestResults({
        ...backtestResults,
        [nodePath]: {
          chartData: updatedChartData,
          correlationData: updatedCorrelationData,
          metrics: updatedMetrics,
        },
      });

      setRunningTests((prev) => ({ ...prev, [nodePath]: false }));
    }, 2000);
  };

  const handleSelectBacktest = (event, newValue) => {
    setSelectedBacktest(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography level="h5" sx={{ mb: 2 }}>
        Backtesting
      </Typography>

      {/* Granularity Selector */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            placeholder="Select Granularity"
            value={granularity}
            onChange={(e, newValue) => setGranularity(newValue)}
          >
            {granularityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Folder Structure Display */}
      <Box sx={{ mb: 4 }}>
        {clusteringOptions?.assetHierarchy ? (
          <ConfigurableInlineAssetFolder
            node={clusteringOptions.assetHierarchy}
            expandedNodes={expandedNodes}
            toggleNode={toggleNode}
            handleRunModelForNode={handleRunModelForNode} // Updated prop name
            runningTests={runningTests}
            selectedAssets={selectedAssets}
          />
        ) : (
          <Typography>No asset hierarchy available.</Typography>
        )}
      </Box>

      {/* Backtest Dropdown Filter */}
      {Object.keys(nodeResults).length > 0 && (
        <Box sx={{ mb: 4 }}>
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              placeholder="Select Backtest to View"
              value={selectedBacktest}
              onChange={handleSelectBacktest}
            >
              {Object.keys(nodeResults).map((nodePath) => (
                <Option key={nodePath} value={nodePath}>
                  {nodePath}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Display Selected Backtest Results */}
      {selectedBacktest && nodeResults[selectedBacktest] && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {selectedBacktest}
          </Typography>
          <Box sx={{ height: 600, marginBottom: 4 }}>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#000000',
              }}
            >
              Asset Returns
            </Typography>
            {/* Debugging: Log the chartData */}
            {console.log(
              `Performance Chart Data for ${selectedBacktest}:`,
              nodeResults[selectedBacktest].chartData
            )}
            <PerformanceChart
              data={nodeResults[selectedBacktest].chartData}
              granularity={granularity}
              yAxisLabel="Investment Value (USD)"
              tooltipFormat={(value) => `$${value.toFixed(2)}`}
            />
          </Box>

          {nodeResults[selectedBacktest].correlationData.length > 1 && (
            <Box sx={{ marginBottom: 4 }}>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#40826D',
                  marginBottom: 2,
                }}
              >
                Correlation Heatmap
              </Typography>
              <CorrelationHeatmap correlations={nodeResults[selectedBacktest].correlationData} />
            </Box>
          )}

          <Box sx={{ marginBottom: 4 }}>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#40826D',
                marginBottom: 2,
              }}
            >
              Metrics
            </Typography>
            <MetricsList metrics={nodeResults[selectedBacktest].metrics} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Backtesting;