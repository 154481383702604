// src/pages/PortfolioArena/Trading.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  Option,
  CircularProgress,
  Slider,
} from '@mui/joy';
import PerformanceChart from '../../../components/charts/PerformanceChart';
import ConfigurableInlineAssetFolder from './ConfigurableInlineAssetFolder';
import {
  granularityOptions,
  generateTrackingErrorData,
  generateTransactionCostData,
  generateCorrelationMatrix,
  getRandomStatistics,
} from '../../../data/assetClassDataGenerators';

const Trading = ({
  selectedAssets = [],
  clusteringOptions,
  tradingOptions,
  setTradingOptions,
}) => {
  const [granularity, setGranularity] = useState('1Y');
  const [rebalancingFrequency, setRebalancingFrequency] = useState('monthly'); // 'daily', 'weekly', 'monthly', etc.
  const [trackingErrorTarget, setTrackingErrorTarget] = useState(5); // Scale from 1 to 10
  const [runningTests, setRunningTests] = useState({});
  const [nodeResults, setNodeResults] = useState({});
  const [selectedModelRun, setSelectedModelRun] = useState('');
  const [plotType, setPlotType] = useState('trackingError'); // 'trackingError' or 'transactionCost'
  const [expandedNodes, setExpandedNodes] = useState(new Set());

  useEffect(() => {
    if (tradingOptions) {
      setNodeResults((prev) => ({ ...prev, ...tradingOptions }));
      const modelRunPaths = Object.keys(tradingOptions);
      if (modelRunPaths.length > 0) {
        setSelectedModelRun(modelRunPaths[modelRunPaths.length - 1]);
      }
    }
  }, [tradingOptions]);

  const toggleNode = (pathKey) => {
    setExpandedNodes((prev) => {
      const next = new Set(prev);
      if (next.has(pathKey)) {
        next.delete(pathKey);
      } else {
        next.add(pathKey);
      }
      return next;
    });
  };

  const handleRunModelForNode = (nodePath, assets) => {
    if (!assets || assets.length === 0) return;

    setRunningTests((prev) => ({ ...prev, [nodePath]: true }));

    // Simulate trading model using data generators
    setTimeout(() => {
      // Generate Tracking Error Data
      const trackingErrorData = {
        chartData: [
          {
            label: 'Tracking Error',
            data: generateTrackingErrorData(
              granularity,
              rebalancingFrequency,
              trackingErrorTarget
            ),
          },
        ],
        correlationData: [], // Not applicable for tracking error
        metrics: {
          Tracking_Error_Target: `${trackingErrorTarget}%`,
          Rebalancing_Frequency:
            rebalancingFrequency.charAt(0).toUpperCase() +
            rebalancingFrequency.slice(1),
        },
      };

      // Generate Transaction Cost Data
      const transactionCostData = {
        chartData: [
          {
            label: 'Transaction Cost',
            data: generateTransactionCostData(
              granularity,
              rebalancingFrequency,
              trackingErrorTarget
            ),
          },
        ],
        correlationData: [], // Not applicable for transaction costs
        metrics: {
          Total_Transaction_Costs: `$${(Math.random() * 1000).toFixed(2)}`, // Example metric
        },
      };

      // Update results specific to this node
      setTradingOptions({
        ...tradingOptions,
        [nodePath]: {
          trackingError: trackingErrorData,
          transactionCost: transactionCostData,
        },
      });

      setRunningTests((prev) => ({ ...prev, [nodePath]: false }));
    }, 2000);
  };

  const handleSelectModelRun = (event, newValue) => {
    setSelectedModelRun(newValue);
    setPlotType('trackingError'); // Default to tracking error when selecting a new run
  };

  const handlePlotTypeChange = (event, newValue) => {
    setPlotType(newValue);
  };

  const handleRebalancingFrequencyChange = (event, newValue) => {
    setRebalancingFrequency(newValue);
  };

  const handleTrackingErrorTargetChange = (event, newValue) => {
    setTrackingErrorTarget(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography level="h5" sx={{ mb: 2 }}>
        Trading Strategy
      </Typography>

      {/* Rebalancing Frequency Selector */}
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 1 }}>
          Rebalancing Frequency:{' '}
          {rebalancingFrequency.charAt(0).toUpperCase() +
            rebalancingFrequency.slice(1)}
        </Typography>
        <FormControl sx={{ minWidth: 200 }}>
          <Select
            placeholder="Select Rebalancing Frequency"
            value={rebalancingFrequency}
            onChange={(e, newValue) =>
              handleRebalancingFrequencyChange(e, newValue)
            }
          >
            <Option value="daily">Daily</Option>
            <Option value="weekly">Weekly</Option>
            <Option value="monthly">Monthly</Option>
            <Option value="quarterly">Quarterly</Option>
            <Option value="yearly">Yearly</Option>
          </Select>
        </FormControl>
      </Box>

      {/* Tracking Error Target Selector */}
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 1 }}>
          Tracking Error Target: {trackingErrorTarget}%
        </Typography>
        <Slider
          value={trackingErrorTarget}
          min={1}
          max={10}
          step={1}
          onChange={handleTrackingErrorTargetChange}
          valueLabelDisplay="auto"
          marks={[
            { value: 1, label: '1%' },
            { value: 5, label: '5%' },
            { value: 10, label: '10%' },
          ]}
        />
      </Box>

      {/* Granularity Selector */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            placeholder="Select Granularity"
            value={granularity}
            onChange={(e, newValue) => setGranularity(newValue)}
          >
            {granularityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Folder Structure Display */}
      <Box sx={{ mb: 4 }}>
        {clusteringOptions?.assetHierarchy ? (
          <ConfigurableInlineAssetFolder
            node={clusteringOptions.assetHierarchy}
            expandedNodes={expandedNodes}
            toggleNode={toggleNode}
            handleRunModelForNode={handleRunModelForNode}
            runningTests={runningTests}
            selectedAssets={selectedAssets}
          />
        ) : (
          <Typography>No asset hierarchy available.</Typography>
        )}
      </Box>

      {/* Model Run Dropdown Filter */}
      {Object.keys(nodeResults).length > 0 && (
        <Box sx={{ mb: 4 }}>
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              placeholder="Select Model Run to View"
              value={selectedModelRun}
              onChange={handleSelectModelRun}
            >
              {Object.keys(nodeResults).map((nodePath) => (
                <Option key={nodePath} value={nodePath}>
                  {nodePath}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Plot Type Selector */}
      {selectedModelRun && (
        <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              placeholder="Select Plot Type"
              value={plotType}
              onChange={handlePlotTypeChange}
            >
              <Option value="trackingError">Tracking Error</Option>
              <Option value="transactionCost">Transaction Costs</Option>
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Display Selected Model Run Results */}
      {selectedModelRun &&
        nodeResults[selectedModelRun] &&
        nodeResults[selectedModelRun][plotType] && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {selectedModelRun}
            </Typography>
            <Box sx={{ height: 600, marginBottom: 4 }}>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#000000',
                }}
              >
                {plotType === 'trackingError'
                  ? 'Tracking Error Over Time'
                  : 'Transaction Costs Over Time'}
              </Typography>
              <PerformanceChart
                data={nodeResults[selectedModelRun][plotType].chartData}
                granularity={granularity}
                yAxisLabel={
                  plotType === 'trackingError'
                    ? 'Tracking Error (%)'
                    : 'Transaction Costs ($)'
                }
                tooltipFormat={
                  plotType === 'trackingError'
                    ? (value) => `${value.toFixed(2)}%`
                    : (value) => `$${value.toFixed(2)}`
                }
              />
            </Box>

            {/* Display Metrics */}
            {nodeResults[selectedModelRun][plotType].metrics && (
              <Box sx={{ marginBottom: 4 }}>
                <Typography
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: '#40826D',
                    marginBottom: 2,
                  }}
                >
                  Metrics
                </Typography>
                <Box
                  sx={{
                    backgroundColor: '#fefefe',
                    padding: 2,
                    borderRadius: 2,
                  }}
                >
                  {Object.entries(
                    nodeResults[selectedModelRun][plotType].metrics
                  ).map(([key, value], index) => (
                    <Typography key={index} color="#2f3b52">
                      <strong>{key.replace(/_/g, ' ')}:</strong> {value}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        )}

      {/* Display Loading Indicator for Running Tests */}
      {Object.values(runningTests).some((isRunning) => isRunning) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Trading;