// Portfolios.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import InternalTabBar from '../../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Portfolios = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Portfolios
        </Typography>

        {/* Main High-Level Description */}
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Systematic Approach to Building Robust Portfolios
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          Our Portfolio Construction methodology is designed to systematically capture both market and alpha risk premia
          in a robust and diversified manner. We utilize advanced quantitative methods to ensure that our investment
          decisions are data-driven and aligned with financial intuitions.
        </Typography>

        {/* Secondary Section Header */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Our Portfolio Construction
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Our portfolio construction process combines risk parity, alpha integration, and progressive refinement to create a balanced and optimized portfolio.
          </Typography>
        </Box>

        {/* Portfolio Sections */}
        <Box>
          <PortfoliosSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Portfolios;

// PortfoliosSections Component
const PortfoliosSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Portfolio Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <PortfoliosCard title="Risk Parity Foundation">
          <Typography sx={{ color: '#333' }}>
            Our construction process begins with a risk parity foundation, ensuring balanced exposure across asset classes by focusing on risk allocation rather than capital allocation.
          </Typography>
        </PortfoliosCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <PortfoliosCard title="Alpha Integration">
          <Typography sx={{ color: '#333' }}>
            We enhance portfolio performance by integrating diverse alpha signals across our hierarchical framework, capitalizing on market inefficiencies for additional returns.
          </Typography>
        </PortfoliosCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <PortfoliosCard title="Progressive Refinement">
          <Typography sx={{ color: '#333' }}>
            Our portfolios evolve with data-driven enhancements, continuously incorporating new insights and adjusting allocations to optimize performance.
          </Typography>
        </PortfoliosCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <PortfoliosCard title="Advanced Quantitative Methods">
          <Typography sx={{ color: '#333' }}>
            Leveraging advanced quantitative techniques, such as mean-variance optimization and machine learning, we make informed allocation decisions that balance returns and risks.
          </Typography>
        </PortfoliosCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <PortfoliosCard title="Comprehensive Risk Management">
          <Typography sx={{ color: '#333' }}>
            Our multi-layered risk management approach assesses, monitors, and mitigates risks, enhancing long-term portfolio stability and performance.
          </Typography>
        </PortfoliosCard>
      </Grid>
    </Grid>
  </Box>
);

const PortfoliosCard = ({ title, children }) => (
    <Box
      sx={{
        padding: '1.5rem',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        transition: 'transform 0.3s',
        // Removed borderRadius and boxShadow to eliminate the border effect
        '&:hover': {
          transform: 'translateY(-5px)',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: '1.25rem',
          fontWeight: '600',
          color: '#40826D',
          marginBottom: '1rem',
        }}
      >
        {title}
      </Typography>
      <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
        {children}
      </Typography>
    </Box>
  );

PortfoliosCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
