// Clustering.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import InternalTabBar from '../../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Clustering = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
           Clustering
        </Typography>

        {/* Main High-Level Description */}
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Organizing Assets for Optimal Portfolio Management
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          Asset Clustering is essential for managing portfolio complexity and enhancing analytical insights. We group assets into hierarchical clusters based on economic relationships and statistical properties to create a well-structured, balanced portfolio.
        </Typography>

        {/* Secondary Section Header */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Our Clustering Process
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Our clustering methodology is designed to organize assets in a way that enhances portfolio management and performance:
          </Typography>
        </Box>

        {/* Clustering Sections */}
        <Box>
          <ClusteringSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Clustering;

// ClusteringSections Component
const ClusteringSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Clustering Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <ClusteringCard title="Create Hierarchical Structures">
          <Typography sx={{ color: '#333' }}>
            We structure assets into clusters based on economic relationships, sectors, and regions. This layered approach ensures comprehensive coverage and better organization of assets within the portfolio.
          </Typography>
        </ClusteringCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <ClusteringCard title="Flexible Grouping">
          <Typography sx={{ color: '#333' }}>
            Our clustering approach combines expert insights with statistical algorithms, creating groupings that are both intuitive and data-driven for a balanced portfolio composition.
          </Typography>
        </ClusteringCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <ClusteringCard title="Clustering at Every Level">
          <Typography sx={{ color: '#333' }}>
            Our hierarchical clustering spans from broad asset classes down to individual assets, allowing for a more granular and dynamic portfolio structure.
          </Typography>
        </ClusteringCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <ClusteringCard title="Advanced Analytical Tools">
          <Typography sx={{ color: '#333' }}>
            We employ tools like PCA and factor rotation to refine clusters and uncover asset relationships, ensuring that our groupings align with underlying market factors.
          </Typography>
        </ClusteringCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <ClusteringCard title="Continuous Refinement">
          <Typography sx={{ color: '#333' }}>
            Clusters are continuously refined based on market shifts, new data, and portfolio feedback, ensuring that our structure remains relevant and responsive.
          </Typography>
        </ClusteringCard>
      </Grid>
    </Grid>
  </Box>
);

// ClusteringCard Component
const ClusteringCard = ({ title, children }) => (
  <Box sx={{
    padding: '1.5rem',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: 'transform 0.3s',
    // Removed borderRadius and boxShadow to eliminate the border effect
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  }}>
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

ClusteringCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
