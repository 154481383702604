// src/pages/PortfolioArena/AlphaModeling.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  Option,
  CircularProgress,
  Checkbox,
} from '@mui/joy';
import { FormControl, FormControlLabel, FormGroup } from '@mui/material';
import PerformanceChart from '../../../components/charts/PerformanceChart';
import CorrelationHeatmap from '../../../components/charts/CorrelationHeatmap';
import MetricsList from '../../../components/charts/MetricsList';
import ConfigurableInlineAssetFolder from './ConfigurableInlineAssetFolder';
import {
  generateCorrelationMatrix,
  getRandomStatistics,
  granularityOptions,
  generateAlphaFactorReturns,
} from '../../../data/assetClassDataGenerators';

const AlphaModeling = ({
  selectedAssets = [],
  clusteringOptions,
  alphaModelOptions,
  setAlphaModelOptions,
}) => {
  const [granularity, setGranularity] = useState('1Y');
  const [runningTests, setRunningTests] = useState({});
  const [nodeResults, setNodeResults] = useState({});
  const [selectedModelRun, setSelectedModelRun] = useState('');
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [alphaFactorTypes, setAlphaFactorTypes] = useState({
    price: true,
    financials: true,
    news: false,
    machineLearning: false,
    llms: false,
  });

  useEffect(() => {
    if (alphaModelOptions) {
      setNodeResults((prev) => ({ ...prev, ...alphaModelOptions }));
      const modelRunPaths = Object.keys(alphaModelOptions);
      if (modelRunPaths.length > 0) {
        setSelectedModelRun(modelRunPaths[modelRunPaths.length - 1]);
      }
    }
  }, [alphaModelOptions]);

  const toggleNode = (pathKey) => {
    setExpandedNodes((prev) => {
      const next = new Set(prev);
      if (next.has(pathKey)) {
        next.delete(pathKey);
      } else {
        next.add(pathKey);
      }
      return next;
    });
  };

  const handleRunModelForNode = (nodePath, assets) => {
    if (!assets || assets.length === 0) return;

    setRunningTests((prev) => ({ ...prev, [nodePath]: true }));

    // Simulate alpha modeling using data generators
    setTimeout(() => {
      console.log("Selected Alpha Factor Types: ", alphaFactorTypes); // Acknowledge the selected types

      // Generate Alpha Factor Returns
      const factorRatio = 0.3; // Ratio of factors to assets (adjust as needed)
      const n_assets = assets.length;
      const n_factors = Math.max(1, Math.round(n_assets * factorRatio));
      const alphaFactorReturns = generateAlphaFactorReturns(n_factors, granularity);
      const alphaFactorLabels = alphaFactorReturns.map((d) => d.label);

      const alphaFactorData = {
        chartData: alphaFactorReturns, // Each factor has its own data series
        correlationData: generateCorrelationMatrix(alphaFactorLabels),
        metrics: alphaFactorLabels.map((factor) => ({
          name: factor,
          ...getRandomStatistics(),
        })),
      };

      // Update results specific to this node
      setAlphaModelOptions({
        ...alphaModelOptions,
        [nodePath]: alphaFactorData,
      });

      setRunningTests((prev) => ({ ...prev, [nodePath]: false }));
    }, 2000);
  };

  const handleSelectModelRun = (event, newValue) => {
    setSelectedModelRun(newValue);
  };

  const handleAlphaFactorTypeChange = (event) => {
    const { name, checked } = event.target;
    setAlphaFactorTypes((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography level="h5" sx={{ mb: 2 }}>
        Alpha Modeling
      </Typography>

      {/* Alpha Factor Types Selector */}
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 1 }}>Select Alpha Factor Types:</Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={alphaFactorTypes.price}
                onChange={handleAlphaFactorTypeChange}
                name="price"
              />
            }
            label="Price"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={alphaFactorTypes.financials}
                onChange={handleAlphaFactorTypeChange}
                name="financials"
              />
            }
            label="Financials"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={alphaFactorTypes.news}
                onChange={handleAlphaFactorTypeChange}
                name="news"
              />
            }
            label="News"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={alphaFactorTypes.machineLearning}
                onChange={handleAlphaFactorTypeChange}
                name="machineLearning"
              />
            }
            label="Machine Learning"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={alphaFactorTypes.llms}
                onChange={handleAlphaFactorTypeChange}
                name="llms"
              />
            }
            label="LLMs"
          />
        </FormGroup>
      </Box>

      {/* Granularity Selector */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            placeholder="Select Granularity"
            value={granularity}
            onChange={(e, newValue) => setGranularity(newValue)}
          >
            {granularityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Folder Structure Display */}
      <Box sx={{ mb: 4 }}>
        {clusteringOptions?.assetHierarchy ? (
          <ConfigurableInlineAssetFolder
            node={clusteringOptions.assetHierarchy}
            expandedNodes={expandedNodes}
            toggleNode={toggleNode}
            handleRunModelForNode={handleRunModelForNode}
            runningTests={runningTests}
            selectedAssets={selectedAssets}
          />
        ) : (
          <Typography>No asset hierarchy available.</Typography>
        )}
      </Box>

      {/* Model Run Dropdown Filter */}
      {Object.keys(nodeResults).length > 0 && (
        <Box sx={{ mb: 4 }}>
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              placeholder="Select Model Run to View"
              value={selectedModelRun}
              onChange={handleSelectModelRun}
            >
              {Object.keys(nodeResults).map((nodePath) => (
                <Option key={nodePath} value={nodePath}>
                  {nodePath}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Display Selected Model Run Results */}
      {selectedModelRun && nodeResults[selectedModelRun] && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {selectedModelRun}
          </Typography>
          <Box sx={{ height: 600, marginBottom: 4 }}>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#000000',
              }}
            >
              Alpha Factor Returns
            </Typography>
            <PerformanceChart
              data={nodeResults[selectedModelRun].chartData}
              granularity={granularity}
              yAxisLabel="Return"
              tooltipFormat={(value) => `${(value * 100).toFixed(2)}%`}
            />
          </Box>

          {nodeResults[selectedModelRun].correlationData.length > 1 && (
            <Box sx={{ marginBottom: 4 }}>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#40826D',
                  marginBottom: 2,
                }}
              >
                Correlation Heatmap
              </Typography>
              <CorrelationHeatmap
                correlations={nodeResults[selectedModelRun].correlationData}
              />
            </Box>
          )}

          <Box sx={{ marginBottom: 4 }}>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#40826D',
                marginBottom: 2,
              }}
            >
              Metrics
            </Typography>
            <MetricsList metrics={nodeResults[selectedModelRun].metrics} />
          </Box>
        </Box>
      )}

      {/* Display Loading Indicator for Running Tests */}
      {Object.values(runningTests).some((isRunning) => isRunning) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default AlphaModeling;