// src/pages/PortfolioArena/PortfolioConstruction.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  Option,
  CircularProgress,
  Checkbox,
  Slider,
} from '@mui/joy';
import { FormControl, FormControlLabel, FormGroup } from '@mui/material';  
import PerformanceChart from '../../../components/charts/PerformanceChart';
import CorrelationHeatmap from '../../../components/charts/CorrelationHeatmap';
import MetricsList from '../../../components/charts/MetricsList';
import ConfigurableInlineAssetFolder from './ConfigurableInlineAssetFolder';
import {
  generatePortfolioWeights,
  generateCorrelationMatrix,
  getRandomStatistics,
  granularityOptions,
} from '../../../data/assetClassDataGenerators';

const PortfolioConstruction = ({
  selectedAssets = [],
  clusteringOptions,
  portfolioConstructionOptions,
  setPortfolioConstructionOptions,
}) => {
  const [granularity, setGranularity] = useState('1Y');
  const [prior, setPrior] = useState('equalWeight'); // 'equalWeight', 'volatilityParity', 'riskParity', 'factorModelParity'
  const [alphaAggressiveness, setAlphaAggressiveness] = useState(5); // Scale from 1 (low) to 10 (high)
  const [optimizationConstraints, setOptimizationConstraints] = useState({
    maxWeight: false,
    sectorConstraints: false,
    turnoverLimit: false,
  });
  const [runningTests, setRunningTests] = useState({});
  const [nodeResults, setNodeResults] = useState({});
  const [selectedModelRun, setSelectedModelRun] = useState('');
  const [expandedNodes, setExpandedNodes] = useState(new Set());

  useEffect(() => {
    if (portfolioConstructionOptions) {
      setNodeResults((prev) => ({ ...prev, ...portfolioConstructionOptions }));
      const modelRunPaths = Object.keys(portfolioConstructionOptions);
      if (modelRunPaths.length > 0) {
        setSelectedModelRun(modelRunPaths[modelRunPaths.length - 1]);
      }
    }
  }, [portfolioConstructionOptions]);

  const toggleNode = (pathKey) => {
    setExpandedNodes((prev) => {
      const next = new Set(prev);
      if (next.has(pathKey)) {
        next.delete(pathKey);
      } else {
        next.add(pathKey);
      }
      return next;
    });
  };

  const handleRunModelForNode = (nodePath, assets) => {
    if (!assets || assets.length === 0) return;

    setRunningTests((prev) => ({ ...prev, [nodePath]: true }));

    // Simulate portfolio construction using data generators
    setTimeout(() => {
      // Generate Portfolio Weights based on selected options
      const portfolioWeights = generatePortfolioWeights(
        assets,
        granularity,
        prior,
        alphaAggressiveness,
        optimizationConstraints
      );
      const assetLabels = portfolioWeights.map((d) => d.label);

      const portfolioWeightsData = {
        chartData: portfolioWeights,
        // correlationData: generateCorrelationMatrix(assetLabels),
        // metrics: assetLabels.map((asset) => ({
          // name: asset,
          // ...getRandomStatistics(),
        // }
      // )
    // ),
      };

      // Update results specific to this node
      setPortfolioConstructionOptions({
        ...portfolioConstructionOptions,
        [nodePath]: portfolioWeightsData,
      });

      setRunningTests((prev) => ({ ...prev, [nodePath]: false }));
    }, 2000);
  };

  const handleSelectModelRun = (event, newValue) => {
    setSelectedModelRun(newValue);
  };

  const handlePriorChange = (event, newValue) => {
    setPrior(newValue);
  };

  const handleAlphaAggressivenessChange = (event, newValue) => {
    setAlphaAggressiveness(newValue);
  };

  const handleOptimizationConstraintsChange = (event) => {
    const { name, checked } = event.target;
    setOptimizationConstraints((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography level="h5" sx={{ mb: 2 }}>
        Portfolio Construction
      </Typography>

      {/* Portfolio Construction Options */}
      <Box sx={{ mb: 4 }}>
        {/* Prior Selection */}
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ mb: 1 }}>Select Prior:</Typography>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              placeholder="Select Prior"
              value={prior}
              onChange={handlePriorChange}
            >
              <Option value="equalWeight">Equal Weight</Option>
              <Option value="volatilityParity">Volatility Parity</Option>
              <Option value="riskParity">Risk Parity</Option>
              <Option value="factorModelParity">Factor Model Parity</Option>
            </Select>
          </FormControl>
        </Box>

        {/* Alpha Aggressiveness Slider */}
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ mb: 1 }}>Alpha Aggressiveness: {alphaAggressiveness}</Typography>
          <Slider
            value={alphaAggressiveness}
            min={1}
            max={10}
            step={1}
            onChange={handleAlphaAggressivenessChange}
            valueLabelDisplay="auto"
            marks={[
              { value: 1, label: 'Low' },
              { value: 5, label: 'Medium' },
              { value: 10, label: 'High' },
            ]}
          />
        </Box>

        {/* Optimization Constraints */}
        <Box>
          <Typography sx={{ mb: 1 }}>Optimization Constraints:</Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={optimizationConstraints.maxWeight}
                  onChange={handleOptimizationConstraintsChange}
                  name="maxWeight"
                />
              }
              label="Maximum Weight"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={optimizationConstraints.sectorConstraints}
                  onChange={handleOptimizationConstraintsChange}
                  name="sectorConstraints"
                />
              }
              label="Sector Constraints"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={optimizationConstraints.turnoverLimit}
                  onChange={handleOptimizationConstraintsChange}
                  name="turnoverLimit"
                />
              }
              label="Turnover Limit"
            />
          </FormGroup>
        </Box>
      </Box>

      {/* Granularity Selector */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            placeholder="Select Granularity"
            value={granularity}
            onChange={(e, newValue) => setGranularity(newValue)}
          >
            {granularityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Folder Structure Display */}
      <Box sx={{ mb: 4 }}>
        {clusteringOptions?.assetHierarchy ? (
          <ConfigurableInlineAssetFolder
            node={clusteringOptions.assetHierarchy}
            expandedNodes={expandedNodes}
            toggleNode={toggleNode}
            handleRunModelForNode={handleRunModelForNode}
            runningTests={runningTests}
            selectedAssets={selectedAssets}
          />
        ) : (
          <Typography>No asset hierarchy available.</Typography>
        )}
      </Box>

      {/* Model Run Dropdown Filter */}
      {Object.keys(nodeResults).length > 0 && (
        <Box sx={{ mb: 4 }}>
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              placeholder="Select Model Run to View"
              value={selectedModelRun}
              onChange={handleSelectModelRun}
            >
              {Object.keys(nodeResults).map((nodePath) => (
                <Option key={nodePath} value={nodePath}>
                  {nodePath}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Display Selected Model Run Results */}
      {selectedModelRun && nodeResults[selectedModelRun] && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {selectedModelRun}
          </Typography>
          <Box sx={{ height: 600, marginBottom: 4 }}>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#000000',
              }}
            >
              Portfolio Weights Over Time
            </Typography>
            <PerformanceChart
              data={nodeResults[selectedModelRun].chartData}
              granularity={granularity}
              yAxisLabel="Weight (%)"
              tooltipFormat={(value) => `${(value * 100).toFixed(2)}%`}
            />
          </Box>
        </Box>
      )}

      {/* Display Loading Indicator for Running Tests */}
      {Object.values(runningTests).some((isRunning) => isRunning) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default PortfolioConstruction;