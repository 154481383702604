// Deployment.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import InternalTabBar from '../../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Deploy = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Deploy
        </Typography>

        {/* High-Level Description */}
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Executing Your Investment Strategy with Precision
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          Deployment is the final step where we put our investment philosophy into action. This involves executing the
          investment plan based on our comprehensive approach and continuously monitoring the portfolio to ensure it
          remains aligned with your financial goals.
        </Typography>

        {/* Secondary Section Header */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Our Deployment Process
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Each deployment step is essential to maintaining your portfolio’s integrity, tracking performance, and staying aligned with your objectives.
          </Typography>
        </Box>

        {/* Deployment Sections */}
        <Box>
          <DeploySections />
        </Box>
      </Box>
    </Box>
  );
};

export default Deploy;

// DeploymentSections Component
const DeploySections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Deployment Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <DeployCard title="Strategy Implementation">
          <Typography sx={{ color: '#333' }}>
            Precise execution of your investment strategies ensures that every trade aligns with the optimized portfolio, through advanced trade execution, asset allocation, and order routing.
          </Typography>
        </DeployCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <DeployCard title="Performance Monitoring">
          <Typography sx={{ color: '#333' }}>
            Continuous monitoring with real-time tracking, performance metrics, and risk assessment helps ensure that your portfolio remains on track.
          </Typography>
        </DeployCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <DeployCard title="Making Necessary Adjustments">
          <Typography sx={{ color: '#333' }}>
            Based on performance and market conditions, we make strategic adjustments to optimize returns and manage risks, including rebalancing, dynamic shifting, and strategy refinement.
          </Typography>
        </DeployCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <DeployCard title="Client Alignment">
          <Typography sx={{ color: '#333' }}>
            We regularly review your portfolio to incorporate feedback, ensuring alignment with your evolving financial goals and providing transparent reporting.
          </Typography>
        </DeployCard>
      </Grid>
    </Grid>
  </Box>
);

// DeploymentCard Component
const DeployCard = ({ title, children }) => (
  <Box sx={{
    padding: '1.5rem',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  }}>
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

DeployCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
