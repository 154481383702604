// Selection.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import InternalTabBar from '../../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Selection = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Selection
        </Typography>

        {/* Main High-Level Description */}
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Building a Foundation of Resilient and Diverse Assets
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          At AlgoInvest, we begin by carefully selecting a wide range of assets across multiple classes, ensuring a well-diversified portfolio that captures market opportunities while managing risk. This foundational step lays the groundwork for a resilient investment strategy.
        </Typography>

        {/* Secondary Section Header */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Our Asset Selection Process
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Our asset selection process involves several critical elements, each designed to create a robust and diversified portfolio aligned with your investment goals:
          </Typography>
        </Box>

        {/* Selection Sections */}
        <Box>
          <SelectionSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Selection;

// SelectionSections Component
const SelectionSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Selection Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <SelectionCard title="Diverse Asset Classes">
          <Typography sx={{ color: '#333' }}>
            We incorporate a wide range of asset classes, including equities, fixed income, real estate, and commodities. This diversity provides resilience and ensures opportunities for growth across various market conditions.
          </Typography>
        </SelectionCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectionCard title="Thematic Grouping">
          <Typography sx={{ color: '#333' }}>
            Assets are grouped into meaningful themes, such as regional markets, size-based segments, and sector-focused investments. This thematic approach enhances targeted exposure and aligns with specific market dynamics.
          </Typography>
        </SelectionCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectionCard title="Granular Selection">
          <Typography sx={{ color: '#333' }}>
            Our process extends beyond broad asset classes, allowing for precise exposure within each segment. This granular approach improves risk management and performance by focusing on high-potential assets.
          </Typography>
        </SelectionCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectionCard title="Continuous Evaluation">
          <Typography sx={{ color: '#333' }}>
            We continuously monitor and adjust our selections to align with market changes, ensuring that your portfolio adapts to evolving economic conditions and your investment goals.
          </Typography>
        </SelectionCard>
      </Grid>
    </Grid>
  </Box>
);

// SelectionCard Component
const SelectionCard = ({ title, children }) => (
  <Box sx={{
    padding: '1.5rem',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: 'transform 0.3s',
    // Removed borderRadius and boxShadow to eliminate the border effect
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  }}>
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

SelectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
