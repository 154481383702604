// src/components/internal/MetricsList.jsx

import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import PropTypes from 'prop-types';

const MetricsList = ({ metrics }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fefefe', // Updated to match the greenish background
        borderRadius: 'none', // Remove rounded corners
        boxShadow: 'none', // Remove shadow (border)
        border: 'none', // Ensure no border is applied
        padding: 3,
        marginBottom: 4,
        width: '100%'
      }}
    >
      {/* Header Row */}
      <Grid container spacing={1} sx={{ paddingBottom: 2, borderBottom: '1px solid #e0e0e0' }}>
        <Grid xs={1.71428}><Typography fontWeight="bold" color="#2f3b52">Name</Typography></Grid>
        <Grid xs={1.71428}><Typography fontWeight="bold" color="#2f3b52">Return %</Typography></Grid>
        <Grid xs={1.71428}><Typography fontWeight="bold" color="#2f3b52">Ann Return %</Typography></Grid>
        <Grid xs={1.71428}><Typography fontWeight="bold" color="#2f3b52">Volatility %</Typography></Grid>
        <Grid xs={1.71428}><Typography fontWeight="bold" color="#2f3b52">Ann Volatility %</Typography></Grid>
        <Grid xs={1.71428}><Typography fontWeight="bold" color="#2f3b52">Sharpe Ratio</Typography></Grid>
        <Grid xs={1.71428}><Typography fontWeight="bold" color="#2f3b52">Ann Sharpe Ratio</Typography></Grid>
      </Grid>

      {/* Metrics Rows */}
      {metrics.map((metric, index) => (
        <Grid container spacing={1} key={index} sx={{ padding: '8px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Grid xs={1.71428}><Typography color="#2f3b52">{metric.name || metric.symbol}</Typography></Grid>
          <Grid xs={1.71428}><Typography color="#2f3b52">{metric.returns}</Typography></Grid>
          <Grid xs={1.71428}><Typography color="#2f3b52">{(parseFloat(metric.returns) / 10).toFixed(2)}%</Typography></Grid>
          <Grid xs={1.71428}><Typography color="#2f3b52">{metric.volatility}</Typography></Grid>
          <Grid xs={1.71428}><Typography color="#2f3b52">{(parseFloat(metric.volatility) / 10).toFixed(2)}%</Typography></Grid>
          <Grid xs={1.71428}><Typography color="#2f3b52">{metric.sharpe}</Typography></Grid>
          <Grid xs={1.71428}><Typography color="#2f3b52">{(parseFloat(metric.sharpe) / 10).toFixed(2)}</Typography></Grid>
        </Grid>
      ))}
    </Box>
  );
};

MetricsList.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      symbol: PropTypes.string,
      returns: PropTypes.string,
      volatility: PropTypes.string,
      sharpe: PropTypes.string,
    })
  ).isRequired,
};

export default MetricsList;