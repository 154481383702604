import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import algoinvestGraph from '../../assets/images/algoinvest-graph.png';
import snpGraph from '../../assets/images/snp-graph.png';
import aiFundGraph from '../../assets/images/ai-fund-graph.png'; // Placeholder for AI Fund Graph
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const Performance = () => {
  return (
    <>
    <Box
      sx={{
        height: '25vh',
        bgcolor: '#40826D',
        // py: { xs: 6, md: 10 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // px: { xs: 2, md: 4 },
      }}
    >
    <Typography
    level="h4"
    sx={{ fontSize: { xs: '2.5rem', md: '3.4rem' }, color: '#fff', fontWeight: 'bold' }}
  >
    AlgoInvest vs S&P 500
  </Typography>
  </Box>

    <Box
      sx={{
        height: '75vh',
        bgcolor: '#40826D',
        py: { xs: 6, md: 10 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: { xs: 2, md: 4 },
      }}
    >
      <Grid container spacing={6} sx={{ maxWidth: '100%', margin: '0' }}>
        
        {/* S&P 500 */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              bgcolor: '#ffffff',
              p: { xs: '20px', md: '40px' },
              borderRadius: '100px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            <Typography level="h5" sx={{ fontSize: '2.2rem', fontWeight: 'bold', color: '#2f3b52', mb: 3 }}>
              S&P 500
            </Typography>
            <img
              src={snpGraph}
              alt="S&P 500 Graph"
              style={{ width: '100%', marginBottom: '30px' }}
            />
            <Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CancelIcon sx={{ color: '#8B0000', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Only US, large-cap Equities</Typography>
              </Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CancelIcon sx={{ color: '#8B0000', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Static investment allocation</Typography>
              </Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CancelIcon sx={{ color: '#8B0000', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Heavy big tech/finance risk</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* AlgoInvest Core Fund */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              bgcolor: '#ffffff',
              p: { xs: '20px', md: '40px' },
              borderRadius: '100px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            <Typography level="h5" sx={{ fontSize: '2.2rem', fontWeight: 'bold', color: '#2f3b52', mb: 3 }}>
              Core Fund
            </Typography>
            <img
              src={algoinvestGraph}
              alt="AlgoInvest Graph"
              style={{ width: '100%', marginBottom: '30px' }}
            />
            <Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Multiple asset classes</Typography>
              </Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>
                  Dynamic allocation
                </Typography>
              </Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Controlled risk exposures</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* AI Fund */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              bgcolor: '#ffffff',
              p: { xs: '20px', md: '40px' },
              borderRadius: '100px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            <Typography level="h5" sx={{ fontSize: '2.2rem', fontWeight: 'bold', color: '#2f3b52', mb: 3 }}>
              AI Fund
            </Typography>
            <img
              src={aiFundGraph}
              alt="AI Fund Graph"
              style={{ width: '100%', marginBottom: '30px' }}
            />
            <Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Financial statement analysis</Typography>
              </Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>News sentiment</Typography>
              </Box>
              <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
                <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
                <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Proprietary algorithms</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default Performance;

// import React from 'react';
// import { Box, Typography, Grid } from '@mui/joy';
// import algoinvestGraph from '../../assets/images/algoinvest-graph.png';
// import snpGraph from '../../assets/images/snp-graph.png';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';

// const Performance = () => {
//   return (
//     <Box
//       sx={{
//         minHeight: '100vh',
//         bgcolor: '#40826D',
//         py: { xs: 6, md: 10 },
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         px: { xs: 2, md: 4 },
//       }}
//     >
//       <Grid container spacing={6} sx={{ maxWidth: '100%', margin: '0' }}>
//         {/* AlgoInvest Graph */}
//         <Grid item xs={12} md={6}>
//           <Box
//             sx={{
//               bgcolor: '#ffffff',
//               p: { xs: '20px', md: '40px' },
//               borderRadius: '100px',
//               boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
//               textAlign: 'center',
//               transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//               '&:hover': {
//                 transform: 'translateY(-5px)',
//                 boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
//               },
//             }}
//           >
//             <Typography level="h5" sx={{ fontSize: '2.2rem', fontWeight: 'bold', color: '#2f3b52', mb: 3 }}>
//             Funds
//             </Typography>
//             <img
//               src={algoinvestGraph}
//               alt="AlgoInvest Graph"
//               style={{ width: '100%', marginBottom: '30px' }}
//             />
//             <Box>
//               <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
//                 <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
//                 <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Multiple asset classes</Typography>
//               </Box>
//               <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
//                 <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
//                 <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>
//                   Dynamic investment allocation
//                 </Typography>
//               </Box>
//               <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
//                 <CheckCircleIcon sx={{ color: '#228B22', mr: 1, fontSize: '40px' }} />
//                 <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Controlled risk exposures</Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Grid>
//         {/* S&P500 Graph */}
//         <Grid item xs={12} md={6}>
//           <Box
//             sx={{
//               bgcolor: '#ffffff',
//               p: { xs: '20px', md: '40px' },
//               borderRadius: '100px',
//               boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
//               textAlign: 'center',
//               transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//               '&:hover': {
//                 transform: 'translateY(-5px)',
//                 boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
//               },
//             }}
//           >
//             <Typography level="h5" sx={{ fontSize: '2.2rem', fontWeight: 'bold', color: '#2f3b52', mb: 3 }}>
//               S&P 500
//             </Typography>
//             <img
//               src={snpGraph}
//               alt="S&P 500 Graph"
//               style={{ width: '100%', marginBottom: '30px' }}
//             />
//             <Box>
//               <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
//                 <CancelIcon sx={{ color: '#8B0000', mr: 1, fontSize: '40px' }} />
//                 <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Only US, large-cap Equities</Typography>
//               </Box>
//               <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
//                 <CancelIcon sx={{ color: '#8B0000', mr: 1, fontSize: '40px' }} />
//                 <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Static investment allocation</Typography>
//               </Box>
//               <Box sx={{ marginLeft: '5.5%', display: 'flex', alignItems: 'center', mb: 1, color: '#2f3b52' }}>
//                 <CancelIcon sx={{ color: '#8B0000', mr: 1, fontSize: '40px' }} />
//                 <Typography sx={{ fontSize: '1.6rem', fontWeight: '500' }}>Heavy big tech/finance risk </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default Performance;