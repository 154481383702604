// src/pages/PortfolioArena/PortfolioWorkspace.js

import React, { useState } from 'react';
import { Box, Tabs, Tab, TabList } from '@mui/joy';
import AssetSelection from './AssetSelection';
import AssetClustering from './AssetClustering';
import BetaModeling from './BetaModeling';
import RhoModeling from './RhoModeling';
import AlphaModeling from './AlphaModeling';
import PortfolioConstruction from './PortfolioConstruction';
import Trading from './Trading';
import Backtesting from './Backtesting';
import Deploy from './Deploy';
import Taxes from './Taxes';

const PortfolioWorkspace = ({ portfolio, updatePortfolio }) => {
  const [activeTab, setActiveTab] = useState(0);

  // Handle tab switching
  const handleChangeTab = (event, newIndex) => {
    setActiveTab(newIndex);
  };

  const steps = [
    'Selection',
    'Clustering',
    'Beta',
    'Rho',
    'Alpha',
    'Portfolios',
    'Trading',
    'Taxes',
    'Backtesting',
    'Deploy',
  ];

  return (
    <Box>
      {/* Tabs for the portfolio creation steps */}
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        sx={{ borderBottom: '1px solid #ccc', mb: 2 }}
      >
        <TabList>
          {steps.map((label, index) => (
            <Tab key={index}>{label}</Tab>
          ))}
        </TabList>
      </Tabs>

      {/* Render components based on the active tab */}
      <Box sx={{ mt: 2 }}>
        {activeTab === 0 && (
          <AssetSelection
            selectedAssets={portfolio.selectedAssets}
            setSelectedAssets={(assets) =>
              updatePortfolio({ ...portfolio, selectedAssets: assets })
            }
            clusteringOptions={portfolio.clusteringOptions}
            updateClusteringOptions={(options) =>
              updatePortfolio({ ...portfolio, clusteringOptions: options })
            }
          />
        )}

        {activeTab === 1 && (
          <AssetClustering
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            updateClusteringOptions={(options) =>
              updatePortfolio({ ...portfolio, clusteringOptions: options })
            }
          />
        )}

        {activeTab === 2 && (
          <BetaModeling
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            riskModelOptions={portfolio.riskModelOptions}
            setRiskModelOptions={(options) =>
              updatePortfolio({ ...portfolio, riskModelOptions: options })
            }
          />
        )}

        {activeTab === 3 && (
          <RhoModeling
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            rhoModelOptions={portfolio.rhoModelOptions}
            setRhoModelOptions={(options) =>
              updatePortfolio({ ...portfolio, rhoModelOptions: options })
            }
          />
        )}

        {activeTab === 4 && (
          <AlphaModeling
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            alphaModelOptions={portfolio.alphaModelOptions}
            setAlphaModelOptions={(options) =>
              updatePortfolio({ ...portfolio, alphaModelOptions: options })
            }
          />
        )}

        {activeTab === 5 && (
          <PortfolioConstruction
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            portfolioConstructionOptions={portfolio.portfolioConstructionOptions}
            setPortfolioConstructionOptions={(options) =>
              updatePortfolio({
                ...portfolio,
                portfolioConstructionOptions: options,
              })
            }
          />
        )}

        {activeTab === 6 && (
          <Trading
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            tradingOptions={portfolio.tradingOptions}
            setTradingOptions={(options) =>
              updatePortfolio({ ...portfolio, tradingOptions: options })
            }
          />
        )}

        {activeTab === 7 && (
          <Taxes
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            taxOptions={portfolio.taxOptions}
            setTaxOptions={(options) =>
              updatePortfolio({ ...portfolio, taxOptions: options })
            }
          />
        )}

        {activeTab === 8 && (
          <Backtesting
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            backtestOptions={portfolio.backtestOptions}
            setBacktestOptions={(options) =>
              updatePortfolio({ ...portfolio, backtestOptions: options })
            }
            backtestResults={portfolio.backtestResults}
            setBacktestResults={(results) =>
              updatePortfolio({ ...portfolio, backtestResults: results })
            }
          />
        )}

        {activeTab === 9 && (
          <Deploy
            portfolioName={portfolio.name}
            backtestResults={portfolio.backtestResults}
            selectedAssets={portfolio.selectedAssets}
            clusteringOptions={portfolio.clusteringOptions}
            riskModelOptions={portfolio.riskModelOptions}
            alphaModelOptions={portfolio.alphaModelOptions}
            rhoModelOptions={portfolio.rhoModelOptions} // Include rhoModelOptions
            portfolioConstructionOptions={portfolio.portfolioConstructionOptions}
            tradingOptions={portfolio.tradingOptions}
            taxOptions={portfolio.taxOptions}
          />
        )}
      </Box>
    </Box>
  );
};

export default PortfolioWorkspace;