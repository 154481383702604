import React, { useState, useEffect } from 'react';
import {
 Box,
 Typography,
 Button,
 Modal,
 ModalDialog,
 ModalClose,
 Input,
 List,
 ListItem,
 ListItemDecorator,
 IconButton,
 Stack,
 Select,
 Option,
} from '@mui/joy';
import { FaUniversity, FaTrash } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { getAccounts, addAccount, removeAccount } from '../../utils/api';

const AccountsSection = () => {
 const [accounts, setAccounts] = useState([]);
 const [openAddModal, setOpenAddModal] = useState(false);

 // Fetch accounts on component mount
 useEffect(() => {
   fetchAccounts();
 }, []);


 const fetchAccounts = async () => {
   try {
     const fetchedAccounts = await getAccounts();
     setAccounts(fetchedAccounts);
   } catch (error) {
     console.error('Failed to fetch accounts:', error);
     toast.error('Failed to load accounts.');
   }
 };


 const accountFormik = useFormik({
   initialValues: {
     name: '',
     account_number: '',
     routing_number: '',
     account_type: '',
   },
   validationSchema: Yup.object({
     name: Yup.string().required('Bank/Brokerage Name is required'),
     account_number: Yup.string().required('Account Number is required'),
     routing_number: Yup.string().required('Routing Number is required'),
     account_type: Yup.string().required('Account Type is required'),
   }),
   onSubmit: async (values) => {
     try {
       await addAccount(values);
       toast.success('Account added successfully!');
       setOpenAddModal(false);
       accountFormik.resetForm();
       fetchAccounts(); // Refresh the account list
     } catch (error) {
       console.error('Failed to add account:', error);
       toast.error('Failed to add account.');
     }
   },
 });


 const handleRemoveAccount = async (id) => {
   if (window.confirm('Are you sure you want to remove this account?')) {
     try {
       await removeAccount(id);
       toast.success('Account removed successfully!');
       fetchAccounts(); // Refresh the account list
     } catch (error) {
       console.error('Failed to remove account:', error);
       toast.error('Failed to remove account.');
     }
   }
 };


 return (
   <Box sx={{ padding: 3, backgroundColor: '#fefefe' }}>
     <Typography level="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#40826D', marginBottom: 3 }}>
       Connected Accounts
     </Typography>
     <List sx={{ padding: 0 }}>
       {accounts.length > 0 ? (
         accounts.map((account) => (
           <ListItem
             key={account.id}
             endAction={
               <IconButton color="danger" onClick={() => handleRemoveAccount(account.id)}>
                 <FaTrash />
               </IconButton>
             }
             sx={{
               backgroundColor: '#ffffff',
               borderRadius: '8px',
               marginBottom: 1,
               '&:hover': {
                 backgroundColor: '#e6f7f2',
               },
             }}
           >
             <ListItemDecorator>
               <FaUniversity style={{ color: '#478778' }} />
             </ListItemDecorator>
             <Typography sx={{ color: '#333' }}>
               {account.name} ({account.account_type}) - ****{account.account_number.slice(-4)}
             </Typography>
           </ListItem>
         ))
       ) : (
         <Typography sx={{ color: '#333' }}>No connected accounts.</Typography>
       )}
     </List>


     <Box mt={2}>
       <Button
         variant="solid"
         color="primary"
         onClick={() => setOpenAddModal(true)}
         sx={{
           borderRadius: '30px',
           padding: '8px 20px',
           fontWeight: '600',
           fontSize: '1rem',
           textTransform: 'none',
           '&:hover': {
             backgroundColor: '#478778',
           },
         }}
       >
         + Add Account
       </Button>
     </Box>


     {/* Add Account Modal */}
     <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
       <ModalDialog aria-labelledby="add-account-dialog-title" size="md">
         <ModalClose />
         <Typography id="add-account-dialog-title" level="h6" sx={{ color: '#40826D', fontWeight: 'bold' }}>
           Add Account
         </Typography>
         <form onSubmit={accountFormik.handleSubmit}>
           <Stack spacing={2}>
             {[
               { label: 'Bank/Brokerage Name', id: 'name' },
               { label: 'Account Number', id: 'account_number' },
               { label: 'Routing Number', id: 'routing_number' },
             ].map((field) => (
               <Input
                 key={field.id}
                 fullWidth
                 id={field.id}
                 name={field.id}
                 placeholder={field.label}
                 value={accountFormik.values[field.id]}
                 onChange={accountFormik.handleChange}
                 onBlur={accountFormik.handleBlur}
                 error={accountFormik.touched[field.id] && Boolean(accountFormik.errors[field.id])}
                 helperText={accountFormik.touched[field.id] && accountFormik.errors[field.id]}
                 sx={{
                   backgroundColor: '#ffffff',
                   borderRadius: '8px',
                   '&:hover': {
                     backgroundColor: '#e6f7f2',
                   },
                 }}
               />
             ))}
             <Select
               name="account_type"
               placeholder="Select Account Type"
               value={accountFormik.values.account_type}
               onChange={(e, value) => {
                 accountFormik.setFieldValue('account_type', value);
               }}
               onBlur={accountFormik.handleBlur}
               error={accountFormik.touched.account_type && Boolean(accountFormik.errors.account_type)}
               sx={{
                 backgroundColor: '#ffffff',
                 borderRadius: '8px',
                 '&:hover': {
                   backgroundColor: '#e6f7f2',
                 },
               }}
             >
               <Option value="">Select Account Type</Option>
               <Option value="Checking">Bank: Checking</Option>
               <Option value="Savings">Bank: Savings</Option>
               <Option value="Rewards">Bank: Rewards</Option>
               <Option value="Bank">Bank: Bank</Option>
               <Option value="Fixed Annuity">Insurance: Fixed Annuity</Option>
               <Option value="Annuity">Insurance: Annuity</Option>
               <Option value="Insurance">Insurance: Insurance</Option>
               <Option value="Traditional 401k">Investment Employer: Traditional 401k</Option>
               <Option value="Roth 401k">Investment Employer: Roth 401k</Option>
               <Option value="Thrift Savings Plan">Investment Employer: Thrift Savings Plan</Option>
               <Option value="403B">Investment Employer: 403B</Option>
               <Option value="457B">Investment Employer: 457B</Option>
               <Option value="401A">Investment Employer: 401A</Option>
               <Option value="HSA">Investment Employer: HSA</Option>
               <Option value="Pension">Investment Employer: Pension</Option>
               <Option value="Roth">Investment IRA: Roth (after-tax)</Option>
               <Option value="Traditional">Investment IRA: Traditional (pre-tax)</Option>
               <Option value="SEP">Investment IRA: SEP</Option>
               <Option value="SIMPLE">Investment IRA: SIMPLE</Option>
               <Option value=".529">Investment Other: .529</Option>
               <Option value="Coverdell ESA">Investment Other: Coverdell ESA</Option>
               <Option value="Variable Annuity">Investment Other: Variable Annuity</Option>
               <Option value="HSA Other">Investment Other: HSA</Option>
               <Option value="Other Non-Taxable">Investment Other: Other Non-Taxable</Option>
               <Option value="Other Taxable">Investment Taxable: Individual</Option>
               <Option value="Other Taxable">Investment Taxable: Joint</Option>
               <Option value="Other Taxable">Investment Taxable: Stock Plan</Option>
               <Option value="Other Taxable">Investment Taxable: Trust</Option>
               <Option value="Other Taxable Other">Investment Taxable: Other Taxable</Option>
             </Select>
           </Stack>
           <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
             <Button
               onClick={() => setOpenAddModal(false)}
               variant="plain"
               color="neutral"
             >
               Cancel
             </Button>
             <Button
               type="submit"
               variant="solid"
               color="primary"
               disabled={!accountFormik.isValid || !accountFormik.dirty}
             >
               Add Account
             </Button>
           </Box>
         </form>
       </ModalDialog>
     </Modal>
   </Box>
 );
};

export default AccountsSection;