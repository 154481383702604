// src/pages/PortfolioArena/ConfigurableInlineAssetFolder.js

import React from 'react';
import { Box, Typography } from '@mui/joy';
import { ChevronDown, ChevronRight } from 'lucide-react';

const ConfigurableInlineAssetFolder = ({
  node,
  path = [],
  depth = 0,
  expandedNodes,
  toggleNode,
  renderControls, // New prop
}) => {
  const renderNode = (node, path = [], depth = 0) => {
    return Object.entries(node || {}).map(([key, subNode]) => {
      const currentPath = [...path, key];
      const pathKey = currentPath.join(' > ');
      const isExpanded = expandedNodes.has(pathKey);

      if (!Array.isArray(subNode)) {
        // Folder node
        return (
          <Box key={pathKey}>
            <Box
              onClick={() => toggleNode(pathKey)}
              sx={{
                pl: depth * 3,
                py: 1,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
              }}
            >
              {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
              {/* Render controls for this node */}
              {renderControls &&
                renderControls({
                  node: subNode,
                  path: currentPath,
                  depth,
                  isLeaf: false,
                })}
              <Typography
                sx={{
                  fontWeight: depth === 0 ? 600 : 400,
                  color: depth === 0 ? 'text.primary' : 'text.secondary',
                }}
              >
                {key.replace(/_/g, ' ')}
              </Typography>
            </Box>
            {isExpanded && <Box>{renderNode(subNode, currentPath, depth + 1)}</Box>}
          </Box>
        );
      } else {
        // Leaf node (array of assets)
        const assets = subNode;
        return (
          <Box key={pathKey}>
            <Box
              onClick={() => toggleNode(pathKey)}
              sx={{
                pl: depth * 3,
                py: 1,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
              }}
            >
              {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
              {/* Render controls for this node */}
              {renderControls &&
                renderControls({
                  node: subNode,
                  path: currentPath,
                  depth,
                  isLeaf: false, // Treat leaf node as a folder for assets
                })}
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  color: 'text.secondary',
                }}
              >
                {key.replace(/_/g, ' ')} ({assets.length} assets)
              </Typography>
            </Box>
            {isExpanded && (
              <Box>
                {assets.map((asset) => {
                  const assetPath = [...currentPath, asset];
                  const assetPathKey = assetPath.join(' > ');
                  return (
                    <Box
                      key={assetPathKey}
                      sx={{
                        pl: (depth + 1) * 3,
                        py: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
                      }}
                    >
                      {/* Render controls for the asset */}
                      {renderControls &&
                        renderControls({
                          node: asset,
                          path: assetPath,
                          depth: depth + 1,
                          isLeaf: true,
                        })}
                      <Typography
                        sx={{
                          fontSize: '0.9rem',
                          color: 'text.secondary',
                        }}
                      >
                        {asset}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      }
    });
  };

  return <Box>{renderNode(node, path, depth)}</Box>;
};

export default ConfigurableInlineAssetFolder;