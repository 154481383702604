// src/data/assetTree.js

export const etfTreeData = {
    Portfolio: {
      Regional: {
        Global_Markets: {
          Global_Broad: [
            'ACWI', // iShares MSCI ACWI ETF
            'VT', // Vanguard Total World Stock ETF
            'URTH', // iShares MSCI World ETF
            'VEU', // Vanguard FTSE All-World ex-US ETF
            'IXUS', // iShares Core MSCI Total International Stock ETF
            'SPDW', // SPDR Portfolio Developed World ex-US ETF
            'SCHF', // Schwab International Equity ETF
            'VEA', // Vanguard FTSE Developed Markets ETF
            'ACWX', // iShares MSCI ACWI ex U.S. ETF
            'SPGM', // SPDR Portfolio MSCI Global Stock Market ETF
            'GSIE', // Goldman Sachs ActiveBeta International Equity ETF
            // 'VTWAX', // Vanguard Total World Stock Index Fund Admiral Shares (mutual fund)
            // 'VTWNX', // Vanguard Target Retirement 2020 Fund (mutual fund)
            // 'DWVG', // Davis Select Worldwide ETF
            // 'RNDW', // First Trust Developed Markets ex-US AlphaDEX Fund
          ],
          Global_Specialized: [
            'XT', // iShares Exponential Technologies ETF
            // 'KLDW', // Knowledge Leaders Developed World ETF
            'BOTZ', // Global X Robotics & Artificial Intelligence ETF
            'FINX', // Global X FinTech ETF
            'IXP', // iShares Global Comm Services ETF
            'NXTG', // First Trust Indxx NextG ETF
            // 'ACWF', // iShares MSCI Global Multifactor ETF
            'HEFA', // iShares Currency Hedged MSCI EAFE ETF
            'PBD', // Invesco Global Clean Energy ETF
          ],
        },
        Developed_Markets: {
          North_America: [
            'SPY', // SPDR S&P 500 ETF Trust
            'IVV', // iShares Core S&P 500 ETF
            'VOO', // Vanguard S&P 500 ETF
            'VTI', // Vanguard Total Stock Market ETF
            'EWC', // iShares MSCI Canada ETF
            // 'FCAN', // First Trust Canada AlphaDEX Fund
            // 'XIC', // iShares Core S&P/TSX Capped Composite Index ETF (Canada)
            // 'ZCN', // BMO S&P/TSX Capped Composite Index ETF (Canada)
            'ITOT', // iShares Core S&P Total U.S. Stock Market ETF
            'VONE', // Vanguard Russell 1000 ETF
            // 'XIU', // iShares S&P/TSX 60 Index ETF (Canada)
            'MDY', // SPDR S&P MidCap 400 ETF Trust
            'IJR', // iShares Core S&P Small-Cap ETF
            'IWB', // iShares Russell 1000 ETF
            'SCHB', // Schwab U.S. Broad Market ETF
            // 'ZSP', // BMO S&P 500 Index ETF (Canada)
          ],
          Europe: [
            'VGK', // Vanguard FTSE Europe ETF
            'EWU', // iShares MSCI United Kingdom ETF
            'IEV', // iShares Europe ETF
            'EZU', // iShares MSCI Eurozone ETF
            'EWG', // iShares MSCI Germany ETF
            'EWQ', // iShares MSCI France ETF
            'EWI', // iShares MSCI Italy ETF
            'EWL', // iShares MSCI Switzerland ETF
            'FEZ', // SPDR EURO STOXX 50 ETF
            'HEDJ', // WisdomTree Europe Hedged Equity Fund
            'IEUR', // iShares Core MSCI Europe ETF
            'DAX', // Global X DAX Germany ETF
            // 'FLIE', // Franklin FTSE Europe ETF
            'EUSC', // WisdomTree Europe SmallCap Dividend Fund
            'DBEU', // Xtrackers MSCI Europe Hedged Equity ETF
            'FEP', // First Trust Europe AlphaDEX Fund
            // 'FEEU', // Barclays ETN+ FI Enhanced Europe 50 ETN
          ],
          Asia_Pacific: [
            'EWJ', // iShares MSCI Japan ETF
            'VPL', // Vanguard FTSE Pacific ETF
            'EWA', // iShares MSCI Australia ETF
            'AAXJ', // iShares MSCI All Country Asia ex Japan ETF
            'EPP', // iShares MSCI Pacific ex Japan ETF
            'VNM', // VanEck Vectors Vietnam ETF
            'EWH', // iShares MSCI Hong Kong ETF
            'EWT', // iShares MSCI Taiwan ETF
            'EWS', // iShares MSCI Singapore ETF
            'FPA', // First Trust Asia Pacific Ex-Japan AlphaDEX Fund
            'FLKR', // Franklin FTSE South Korea ETF
            'FLTW', // Franklin FTSE Taiwan ETF
            'HAWX', // iShares Currency Hedged MSCI ACWI ex U.S. ETF
            // 'AXJL', // iShares MSCI All Country Asia ex Japan ETF
            'ASEA', // Global X FTSE Southeast Asia ETF
            'GMF', // SPDR S&P Emerging Asia Pacific ETF
          ],
        },
        Emerging_Markets: {
          Emerging_Broad: [
            'EEM', // iShares MSCI Emerging Markets ETF
            'VWO', // Vanguard FTSE Emerging Markets ETF
            'IEMG', // iShares Core MSCI Emerging Markets ETF
            'SCHE', // Schwab Emerging Markets Equity ETF
            'SPEM', // SPDR Portfolio Emerging Markets ETF
            'DEM', // WisdomTree Emerging Markets High Dividend Fund
            'DVYE', // iShares Emerging Markets Dividend ETF
            'EMXC', // iShares MSCI Emerging Markets ex China ETF
            'EEMV', // iShares Edge MSCI Min Vol Emerging Markets ETF
            'EDIV', // SPDR S&P Emerging Markets Dividend ETF
            // 'EMFM', // Global X Next Emerging & Frontier ETF
            'GMM', // SPDR S&P Emerging Markets ETF
            'EMGF', // iShares MSCI Emerging Markets Multifactor ETF
            'FNDE', // Schwab Fundamental Emerging Markets Large Company Index ETF
          ],
          Emerging_Asia: [
            'INDA', // iShares MSCI India ETF
            'EPHE', // iShares MSCI Philippines ETF
            'SMIN', // iShares MSCI India Small-Cap ETF
            'EPI', // WisdomTree India Earnings Fund
            'PIN', // Invesco India ETF
            // 'FRN', // Invesco Frontier Markets ETF
            // 'INXX', // Columbia India Infrastructure ETF
            'KBA', // KraneShares Bosera MSCI China A ETF
            'MCHI', // iShares MSCI China ETF
            // 'FTSE', // Vanguard FTSE Pacific ETF
            'TLTE', // FlexShares Morningstar Emerging Markets Factor Tilt Index Fund
          ],
          Emerging_Latin_America_Africa: [
            'ILF', // iShares Latin America 40 ETF
            'AFK', // VanEck Vectors Africa Index ETF
            'EPU', // iShares MSCI Peru ETF
            'EZA', // iShares MSCI South Africa ETF
            'FLBR', // Franklin FTSE Brazil ETF
            // 'GAF', // SPDR S&P Emerging Middle East & Africa ETF
            // 'EGPT', // VanEck Vectors Egypt Index ETF
            'ARGT', // Global X MSCI Argentina ETF
            'GXG', // Global X MSCI Colombia ETF
            // 'NGE', // Global X MSCI Nigeria ETF
            'NORW', // Global X MSCI Norway ETF
            // 'UMX', // UBS ETRACS CMCI Energy Total Return ETN
            // 'LATM', // SPDR S&P Latin America ETF
          ],
          Emerging_Small_Cap: [
            'EWX', // SPDR S&P Emerging Markets Small Cap ETF
            'DGS', // WisdomTree Emerging Markets SmallCap Dividend Fund
            'EEMS', // iShares MSCI Emerging Markets Small-Cap ETF
            'SMDD', // ProShares UltraPro Short MidCap400
            'XSOE', // WisdomTree Emerging Markets ex-State-Owned Enterprises Fund
            // 'EVAL', // Invesco S&P Emerging Markets Low Volatility ETF
            // 'TEMX', // SPDR MSCI Emerging Markets Fossil Fuel Reserves Free ETF
          ],
        },
      },
      Size_Based: {
        Large_Cap: {
          Large_Core: [
            'SCHX', // Schwab U.S. Large-Cap ETF
            'SPLG', // SPDR Portfolio S&P 500 ETF
            'VV', // Vanguard Large-Cap ETF
            'DIA', // SPDR Dow Jones Industrial Average ETF Trust
            'IWL', // iShares Russell Top 200 ETF
            'OEF', // iShares S&P 100 ETF
            'IUS', // Invesco Russell 1000 Equal Weight ETF
            'EQL', // ALPS Equal Sector Weight ETF
            'PRF', // Invesco FTSE RAFI US 1000 ETF
            'VOOG', // Vanguard S&P 500 Growth ETF
          ],
          Large_Growth: [
            'IWF', // iShares Russell 1000 Growth ETF
            'QQQJ', // Invesco NASDAQ Next Gen 100 ETF
            'GSLC', // Goldman Sachs ActiveBeta U.S. Large Cap Equity ETF
            'ONEQ', // Fidelity Nasdaq Composite Index ETF
            'FTEC', // Fidelity MSCI Information Technology Index ETF
          ],
          Large_Value: [
            'IUSV', // iShares Core S&P U.S. Value ETF
            'RPV', // Invesco S&P 500 Pure Value ETF
            'MGV', // Vanguard Mega Cap Value ETF
            'SPYV', // SPDR Portfolio S&P 500 Value ETF
            'DHS', // WisdomTree U.S. High Dividend Fund
          ],
          Large_Specialized: [
            'DVY', // iShares Select Dividend ETF
            'RSP', // Invesco S&P 500 Equal Weight ETF
            'FENY', // Fidelity MSCI Energy Index ETF
            'USMV', // iShares Edge MSCI Min Vol USA ETF
            'SPLV', // Invesco S&P 500 Low Volatility ETF
            'NOBL', // ProShares S&P 500 Dividend Aristocrats ETF
            'SDY', // SPDR S&P Dividend ETF
            'DGRO', // iShares Core Dividend Growth ETF
            'VIG', // Vanguard Dividend Appreciation ETF
            'MOAT', // VanEck Vectors Morningstar Wide Moat ETF
            'FDL', // First Trust Morningstar Dividend Leaders Index Fund
            'CDC', // VictoryShares US EQ Income Enhanced Volatility Wtd ETF
          ],
          Large_Tech_Focus: [
            'QQQ', // Invesco QQQ Trust
            'XLK', // Technology Select Sector SPDR Fund
            'IYW', // iShares U.S. Technology ETF
            'FDN', // First Trust Dow Jones Internet Index Fund
            'SKYY', // First Trust Cloud Computing ETF
            'PNQI', // Invesco NASDAQ Internet ETF
            'ARKK', // ARK Innovation ETF
            'FDIG', // Fidelity Crypto Industry and Digital Payments ETF
          ],
        },
        Mid_Cap: {
          Mid_Core: [
            'IJH', // iShares Core S&P Mid-Cap ETF
            'VO', // Vanguard Mid-Cap ETF
            'IWR', // iShares Russell Mid-Cap ETF
            'SCHM', // Schwab U.S. Mid-Cap ETF
            'SPMD', // SPDR Portfolio S&P 400 Mid Cap ETF
            'XMLV', // Invesco S&P MidCap Low Volatility ETF
            'IWP', // iShares Russell Mid-Cap Growth ETF
          ],
          Mid_Growth: [
            'IWP', // iShares Russell Mid-Cap Growth ETF
            'MDYG', // SPDR S&P 400 Mid Cap Growth ETF
            'RFG', // Invesco S&P MidCap 400 Pure Growth ETF
            // 'JKH', // iShares Morningstar Mid-Cap Growth ETF
            'VOT', // Vanguard Mid-Cap Growth ETF
            'IJK', // iShares S&P Mid-Cap 400 Growth ETF
            'PWB', // Invesco Dynamic Large Cap Growth ETF
            'FAD', // First Trust Multi Cap Growth AlphaDEX Fund
          ],
          Mid_Value: [
            'IWS', // iShares Russell Mid-Cap Value ETF
            'MDYV', // SPDR S&P 400 Mid Cap Value ETF
            'RFV', // Invesco S&P MidCap 400 Pure Value ETF
            'VOE', // Vanguard Mid-Cap Value ETF
            'DON', // WisdomTree U.S. MidCap Dividend Fund
            // 'JKI', // iShares Morningstar Mid-Cap Value ETF
            'IJJ', // iShares S&P Mid-Cap 400 Value ETF
            'FNK', // First Trust Mid Cap Value AlphaDEX Fund
          ],
        },
        Small_Cap: {
          Small_Core: [
            'VB', // Vanguard Small-Cap ETF
            // 'SLY', // SPDR S&P 600 Small Cap ETF
            'SCHA', // Schwab U.S. Small-Cap ETF
            'SPSM', // SPDR Portfolio S&P 600 Small Cap ETF
            'VTWO', // Vanguard Russell 2000 ETF
            'IWC', // iShares Micro-Cap ETF
            'VIOO', // Vanguard S&P Small-Cap 600 ETF
            'IWM', // iShares Russell 2000 ETF
          ],
          Small_Growth: [
            'IWO', // iShares Russell 2000 Growth ETF
            'VBK', // Vanguard Small-Cap Growth ETF
            'IJT', // iShares S&P Small-Cap 600 Growth ETF
            'SLYG', // SPDR S&P 600 Small Cap Growth ETF
            'FYC', // First Trust Small Cap Growth AlphaDEX Fund
            // 'PZI', // Invesco Zacks Micro Cap ETF
            // 'TWOK', // SPDR Russell 2000 ETF
            'RWJ', // Invesco S&P SmallCap 600 Revenue ETF
          ],
          Small_Value: [
            'IWN', // iShares Russell 2000 Value ETF
            'VBR', // Vanguard Small-Cap Value ETF
            'IJS', // iShares S&P Small-Cap 600 Value ETF
            'SLYV', // SPDR S&P 600 Small Cap Value ETF
            'DES', // WisdomTree U.S. SmallCap Dividend Fund
            'XSLV', // Invesco S&P SmallCap Low Volatility ETF
            'DGRS', // WisdomTree U.S. SmallCap Quality Dividend Growth Fund
            'VTWV', // Vanguard Russell 2000 Value ETF
            // 'PXSV', // Invesco Russell 2000 Pure Value ETF
          ],
        },
        Small_Mid_Cap: {
          Small_Mid_Diversified: [
            'SMDV', // ProShares Russell 2000 Dividend Growers ETF
            'VXF', // Vanguard Extended Market ETF
          ],
          Small_Mid_Growth: [
            'PSCT', // Invesco S&P SmallCap Information Technology ETF
            'VTWG', // Vanguard Russell 2000 Growth ETF
          ],
          Small_Mid_Value: [
            // 'JKL', // iShares Morningstar Small-Cap Value ETF
            'DFSV', // Dimensional U.S. Small Cap Value ETF
            // 'PXSV', // Invesco Russell 2000 Pure Value ETF
            'VIOV', // Vanguard S&P Small-Cap 600 Value ETF
            'IVOV', // Vanguard S&P Mid-Cap 400 Value ETF
          ],
        },
      },
      Sector_Based: {
        Technology_Communication: {
          Technology: [
            'XITK', // SPDR FactSet Innovative Technology ETF
            'KOMP', // SPDR S&P Kensho New Economies Composite ETF
            'IGM', // iShares Expanded Tech Sector ETF
            'VGT', // Vanguard Information Technology ETF
            // 'RYT', // Invesco S&P 500 Equal Weight Technology ETF
            'IETC', // iShares Evolved U.S. Technology ETF
            // 'MTK', // SPDR Morgan Stanley Technology ETF
            'PTF', // Invesco DWA Technology Momentum ETF
            'IGV', // iShares Expanded Tech-Software Sector ETF
            'XNTK', // SPDR NYSE Technology ETF
          ],
          Semiconductors: [
            'SMH', // VanEck Vectors Semiconductor ETF
            'XSD', // SPDR S&P Semiconductor ETF
            'PSI', // Invesco Dynamic Semiconductors ETF
            'SOXX', // iShares Semiconductor ETF
            'SOXQ', // Invesco PHLX Semiconductor ETF
            'USD', // ProShares Ultra Semiconductors
            'SSG', // ProShares UltraShort Semiconductors
          ],
          Communication_Infrastructure: [
            'FCOM', // Fidelity MSCI Communication Services Index ETF
            'XLC', // Communication Services Select Sector SPDR Fund
            'IYZ', // iShares U.S. Telecommunications ETF
            'VOX', // Vanguard Communication Services ETF
            // 'FIVG', // Defiance Next Gen Connectivity ETF
            'ISTB', // iShares Core 1-5 Year USD Bond ETF (included for infrastructure funding exposure)
            'NBCT', // Neuberger Berman Next Generation Connectivity Fund Inc.
            'NETL', // NETLease Corporate Real Estate ETF
          ],
          Cybersecurity: [
            'HACK', // ETFMG Prime Cyber Security ETF
            'CIBR', // First Trust NASDAQ Cybersecurity ETF
            'BUG', // Global X Cybersecurity ETF
            'IHAK', // iShares Cybersecurity and Tech ETF
            'WCBR', // WisdomTree Cybersecurity Fund
            // 'HAKK', // ETFMG Cyber Security ESG ETF
          ],
          Network_Security: [
            'SOCL', // Global X Social Media ETF
            'SNSR', // Global X Internet of Things ETF
            'IPAY', // ETFMG Prime Mobile Payments ETF
            // 'FIVG', // Defiance Next Gen Connectivity ETF
            'ARKW', // ARK Next Generation Internet ETF
            // 'VPN', // Global X Data Center REITs & Digital Infrastructure ETF
            'META', // Roundhill Ball Metaverse ETF
            // 'XWEB', // SPDR S&P Internet ETF
          ],
        },
        Healthcare_Financial: {
          Healthcare_Broad: [
            'XLV', // Health Care Select Sector SPDR Fund
            'VHT', // Vanguard Health Care ETF
            'IYH', // iShares U.S. Healthcare ETF
            'FHLC', // Fidelity MSCI Health Care Index ETF
            // 'RYH', // Invesco S&P 500 Equal Weight Health Care ETF
            'IXJ', // iShares Global Healthcare ETF
            // 'IXV', // iShares S&P 500 Health Care Sector ETF
            'PTH', // Invesco DWA Healthcare Momentum ETF
            // 'XLVS', // ProShares Ultra Health Care ETF
            'IHI', // iShares U.S. Medical Devices ETF
          ],
          Biotechnology: [
            'IBB', // iShares Nasdaq Biotechnology ETF
            'XBI', // SPDR S&P Biotech ETF
            'BBH', // VanEck Vectors Biotech ETF
            'ARKG', // ARK Genomic Revolution ETF
            'PBE', // Invesco Dynamic Biotechnology & Genome ETF
            'GNOM', // Global X Genomics & Biotechnology ETF
            'BBC', // Virtus LifeSci Biotech Clinical Trials ETF
            'BBP', // Virtus LifeSci Biotech Products ETF
            'SBIO', // ALPS Medical Breakthroughs ETF
          ],
          Medical_Devices: [
            'IHI', // iShares U.S. Medical Devices ETF
            'XHE', // SPDR S&P Health Care Equipment ETF
            'PSCH', // Invesco S&P SmallCap Health Care ETF
            'XHS', // SPDR S&P Health Care Services ETF
            'HTEC', // ROBO Global Healthcare Technology and Innovation ETF
            // 'IEIH', // iShares Evolved U.S. Innovative Healthcare ETF
            // 'HEAL', // iShares U.S. Healthcare Providers ETF
            'DRIV', // Global X Autonomous & Electric Vehicles ETF
          ],
          Financial_Services: [
            'XLF', // Financial Select Sector SPDR Fund
            'VFH', // Vanguard Financials ETF
            'FNCL', // Fidelity MSCI Financials Index ETF
            'IYG', // iShares U.S. Financial Services ETF
            'KCE', // SPDR S&P Capital Markets ETF
            // 'RYF', // Invesco S&P 500 Equal Weight Financials ETF
            'KIE', // SPDR S&P Insurance ETF
            'IYF', // iShares U.S. Financials ETF
            'KBE', // SPDR S&P Bank ETF
            'KRE', // SPDR S&P Regional Banking ETF
            'IAT', // iShares U.S. Regional Banks ETF
            'IAI', // iShares U.S. Broker-Dealers & Securities Exchanges ETF
            'FXO', // First Trust Financials AlphaDEX Fund
            'BIZD', // VanEck Vectors BDC Income ETF
            'KBWB', // Invesco KBW Bank ETF
          ],
        },
        Consumer_Industrial_Energy: {
          Consumer_Discretionary: [
            'XLY', // Consumer Discretionary Select Sector SPDR Fund
            'VCR', // Vanguard Consumer Discretionary ETF
            'IYC', // iShares U.S. Consumer Discretionary ETF
            'FDIS', // Fidelity MSCI Consumer Discretionary Index ETF
            // 'RCD', // Invesco S&P 500 Equal Weight Consumer Discretionary ETF
            'FXD', // First Trust Consumer Discretionary AlphaDEX Fund
            'XRT', // SPDR S&P Retail ETF
            'RTH', // VanEck Vectors Retail ETF
            // 'BITE', // Direxion Daily Consumer Discretionary Bull 3X Shares
          ],
          Consumer_Staples: [
            'XLP', // Consumer Staples Select Sector SPDR Fund
            'VDC', // Vanguard Consumer Staples ETF
            'FSTA', // Fidelity MSCI Consumer Staples Index ETF
            // 'RHS', // Invesco S&P 500 Equal Weight Consumer Staples ETF
            'FXG', // First Trust Consumer Staples AlphaDEX Fund
            'IYK', // iShares U.S. Consumer Goods ETF
            'PBJ', // Invesco Dynamic Food & Beverage ETF
            'KXI', // iShares Global Consumer Staples ETF
          ],
          Renewable_Energy: [
            'ICLN', // iShares Global Clean Energy ETF
            'TAN', // Invesco Solar ETF
            'QCLN', // First Trust NASDAQ Clean Edge Green Energy Index Fund
            'ACES', // ALPS Clean Energy ETF
            'GRID', // First Trust NASDAQ Clean Edge Smart Grid Infrastructure Index Fund
            'SMOG', // VanEck Vectors Low Carbon Energy ETF
            'FAN', // First Trust Global Wind Energy ETF
            'PBW', // Invesco WilderHill Clean Energy ETF
            'CNRG', // SPDR S&P Kensho Clean Power ETF
            'BATT', // Amplify Lithium & Battery Technology ETF
          ],
          Traditional_Energy: [
            'XLE', // Energy Select Sector SPDR Fund
            'VDE', // Vanguard Energy ETF
            'IYE', // iShares U.S. Energy ETF
            'PXI', // Invesco DWA Energy Momentum ETF
            // 'RYE', // Invesco S&P 500 Equal Weight Energy ETF
            'OIH', // VanEck Vectors Oil Services ETF
            'XOP', // SPDR S&P Oil & Gas Exploration & Production ETF
            'IEO', // iShares U.S. Oil & Gas Exploration & Production ETF
            'PSCE', // Invesco S&P SmallCap Energy ETF
            'AMLP', // Alerian MLP ETF
          ],
          Commercial_Aerospace: [
            'ITA', // iShares U.S. Aerospace & Defense ETF
            'PPA', // Invesco Aerospace & Defense ETF
            'XAR', // SPDR S&P Aerospace & Defense ETF
            'MISL', // SPDR S&P Kensho Final Frontiers ETF
            // 'FLYA', // Direxion Flight to Safety Strategy ETF
          ],
          Infrastructure: [
            'IGF', // iShares Global Infrastructure ETF
            'NFRA', // FlexShares STOXX Global Broad Infrastructure Index Fund
            'TOLZ', // ProShares DJ Brookfield Global Infrastructure ETF
            // 'GHII', // Invesco S&P High Income Infrastructure ETF
            'EMIF', // iShares Emerging Markets Infrastructure ETF
            'MLPX', // Global X MLP & Energy Infrastructure ETF
            'UTF', // Cohen & Steers Infrastructure Fund, Inc.
          ],
          Utilities: [
            'VPU', // Vanguard Utilities ETF
            'XLU', // Utilities Select Sector SPDR Fund
            'IDU', // iShares U.S. Utilities ETF
            'FUTY', // Fidelity MSCI Utilities Index ETF
            'FXU', // First Trust Utilities AlphaDEX Fund
            'UTES', // Virtus Reaves Utilities ETF
            // 'RYU', // Invesco S&P 500 Equal Weight Utilities ETF
          ],
          Automotive: [
            'CARZ', // First Trust NASDAQ Global Auto Index Fund
            'KARS', // KraneShares Electric Vehicles and Future Mobility Index ETF
            'IDRV', // iShares Self-Driving EV and Tech ETF
            'MOTO', // SmartETFs Smart Transportation & Technology ETF
            // 'EKAR', // Capital Link NextGen Vehicles & Technology ETF
            'HAIL', // SPDR S&P Kensho Smart Mobility ETF
            'FDRV', // Fidelity Electric Vehicles and Future Transportation ETF
            // 'VROM', // T. Rowe Price Blue Chip Growth ETF
          ],
        },
      },
      Factor_Based: {
        Momentum_Based: {
          Bull_Market_Leveraged: [
            'UPRO', // ProShares UltraPro S&P500
            'UMDD', // ProShares UltraPro MidCap400
            'TQQQ', // ProShares UltraPro QQQ
            'SPXL', // Direxion Daily S&P 500 Bull 3X Shares
            'UDOW', // ProShares UltraPro Dow30
            'URTY', // ProShares UltraPro Russell2000
            'FNGU', // MicroSectors FANG+ Index 3X Leveraged ETN
          ],
          Sector_Specific_Leveraged: [
            'LABU', // Direxion Daily S&P Biotech Bull 3X Shares
            'DRN', // Direxion Daily Real Estate Bull 3X Shares
            'FAS', // Direxion Daily Financial Bull 3X Shares
            'DFEN', // Direxion Daily Aerospace & Defense Bull 3X Shares
            'CURE', // Direxion Daily Healthcare Bull 3X Shares
            'NUGT', // Direxion Daily Gold Miners Index Bull 2X Shares
            'SOXL', // Direxion Daily Semiconductor Bull 3X Shares
            'TECL', // Direxion Daily Technology Bull 3X Shares
          ],
          Broad_Market_Inverse: [
            'SPXU', // ProShares UltraPro Short S&P500
            'SQQQ', // ProShares UltraPro Short QQQ
            'TZA', // Direxion Daily Small Cap Bear 3X Shares
            'SDOW', // ProShares UltraPro Short Dow30
            'SH', // ProShares Short S&P500
            'PSQ', // ProShares Short QQQ
            'DOG', // ProShares Short Dow30
          ],
          Sector_Specific_Inverse: [
            'LABD', // Direxion Daily S&P Biotech Bear 3X Shares
            'FAZ', // Direxion Daily Financial Bear 3X Shares
            'DUST', // Direxion Daily Gold Miners Index Bear 2X Shares
            'ERY', // Direxion Daily Energy Bear 3X Shares
            'SOXS', // Direxion Daily Semiconductor Bear 3X Shares
            'TECS', // Direxion Daily Technology Bear 3X Shares
            'WEBS', // Direxion Daily Dow Jones Internet Bear 3X Shares
          ],
        },
        Fundamentals_Based: {
          ESG_Focus: [
            'ESGU', // iShares ESG Aware MSCI USA ETF
            'SUSA', // iShares MSCI USA ESG Select ETF
            'DSI', // iShares MSCI KLD 400 Social ETF
            'SPYX', // SPDR S&P 500 Fossil Fuel Reserves Free ETF
            'JUST', // Goldman Sachs JUST U.S. Large Cap Equity ETF
            'CRBN', // iShares MSCI ACWI Low Carbon Target ETF
            'NULV', // Nuveen ESG Large-Cap Value ETF
            'EFAX', // SPDR MSCI EAFE Fossil Fuel Reserves Free ETF
            'EEMX', // SPDR MSCI Emerging Markets Fossil Fuel Reserves Free ETF
          ],
          Fundamental_Growth: [
            'MGK', // Vanguard Mega Cap Growth ETF
            'IUSG', // iShares Core S&P U.S. Growth ETF
            'IVW', // iShares S&P 500 Growth ETF
            'VONG', // Vanguard Russell 1000 Growth ETF
            'SCHG', // Schwab U.S. Large-Cap Growth ETF
            'MTUM', // iShares Edge MSCI USA Momentum Factor ETF
            'IWY', // iShares Russell Top 200 Growth ETF
            'SPYG', // SPDR Portfolio S&P 500 Growth ETF
            'VUG', // Vanguard Growth ETF
          ],
          Quality: [
            'QUAL', // iShares MSCI USA Quality Factor ETF
            'SPHQ', // Invesco S&P 500 Quality ETF
            'DGRW', // WisdomTree U.S. Quality Dividend Growth Fund
            'QDF', // FlexShares Quality Dividend Index Fund
            'OUSA', // O’Shares U.S. Quality Dividend ETF
            'COWZ', // Pacer US Cash Cows 100 ETF
            'SPHD', // Invesco S&P 500 High Dividend Low Volatility ETF
            'EUSA', // iShares MSCI USA Equal Weighted ETF
          ],
          Value_vs_Growth: [
            'VLUE', // iShares MSCI USA Value Factor ETF
            'IVE', // iShares S&P 500 Value ETF
            'VONV', // Vanguard Russell 1000 Value ETF
            'SCHV', // Schwab U.S. Large-Cap Value ETF
            'VTV', // Vanguard Value ETF
            'IWD', // iShares Russell 1000 Value ETF
            // 'DVP', // Deep Value ETF
            'FVAL', // Fidelity Value Factor ETF
          ],
        },
      },
      Fixed_Income: {
        Issuer_Type: {
          Government_Bonds: [
            'GOVT', // iShares U.S. Treasury Bond ETF
            'TLT', // iShares 20+ Year Treasury Bond ETF
            'SHV', // iShares Short Treasury Bond ETF
            'SHY', // iShares 1-3 Year Treasury Bond ETF
            'IEF', // iShares 7-10 Year Treasury Bond ETF
            'VGIT', // Vanguard Intermediate-Term Treasury ETF
            'SCHO', // Schwab Short-Term U.S. Treasury ETF
            'TLH', // iShares 10-20 Year Treasury Bond ETF

          ],
          Corporate_Bonds: [
            'LQD', // iShares iBoxx $ Investment Grade Corporate Bond ETF
            'VCLT', // Vanguard Long-Term Corporate Bond ETF
            'VCIT', // Vanguard Intermediate-Term Corporate Bond ETF
            'HYG', // iShares iBoxx $ High Yield Corporate Bond ETF
            'JNK', // SPDR Bloomberg Barclays High Yield Bond ETF
            'ANGL', // VanEck Vectors Fallen Angel High Yield Bond ETF
            'USIG', // iShares Broad USD Investment Grade Corporate Bond ETF
            'LQDH', // iShares Interest Rate Hedged Corporate Bond ETF
            'SPIB', // SPDR Portfolio Intermediate Term Corporate Bond ETF
          ],
          Municipal_Bonds: [
            'MUB', // iShares National Muni Bond ETF
            'TFI', // SPDR Nuveen Bloomberg Barclays Municipal Bond ETF
            'PZA', // Invesco National AMT-Free Municipal Bond ETF
            'HYD', // VanEck Vectors High-Yield Municipal Index ETF
            'VTEB', // Vanguard Tax-Exempt Bond ETF
            'SHM', // SPDR Nuveen Bloomberg Barclays Short Term Municipal Bond ETF
            'ITM', // VanEck Vectors AMT-Free Intermediate Municipal Index ETF
            'SUB', // iShares Short-Term National Muni Bond ETF
          ],
          International_Bonds: [
            'BNDX', // Vanguard Total International Bond ETF
            'IGOV', // iShares International Treasury Bond ETF
            'EMB', // iShares J.P. Morgan USD Emerging Markets Bond ETF
            'VWOB', // Vanguard Emerging Markets Government Bond ETF
            'PCY', // Invesco Emerging Markets Sovereign Debt ETF
            'IAGG', // iShares Core International Aggregate Bond ETF
            'EMLC', // VanEck Vectors J.P. Morgan EM Local Currency Bond ETF
            'BWX', // SPDR Bloomberg Barclays International Treasury Bond ETF
            'IBND', // SPDR Bloomberg Barclays International Corporate Bond ETF
            'IGLB', // iShares Long-Term Corporate Bond ETF
          ],
        },
        Maturity: {
          Short_Term_Bonds: [
            'BSV', // Vanguard Short-Term Bond ETF
            'MINT', // PIMCO Enhanced Short Maturity Active Exchange-Traded Fund
            'JPST', // JPMorgan Ultra-Short Income ETF
            'ICSH', // BlackRock Ultra Short-Term Bond ETF
            'BIL', // SPDR Bloomberg Barclays 1-3 Month T-Bill ETF
            'VGSH', // Vanguard Short-Term Treasury ETF
            'SPSB', // SPDR Portfolio Short Term Corporate Bond ETF
            'VCSH', // Vanguard Short-Term Corporate Bond ETF
          ],
          Intermediate_Term_Bonds: [
            'SCHR', // Schwab Intermediate-Term U.S. Treasury ETF
            'IGIB', // iShares Intermediate-Term Corporate Bond ETF
            'BIV', // Vanguard Intermediate-Term Bond ETF
            'IUSB', // iShares Core Total USD Bond Market ETF
            'GVI', // iShares Intermediate Government/Credit Bond ETF
            'AGG', // iShares Core U.S. Aggregate Bond ETF
            'BND', // Vanguard Total Bond Market ETF
          ],
          Long_Term_Bonds: [
            'BLV', // Vanguard Long-Term Bond ETF
            'ZROZ', // PIMCO 25+ Year Zero Coupon U.S. Treasury Index ETF
            'EDV', // Vanguard Extended Duration Treasury ETF
            'LTPZ', // PIMCO 15+ Year U.S. TIPS Index Exchange-Traded Fund
            'VGLT', // Vanguard Long-Term Treasury ETF
            'SPLB', // SPDR Portfolio Long Term Corporate Bond ETF
          ],
        },
      },
      Real_Estate: {
        Region: {
          Domestic_Real_Estate: [
            'IYR', // iShares U.S. Real Estate ETF
            'USRT', // iShares Core U.S. REIT ETF
            'FREL', // Fidelity MSCI Real Estate Index ETF
            'REM', // iShares Mortgage Real Estate ETF
            'MORT', // VanEck Vectors Mortgage REIT Income ETF
          ],
          International_Real_Estate: [
            'VNQI', // Vanguard Global ex-U.S. Real Estate ETF
            'RWX', // SPDR Dow Jones International Real Estate ETF
            'IFGL', // iShares International Developed Real Estate ETF
            'HAUZ', // Xtrackers International Real Estate ETF
            // 'WPS', // iShares International Developed Property ETF
            'IPAC', // iShares Core MSCI Pacific ETF
          ],
          Diversified_Global_Real_Estate: [
            'REET', // iShares Global REIT ETF
            'RWO', // SPDR Dow Jones Global Real Estate ETF
            // 'DRW', // WisdomTree Global ex-U.S. Real Estate Fund
            'GRI', // Cohen & Steers Global Realty Majors ETF
            // 'TAO', // Invesco China Real Estate ETF
          ],
        },
        Property_Type: {
          Residential_Real_Estate: [
            'REZ', // iShares Residential and Multisector Real Estate ETF
            'HOMZ', // Hoya Capital Housing ETF
            // 'ROOF', // IQ U.S. Real Estate Small Cap ETF
            // 'RESI', // Virtus Real Asset Income ETF
          ],
          Commercial_Real_Estate: [
            'ICF', // iShares Cohen & Steers REIT ETF
            'SCHH', // Schwab U.S. REIT ETF
            'PSR', // Invesco Active U.S. Real Estate ETF
            'XLRE', // Real Estate Select Sector SPDR Fund
            'RWR', // SPDR Dow Jones REIT ETF
            'VNQ', // Vanguard Real Estate ETF
          ],
          Industrial_Real_Estate: [
            'INDS', // Pacer Benchmark Industrial Real Estate SCTR ETF
            'WOOD', // iShares Global Timber & Forestry ETF
            'CUT', // Invesco MSCI Global Timber ETF
            'PAVE', // Global X U.S. Infrastructure Development ETF
            'IFRA', // iShares U.S. Infrastructure ETF
            'NURE', // Nuveen Short-Term REIT ETF
          ],
        },
      },
      Commodities: {
        Type: {
          Metals: [
            'GLD', // SPDR Gold Shares
            'SLV', // iShares Silver Trust
            'PPLT', // Aberdeen Standard Physical Platinum Shares ETF
            'IAU', // iShares Gold Trust
            'PALL', // Aberdeen Standard Physical Palladium Shares ETF
            'SIVR', // Aberdeen Standard Physical Silver Shares ETF
            'SGOL', // Aberdeen Standard Physical Gold Shares ETF
            'OUNZ', // VanEck Merk Gold Trust
            'GLDM', // SPDR Gold MiniShares Trust
            'BAR', // GraniteShares Gold Trust
          ],
          Energy: [
            'USO', // United States Oil Fund LP
            'UNG', // United States Natural Gas Fund LP
            'DBE', // Invesco DB Energy Fund
            'UCO', // ProShares Ultra Bloomberg Crude Oil
            'BOIL', // ProShares Ultra Bloomberg Natural Gas
            'BNO', // United States Brent Oil Fund LP
            'UGA', // United States Gasoline Fund LP
            'KOLD', // ProShares UltraShort Bloomberg Natural Gas
            'DRIP', // Direxion Daily S&P Oil & Gas Exp. & Prod. Bear 2X Shares
            'OILK', // ProShares K-1 Free Crude Oil Strategy ETF
            'USL', // United States 12 Month Oil Fund LP
          ],
          Agriculture: [
            'DBA', // Invesco DB Agriculture Fund
            'CORN', // Teucrium Corn Fund
            'WEAT', // Teucrium Wheat Fund
            'SOYB', // Teucrium Soybean Fund
            'CANE', // Teucrium Sugar Fund
            // 'NIB', // iPath Bloomberg Cocoa Subindex Total Return ETN
            // 'JO', // iPath Series B Bloomberg Coffee Subindex Total Return ETN
            // 'RJA', // Elements Rogers International Commodity Index-Agriculture Total Return ETN
            // 'COW', // iPath Series B Bloomberg Livestock Subindex Total Return ETN
            'TAGS', // Teucrium Agricultural Fund
          ],
        },
      },
    },
  };