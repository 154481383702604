// src/data/userData.js

// Helper functions
const getRandomNumber = (min, max, decimals = 2) =>
  parseFloat((Math.random() * (max - min) + min).toFixed(decimals));
const getRandomBoolean = () => Math.random() > 0.5;

// Account Types
const ACCOUNT_TYPES = [
  'Pre-Tax 401(k)',
  'After-Tax 401(k)',
  'Roth IRA',
  'Traditional IRA',
  'Health Savings Account',
  'Individual Brokerage',
  'Joint Brokerage',
];

// Generate accounts data
const generateAccounts = () => {
  return ACCOUNT_TYPES.map((type, index) => {
    const exists = getRandomBoolean();
    return {
      id: index + 1,
      name: type,
      balance: exists ? getRandomNumber(5000, 100000) : 0,
      account_type: type,
      account_number: `****${getRandomNumber(1000, 9999, 0)}`,
      routing_number: `****${getRandomNumber(1000, 9999, 0)}`,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      performance: exists
        ? {
            '1D': getRandomNumber(-2, 5),
            '1W': getRandomNumber(-10, 15),
            '1M': getRandomNumber(-20, 25),
            '6M': getRandomNumber(-30, 35),
            '1Y': getRandomNumber(-40, 45),
          }
        : { '1D': 0, '1W': 0, '1M': 0, '6M': 0, '1Y': 0 },
    };
  }).filter((account) => account.balance > 0); // Filter out accounts with no balance
};

// Dummy User Data
const dummyUserData = {
  UserID: "dummy-id",
  firstname: "John",
  lastname: "Doe",
  email: "johndoe@example.com",
  phone_number: "123-456-7890",
  street_addr: "123 Main St",
  city_addr: "Metropolis",
  state_addr: "NY",
  country_addr: "USA",
  zip_code: "10001",
  dob: "1985-05-15",
  is_email_verified: true,
  is_verified_by_algoinvest: true,
  two_factor_enabled: getRandomBoolean(),
  accounts: generateAccounts(),
};

export default dummyUserData;


// // src/data/userData.js

// // Helper functions
// const getRandomNumber = (min, max, decimals = 2) =>
//     parseFloat((Math.random() * (max - min) + min).toFixed(decimals));
//   const getRandomBoolean = () => Math.random() > 0.5;
  
//   // Account Types
//   const ACCOUNT_TYPES = [
//     'Pre-Tax 401(k)',
//     'After-Tax 401(k)',
//     'Roth IRA',
//     'Traditional IRA',
//     'Health Savings Account',
//     'Individual Brokerage',
//     'Joint Brokerage',
//   ];
  
//   // Generate accounts data
//   const generateAccounts = () => {
//     const accounts = {};
  
//     ACCOUNT_TYPES.forEach((type) => {
//       const hasAccount = getRandomBoolean();
//       if (hasAccount) {
//         accounts[type] = {
//           exists: true,
//           balance: getRandomNumber(5000, 100000),
//           performance: {
//             '1D': getRandomNumber(-2, 5),
//             '1W': getRandomNumber(-10, 15),
//             '1M': getRandomNumber(-20, 25),
//             '6M': getRandomNumber(-30, 35),
//             '1Y': getRandomNumber(-40, 45),
//           },
//         };
//       } else {
//         accounts[type] = {
//           exists: false,
//           balance: 0,
//           performance: {
//             '1D': 0,
//             '1W': 0,
//             '1M': 0,
//             '6M': 0,
//             '1Y': 0,
//           },
//         };
//       }
//     });
  
//     return accounts;
//   };
  
//   // User Data
//   const userData = {
//     name: 'Christian Lizardi',
//     accounts: generateAccounts(),
//   };
  
//   export default userData;
  