// src/pages/PortfolioArena/Deployment.js

import React, { useState } from 'react';
import { Box, Typography, Button, Input } from '@mui/joy';

const Deploy = ({
  portfolioName,
  backtestResults,
  selectedAssets,
  clusteringOptions,
  riskModelOptions,
  alphaModelOptions,
  portfolioConstructionOptions,
  tradingOptions,
  taxOptions,
}) => {
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [confirmation, setConfirmation] = useState(false);

  const handleDeploy = () => {
    // Implement deployment logic (e.g., API call to brokerage)
    // Placeholder implementation
    setConfirmation(true);
  };

  return (
    <Box>
      <Typography level="h6" sx={{ mb: 2 }}>
        Deploy
      </Typography>

      <Typography sx={{ mb: 2 }}>
        Review your strategy configuration before deploying.
      </Typography>

      {/* Portfolio Name */}
      <Typography level="subtitle1">Portfolio Name:</Typography>
      <Typography>{portfolioName}</Typography>

      {/* Investment Amount */}
      <Typography level="subtitle1" sx={{ mt: 2 }}>
        Investment Amount ($):
      </Typography>
      <Input
        placeholder="Enter Investment Amount"
        type="number"
        value={investmentAmount}
        onChange={(e) => setInvestmentAmount(e.target.value)}
        sx={{ mb: 2 }}
      />

      {/* Display configuration summary */}
      {/* ... (same as before) */}

      <Button variant="solid" onClick={handleDeploy} sx={{ mt: 4 }}>
        Deploy Strategy
      </Button>

      {confirmation && (
        <Typography sx={{ mt: 2, color: 'green' }}>
          Strategy "{portfolioName}" has been successfully deployed with an investment amount of $
          {investmentAmount}!
        </Typography>
      )}
    </Box>
  );
};

export default Deploy;