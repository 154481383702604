// src/pages/PortfolioArena/Taxes.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  Option,
  CircularProgress,
} from '@mui/joy';
import PerformanceChart from '../../../components/charts/PerformanceChart';
import ConfigurableInlineAssetFolder from './ConfigurableInlineAssetFolder';
import {
  granularityOptions,
  generateTaxCostData,
  generateTaxLossHarvestingData,
} from '../../../data/assetClassDataGenerators';
import { getAccounts } from '../../../utils/api';

const Taxes = ({
  selectedAssets = [],
  clusteringOptions,
  taxOptions,
  setTaxOptions,
}) => {
  const [granularity, setGranularity] = useState('1Y');
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [runningTests, setRunningTests] = useState({});
  const [nodeResults, setNodeResults] = useState({});
  const [selectedModelRun, setSelectedModelRun] = useState('');
  const [plotType, setPlotType] = useState('taxCost'); // 'taxCost' or 'taxLossHarvesting'
  const [expandedNodes, setExpandedNodes] = useState(new Set());

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (taxOptions) {
      setNodeResults((prev) => ({ ...prev, ...taxOptions }));
      const modelRunPaths = Object.keys(taxOptions);
      if (modelRunPaths.length > 0) {
        setSelectedModelRun(modelRunPaths[modelRunPaths.length - 1]);
      }
    }
  }, [taxOptions]);

  const fetchAccounts = async () => {
    try {
      const fetchedAccounts = await getAccounts();
      setAccounts(fetchedAccounts);
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const toggleNode = (pathKey) => {
    setExpandedNodes((prev) => {
      const next = new Set(prev);
      if (next.has(pathKey)) {
        next.delete(pathKey);
      } else {
        next.add(pathKey);
      }
      return next;
    });
  };

  const handleRunModelForNode = (nodePath, assets) => {
    if (!assets || assets.length === 0 || !selectedAccount) return;

    setRunningTests((prev) => ({ ...prev, [nodePath]: true }));

    // Simulate tax model using data generators
    setTimeout(() => {
      // Generate Tax Cost Data
      const taxCostData = {
        chartData: [
          {
            label: 'Tax Cost',
            data: generateTaxCostData(
              granularity,
              selectedAccount,
              assets
            ),
          },
        ],
        metrics: {
          // Add any relevant metrics here
        },
      };

      // Generate Tax-Loss Harvesting Data
      const taxLossHarvestingData = {
        chartData: [
          {
            label: 'Tax-Loss Harvested',
            data: generateTaxLossHarvestingData(
              granularity,
              selectedAccount,
              assets
            ),
          },
        ],
        metrics: {
          // Add any relevant metrics here
        },
      };

      // Update results specific to this node
      setTaxOptions({
        ...taxOptions,
        [nodePath]: {
          taxCost: taxCostData,
          taxLossHarvesting: taxLossHarvestingData,
        },
      });

      setRunningTests((prev) => ({ ...prev, [nodePath]: false }));
    }, 2000);
  };

  const handleSelectModelRun = (event, newValue) => {
    setSelectedModelRun(newValue);
    setPlotType('taxCost'); // Default to tax cost when selecting a new run
  };

  const handlePlotTypeChange = (event, newValue) => {
    setPlotType(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography level="h5" sx={{ mb: 2 }}>
        Taxes
      </Typography>

      {/* Account Selection */}
      <Box sx={{ mb: 4 }}>
        <Typography level="subtitle1" sx={{ mb: 1 }}>
          Select Account:
        </Typography>
        <FormControl sx={{ minWidth: 300 }}>
          <Select
            placeholder="Choose Account"
            value={selectedAccount}
            onChange={(e, newValue) => setSelectedAccount(newValue)}
          >
            {accounts.map((account) => (
              <Option key={account.id} value={account.id}>
                {account.name} ({account.account_type})
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Granularity Selector */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            placeholder="Select Granularity"
            value={granularity}
            onChange={(e, newValue) => setGranularity(newValue)}
          >
            {granularityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Folder Structure Display */}
      <Box sx={{ mb: 4 }}>
        {clusteringOptions?.assetHierarchy ? (
          <ConfigurableInlineAssetFolder
            node={clusteringOptions.assetHierarchy}
            expandedNodes={expandedNodes}
            toggleNode={toggleNode}
            handleRunModelForNode={handleRunModelForNode}
            runningTests={runningTests}
            selectedAssets={selectedAssets}
          />
        ) : (
          <Typography>No asset hierarchy available.</Typography>
        )}
      </Box>

      {/* Model Run Dropdown Filter */}
      {Object.keys(nodeResults).length > 0 && (
        <Box sx={{ mb: 4 }}>
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              placeholder="Select Model Run to View"
              value={selectedModelRun}
              onChange={handleSelectModelRun}
            >
              {Object.keys(nodeResults).map((nodePath) => (
                <Option key={nodePath} value={nodePath}>
                  {nodePath}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Plot Type Selector */}
      {selectedModelRun && (
        <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              placeholder="Select Plot Type"
              value={plotType}
              onChange={handlePlotTypeChange}
            >
              <Option value="taxCost">Tax Cost</Option>
              <Option value="taxLossHarvesting">Tax-Loss Harvesting</Option>
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Display Selected Model Run Results */}
      {selectedModelRun &&
        nodeResults[selectedModelRun] &&
        nodeResults[selectedModelRun][plotType] && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {selectedModelRun}
            </Typography>
            <Box sx={{ height: 600, marginBottom: 4 }}>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#000000',
                }}
              >
                {plotType === 'taxCost'
                  ? 'Tax Costs Over Time'
                  : 'Tax-Loss Harvested Over Time'}
              </Typography>
              <PerformanceChart
                data={nodeResults[selectedModelRun][plotType].chartData}
                granularity={granularity}
                yAxisLabel={
                  plotType === 'taxCost' ? 'Tax Cost ($)' : 'Tax-Loss Harvested ($)'
                }
                tooltipFormat={(value) => `$${value.toFixed(2)}`}
              />
            </Box>
          </Box>
        )}

      {/* Display Loading Indicator for Running Tests */}
      {Object.values(runningTests).some((isRunning) => isRunning) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Taxes;
