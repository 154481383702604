// Backtesting.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import InternalTabBar from '../../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Backtesting = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Backtesting
        </Typography>

        {/* Main High-Level Description */}
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Validating Our Strategies Through Rigorous Testing
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          Backtesting allows us to evaluate the effectiveness of our investment strategies using historical data.
          This critical step helps us refine our models, optimize parameters, and ensure that our strategies are robust and reliable before deployment.
        </Typography>

        {/* Secondary Section Header */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Our Backtesting Process
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Our comprehensive backtesting process involves simulating historical performance, adjusting parameters, and analyzing results to refine our strategies.
          </Typography>
        </Box>

        {/* Backtesting Sections */}
        <Box>
          <BacktestingSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Backtesting;

// BacktestingSections Component
const BacktestingSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Backtesting Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <BacktestingCard title="Simulate Historical Performance">
          <Typography sx={{ color: '#333' }}>
            We test our investment strategies using historical data to assess their effectiveness over various market conditions. This helps us understand how our strategies might perform in real-world scenarios.
          </Typography>
        </BacktestingCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <BacktestingCard title="Adjust Parameters">
          <Typography sx={{ color: '#333' }}>
            Based on backtesting results, we adjust strategy parameters to optimize performance. This iterative process ensures that our models are fine-tuned for maximum effectiveness.
          </Typography>
        </BacktestingCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <BacktestingCard title="Analyze Results">
          <Typography sx={{ color: '#333' }}>
            We thoroughly analyze backtesting outcomes, reviewing performance metrics, risk-adjusted returns, and conducting factor attribution to understand the drivers of performance.
          </Typography>
        </BacktestingCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <BacktestingCard title="Ensure Robustness">
          <Typography sx={{ color: '#333' }}>
            By testing strategies across different time periods and market environments, we ensure that our models are robust and can withstand various market conditions.
          </Typography>
        </BacktestingCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <BacktestingCard title="Avoid Overfitting">
          <Typography sx={{ color: '#333' }}>
            We are careful to avoid overfitting by validating our strategies on out-of-sample data and using techniques such as cross-validation to ensure generalizability.
          </Typography>
        </BacktestingCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <BacktestingCard title="Continuous Improvement">
          <Typography sx={{ color: '#333' }}>
            Backtesting is an ongoing process. We continuously monitor strategy performance and incorporate new data to refine and improve our models over time.
          </Typography>
        </BacktestingCard>
      </Grid>
    </Grid>
  </Box>
);

// BacktestingCard Component
const BacktestingCard = ({ title, children }) => (
  <Box
    sx={{
      padding: '1.5rem',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      transition: 'transform 0.3s',
      '&:hover': {
        transform: 'translateY(-5px)',
      },
    }}
  >
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

BacktestingCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
