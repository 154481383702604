export const GLOBAL_ETF_LISTS = {
    "global_broad": [
      'ACWI', // iShares MSCI ACWI ETF
      'VT',   // Vanguard Total World Stock ETF
      'URTH', // iShares MSCI World ETF
      'VEU',  // Vanguard FTSE All-World ex-US ETF
      'IXUS', // iShares Core MSCI Total International Stock ETF
      'SPDW', // SPDR Portfolio Developed World ex-US ETF
      'SCHF', // Schwab International Equity ETF
      'VEA',  // Vanguard FTSE Developed Markets ETF
      'ACWX', // iShares MSCI ACWI ex U.S. ETF
      'SPGM', // SPDR Portfolio MSCI Global Stock Market ETF
      'GSIE', // Goldman Sachs ActiveBeta International Equity ETF
      'VTWAX',// Vanguard Total World Stock Index Fund Admiral Shares (mutual fund)
      'VTWNX',// Vanguard Target Retirement 2020 Fund (mutual fund)
      'DWVG', // Davis Select Worldwide ETF
      'RNDW', // First Trust Developed Markets ex-US AlphaDEX Fund
    ],
    "global_specialized": [
      'XT',    // iShares Exponential Technologies ETF
      'KLDW',  // Knowledge Leaders Developed World ETF
      'BOTZ',  // Global X Robotics & Artificial Intelligence ETF
      'DRIV',  // Global X Autonomous & Electric Vehicles ETF
      'FINX',  // Global X FinTech ETF
      'SNSR',  // Global X Internet of Things ETF
      'IXJ',   // iShares Global Healthcare ETF
      'IXP',   // iShares Global Comm Services ETF
      'KXI',   // iShares Global Consumer Staples ETF
      'IGF',   // iShares Global Infrastructure ETF
      'NXTG',  // First Trust Indxx NextG ETF
      'ACWF',  // iShares MSCI Global Multifactor ETF
      'VTWG',  // Vanguard Russell 2000 Growth ETF
      'HEFA',  // iShares Currency Hedged MSCI EAFE ETF
      'PBD',   // Invesco Global Clean Energy ETF
    ],
  };
  
  export const DEVELOPED_MARKETS_ETF_LISTS = {
    "north_america": [
      'SPY',   // SPDR S&P 500 ETF Trust
      'IVV',   // iShares Core S&P 500 ETF
      'VOO',   // Vanguard S&P 500 ETF
      'VTI',   // Vanguard Total Stock Market ETF
      'EWC',   // iShares MSCI Canada ETF
      'FCAN',  // First Trust Canada AlphaDEX Fund
      'XIC',   // iShares Core S&P/TSX Capped Composite Index ETF (Canada)
      'ZCN',   // BMO S&P/TSX Capped Composite Index ETF (Canada)
      'ITOT',  // iShares Core S&P Total U.S. Stock Market ETF
      'VONE',  // Vanguard Russell 1000 ETF
      'XIU',   // iShares S&P/TSX 60 Index ETF (Canada)
      'MDY',   // SPDR S&P MidCap 400 ETF Trust
      'IJR',   // iShares Core S&P Small-Cap ETF
      'IWB',   // iShares Russell 1000 ETF
      'SCHB',  // Schwab U.S. Broad Market ETF
      'ZSP',   // BMO S&P 500 Index ETF (Canada)
    ],
    "europe": [
      'VGK',   // Vanguard FTSE Europe ETF
      'EWU',   // iShares MSCI United Kingdom ETF
      'IEV',   // iShares Europe ETF
      'EZU',   // iShares MSCI Eurozone ETF
      'EWG',   // iShares MSCI Germany ETF
      'EWQ',   // iShares MSCI France ETF
      'EWI',   // iShares MSCI Italy ETF
      'EWL',   // iShares MSCI Switzerland ETF
      'FEZ',   // SPDR EURO STOXX 50 ETF
      'HEDJ',  // WisdomTree Europe Hedged Equity Fund
      'IEUR',  // iShares Core MSCI Europe ETF
      'DAX',   // Global X DAX Germany ETF
      'FLIE',  // Franklin FTSE Europe ETF
      'EUSC',  // WisdomTree Europe SmallCap Dividend Fund
      'DBEU',  // Xtrackers MSCI Europe Hedged Equity ETF
      'FEP',   // First Trust Europe AlphaDEX Fund
      'FEEU',  // Barclays ETN+ FI Enhanced Europe 50 ETN
    ],
    "asia_pacific": [
      'EWJ',   // iShares MSCI Japan ETF
      'VPL',   // Vanguard FTSE Pacific ETF
      'EWA',   // iShares MSCI Australia ETF
      'AAXJ',  // iShares MSCI All Country Asia ex Japan ETF
      'EPP',   // iShares MSCI Pacific ex Japan ETF
      'VNM',   // VanEck Vectors Vietnam ETF
      'INDA',  // iShares MSCI India ETF
      'EWH',   // iShares MSCI Hong Kong ETF
      'EWT',   // iShares MSCI Taiwan ETF
      'EWS',   // iShares MSCI Singapore ETF
      'FPA',   // First Trust Asia Pacific Ex-Japan AlphaDEX Fund
      'FLKR',  // Franklin FTSE South Korea ETF
      'FLTW',  // Franklin FTSE Taiwan ETF
      'HAWX',  // iShares Currency Hedged MSCI ACWI ex U.S. ETF
      'AXJL',  // iShares MSCI All Country Asia ex Japan ETF
      'ASEA',  // Global X FTSE Southeast Asia ETF
      'GMF',   // SPDR S&P Emerging Asia Pacific ETF
    ],
  };
  
  export const EMERGING_MARKETS_ETF_LISTS = {
    "emerging_markets_broad": [
      'EEM',    // iShares MSCI Emerging Markets ETF
      'VWO',    // Vanguard FTSE Emerging Markets ETF
      'IEMG',   // iShares Core MSCI Emerging Markets ETF
      'SCHE',   // Schwab Emerging Markets Equity ETF
      'SPEM',   // SPDR Portfolio Emerging Markets ETF
      'DEM',    // WisdomTree Emerging Markets High Dividend Fund
      'DVYE',   // iShares Emerging Markets Dividend ETF
      'EMXC',   // iShares MSCI Emerging Markets ex China ETF
      'EEMV',   // iShares Edge MSCI Min Vol Emerging Markets ETF
      'EDIV',   // SPDR S&P Emerging Markets Dividend ETF
      'EMFM',   // Global X Next Emerging & Frontier ETF
      'GMM',    // SPDR S&P Emerging Markets ETF
      'EMGF',   // iShares MSCI Emerging Markets Multifactor ETF
      'FNDE',   // Schwab Fundamental Emerging Markets Large Company Index ETF
    ],
    "emerging_markets_asia": [
      'INDA',   // iShares MSCI India ETF
      'EPHE',   // iShares MSCI Philippines ETF
      'SMIN',   // iShares MSCI India Small-Cap ETF
      'EPI',    // WisdomTree India Earnings Fund
      'PIN',    // Invesco India ETF
      'FRN',    // Invesco Frontier Markets ETF
      'INXX',   // Columbia India Infrastructure ETF
      'KBA',    // KraneShares Bosera MSCI China A ETF
      'MCHI',   // iShares MSCI China ETF
      'FTSE',   // Vanguard FTSE Pacific ETF
      'TLTE',   // FlexShares Morningstar Emerging Markets Factor Tilt Index Fund
    ],
    "emerging_markets_latin_america_africa": [
      'ILF',    // iShares Latin America 40 ETF
      'AFK',    // VanEck Vectors Africa Index ETF
      'EPU',    // iShares MSCI Peru ETF
      'EZA',    // iShares MSCI South Africa ETF
      'FLBR',   // Franklin FTSE Brazil ETF
      'GAF',    // SPDR S&P Emerging Middle East & Africa ETF
      'EGPT',   // VanEck Vectors Egypt Index ETF
      'ARGT',   // Global X MSCI Argentina ETF
      'GXG',    // Global X MSCI Colombia ETF
      'NGE',    // Global X MSCI Nigeria ETF
      'NORW',   // Global X MSCI Norway ETF
      'UMX',    // UBS ETRACS CMCI Energy Total Return ETN
      'LATM',   // SPDR S&P Latin America ETF
    ],
    "emerging_markets_small_cap": [
      'EWX',    // SPDR S&P Emerging Markets Small Cap ETF
      'DGS',    // WisdomTree Emerging Markets SmallCap Dividend Fund
      'EEMS',   // iShares MSCI Emerging Markets Small-Cap ETF
      'SMDD',   // ProShares UltraPro Short MidCap400
      'XSOE',   // WisdomTree Emerging Markets ex-State-Owned Enterprises Fund
      'EVAL',   // Invesco S&P Emerging Markets Low Volatility ETF
      'EEMS',   // iShares MSCI Emerging Markets Small-Cap ETF
      'TEMX',   // SPDR MSCI Emerging Markets Fossil Fuel Reserves Free ETF
    ],
  };
  
  export const LARGE_CAP_ETF_LISTS = {
    "large_cap_core": [
      'SPY',    // SPDR S&P 500 ETF Trust
      'VTI',    // Vanguard Total Stock Market ETF
      'SCHX',   // Schwab U.S. Large-Cap ETF
      'SCHB',   // Schwab U.S. Broad Market ETF
      'VONE',   // Vanguard Russell 1000 ETF
      'IWB',    // iShares Russell 1000 ETF
      'SPLG',   // SPDR Portfolio S&P 500 ETF
      'VV',     // Vanguard Large-Cap ETF
      'DIA',    // SPDR Dow Jones Industrial Average ETF Trust
      'ITOT',   // iShares Core S&P Total U.S. Stock Market ETF
      'IWL',    // iShares Russell Top 200 ETF
      'OEF',    // iShares S&P 100 ETF
      'IUS',    // Invesco Russell 1000 Equal Weight ETF
      'EQL',    // ALPS Equal Sector Weight ETF
      'PRF',    // Invesco FTSE RAFI US 1000 ETF
      'VOOG',   // Vanguard S&P 500 Growth ETF
    ],
    "large_cap_growth": [
      'VUG',    // Vanguard Growth ETF
      'IWF',    // iShares Russell 1000 Growth ETF
      'IVW',    // iShares S&P 500 Growth ETF
      'SPYG',   // SPDR Portfolio S&P 500 Growth ETF
      'SCHG',   // Schwab U.S. Large-Cap Growth ETF
      'IUSG',   // iShares Core S&P U.S. Growth ETF
      'VONG',   // Vanguard Russell 1000 Growth ETF
      'MGK',    // Vanguard Mega Cap Growth ETF
      'QQQJ',   // Invesco NASDAQ Next Gen 100 ETF
      'GSLC',   // Goldman Sachs ActiveBeta U.S. Large Cap Equity ETF
      'ONEQ',   // Fidelity Nasdaq Composite Index ETF
      'FTEC',   // Fidelity MSCI Information Technology Index ETF
    ],
    "large_cap_value": [
      'VTV',    // Vanguard Value ETF
      'IWD',    // iShares Russell 1000 Value ETF
      'SCHV',   // Schwab U.S. Large-Cap Value ETF
      'IUSV',   // iShares Core S&P U.S. Value ETF
      'RPV',    // Invesco S&P 500 Pure Value ETF
      'MGV',    // Vanguard Mega Cap Value ETF
      'SPYV',   // SPDR Portfolio S&P 500 Value ETF
      'VLUE',   // iShares MSCI USA Value Factor ETF
      'DHS',    // WisdomTree U.S. High Dividend Fund
      'VONV',   // Vanguard Russell 1000 Value ETF
      'IVE',    // iShares S&P 500 Value ETF
      'FVAL',   // Fidelity Value Factor ETF
    ],
    "large_cap_specialized": [
      'DVY',    // iShares Select Dividend ETF
      'RSP',    // Invesco S&P 500 Equal Weight ETF
      'FENY',   // Fidelity MSCI Energy Index ETF
      'USMV',   // iShares Edge MSCI Min Vol USA ETF
      'SPLV',   // Invesco S&P 500 Low Volatility ETF
      'QUAL',   // iShares MSCI USA Quality Factor ETF
      'NOBL',   // ProShares S&P 500 Dividend Aristocrats ETF
      'SDY',    // SPDR S&P Dividend ETF
      'DGRO',   // iShares Core Dividend Growth ETF
      'VIG',    // Vanguard Dividend Appreciation ETF
      'MOAT',   // VanEck Vectors Morningstar Wide Moat ETF
      'DGRW',   // WisdomTree U.S. Quality Dividend Growth Fund
      'FDL',    // First Trust Morningstar Dividend Leaders Index Fund
      'SPHD',   // Invesco S&P 500 High Dividend Low Volatility ETF
      'CDC',    // VictoryShares US EQ Income Enhanced Volatility Wtd ETF
    ],
    "large_cap_tech_focus": [
      'QQQ',    // Invesco QQQ Trust
      'VGT',    // Vanguard Information Technology ETF
      'TECL',   // Direxion Daily Technology Bull 3X Shares
      'XLK',    // Technology Select Sector SPDR Fund
      'IYW',    // iShares U.S. Technology ETF
      'IGV',    // iShares Expanded Tech-Software Sector ETF
      'FDN',    // First Trust Dow Jones Internet Index Fund
      'SKYY',   // First Trust Cloud Computing ETF
      'PNQI',   // Invesco NASDAQ Internet ETF
      'SOXX',   // iShares Semiconductor ETF
      'ARKK',   // ARK Innovation ETF
      'XNTK',   // SPDR NYSE Technology ETF
      'FDIG',   // Fidelity Crypto Industry and Digital Payments ETF
      'FTEC',   // Fidelity MSCI Information Technology Index ETF
    ],
  };
  
  export const MID_CAP_ETF_LISTS = {
    "mid_cap_core": [
      'IJH',    // iShares Core S&P Mid-Cap ETF
      'VO',     // Vanguard Mid-Cap ETF
      'IWR',    // iShares Russell Mid-Cap ETF
      'SCHM',   // Schwab U.S. Mid-Cap ETF
      'SPMD',   // SPDR Portfolio S&P 400 Mid Cap ETF
      'VXF',    // Vanguard Extended Market ETF
      'MDY',    // SPDR S&P MidCap 400 ETF Trust
      'IJJ',    // iShares S&P Mid-Cap 400 Value ETF
      'IJT',    // iShares S&P Mid-Cap 400 Growth ETF
      'XMLV',   // Invesco S&P MidCap Low Volatility ETF
      'IWP',    // iShares Russell Mid-Cap Growth ETF
    ],
    "mid_cap_growth": [
      'IWP',    // iShares Russell Mid-Cap Growth ETF
      'MDYG',   // SPDR S&P 400 Mid Cap Growth ETF
      'RFG',    // Invesco S&P MidCap 400 Pure Growth ETF
      'JKH',    // iShares Morningstar Mid-Cap Growth ETF
      'VOT',    // Vanguard Mid-Cap Growth ETF
      'IJK',    // iShares S&P Mid-Cap 400 Growth ETF
      'SCHG',   // Schwab U.S. Large-Cap Growth ETF
      'PWB',    // Invesco Dynamic Large Cap Growth ETF
      'FAD',    // First Trust Multi Cap Growth AlphaDEX Fund
    ],
    "mid_cap_value": [
      'IWS',    // iShares Russell Mid-Cap Value ETF
      'MDYV',   // SPDR S&P 400 Mid Cap Value ETF
      'RFV',    // Invesco S&P MidCap 400 Pure Value ETF
      'VOE',    // Vanguard Mid-Cap Value ETF
      'DON',    // WisdomTree U.S. MidCap Dividend Fund
      'JKI',    // iShares Morningstar Mid-Cap Value ETF
      'IJJ',    // iShares S&P Mid-Cap 400 Value ETF
      'SCHV',   // Schwab U.S. Large-Cap Value ETF
      'FNK',    // First Trust Mid Cap Value AlphaDEX Fund
      'IWS',    // iShares Russell Mid-Cap Value ETF
    ],
  };
  
  export const SMALL_CAP_ETF_LISTS = {
    "small_cap_core": [
      'IJR',    // iShares Core S&P Small-Cap ETF
      'VB',     // Vanguard Small-Cap ETF
      'SLY',    // SPDR S&P 600 Small Cap ETF
      'SCHA',   // Schwab U.S. Small-Cap ETF
      'SPSM',   // SPDR Portfolio S&P 600 Small Cap ETF
      'VTWO',   // Vanguard Russell 2000 ETF
      'IWC',    // iShares Micro-Cap ETF
      'URTY',   // ProShares UltraPro Russell2000
      'VIOO',   // Vanguard S&P Small-Cap 600 ETF
      'IWM',    // iShares Russell 2000 ETF
      'IJT',    // iShares S&P Small-Cap 600 Growth ETF
      'IJJ',    // iShares S&P Mid-Cap 400 Value ETF
    ],
    "small_cap_growth": [
      'IWO',    // iShares Russell 2000 Growth ETF
      'VBK',    // Vanguard Small-Cap Growth ETF
      'IJT',    // iShares S&P Small-Cap 600 Growth ETF
      'SLYG',   // SPDR S&P 600 Small Cap Growth ETF
      'FYC',    // First Trust Small Cap Growth AlphaDEX Fund
      'PZI',    // Invesco Zacks Micro Cap ETF
      'TWOK',   // SPDR Russell 2000 ETF
      'RWJ',    // Invesco S&P SmallCap 600 Revenue ETF
    ],
    "small_cap_value": [
      'IWN',    // iShares Russell 2000 Value ETF
      'VBR',    // Vanguard Small-Cap Value ETF
      'IJS',    // iShares S&P Small-Cap 600 Value ETF
      'SLYV',   // SPDR S&P 600 Small Cap Value ETF
      'DES',    // WisdomTree U.S. SmallCap Dividend Fund
      'XSLV',   // Invesco S&P SmallCap Low Volatility ETF
      'RFV',    // Invesco S&P MidCap 400 Pure Value ETF
      'DGRS',   // WisdomTree U.S. SmallCap Quality Dividend Growth Fund
      'VTWV',   // Vanguard Russell 2000 Value ETF
      'PXSV',   // Invesco Russell 2000 Pure Value ETF
    ]
  };

  export const SMALL_MID_CAP_ETF_LISTS = {
    "small_mid_cap_diversified": [
      'IWM',    // iShares Russell 2000 ETF
      'IJH',    // iShares Core S&P Mid-Cap ETF
      'VO',     // Vanguard Mid-Cap ETF
      'VB',     // Vanguard Small-Cap ETF
      'MDY',    // SPDR S&P MidCap 400 ETF Trust
      'SMDV',   // ProShares Russell 2000 Dividend Growers ETF
      'VXF',    // Vanguard Extended Market ETF
      'VBR',    // Vanguard Small-Cap Value ETF
      'IJS',    // iShares S&P Small-Cap 600 Value ETF
    ],
    "small_mid_cap_growth_focus": [
      'PSCT',   // Invesco S&P SmallCap Information Technology ETF
      'SBIO',   // ALPS Medical Breakthroughs ETF
      'XSD',    // SPDR S&P Semiconductor ETF
      'FYC',    // First Trust Small Cap Growth AlphaDEX Fund
      'IWO',    // iShares Russell 2000 Growth ETF
      'IJT',    // iShares S&P Small-Cap 600 Growth ETF
      'VOT',    // Vanguard Mid-Cap Growth ETF
      'VBK',    // Vanguard Small-Cap Growth ETF
      'IWP',    // iShares Russell Mid-Cap Growth ETF
      'VTWG',   // Vanguard Russell 2000 Growth ETF
    ],
    "small_mid_cap_value_focus": [
      'VBR',    // Vanguard Small-Cap Value ETF
      'RFV',    // Invesco S&P MidCap 400 Pure Value ETF
      'IJS',    // iShares S&P Small-Cap 600 Value ETF
      'VTWV',   // Vanguard Russell 2000 Value ETF
      'JKL',    // iShares Morningstar Small-Cap Value ETF
      'DFSV',   // Dimensional U.S. Small Cap Value ETF
      'PXSV',   // Invesco Russell 2000 Pure Value ETF
      'IWN',    // iShares Russell 2000 Value ETF
      'VIOV',   // Vanguard S&P Small-Cap 600 Value ETF
      'IVOV',   // Vanguard S&P Mid-Cap 400 Value ETF
    ],
  };
  
  // (Continue with the rest of the lists in the same manner, adding more quality ETFs where appropriate.)
  
  export const TECHNOLOGY_COMMUNICATION_ETF_LISTS = {
    "technology": [
      'XITK',  // SPDR FactSet Innovative Technology ETF
      'KOMP',  // SPDR S&P Kensho New Economies Composite ETF
      'IGM',   // iShares Expanded Tech Sector ETF
      'VGT',   // Vanguard Information Technology ETF
      'RYT',   // Invesco S&P 500 Equal Weight Technology ETF
      'IETC',  // iShares Evolved U.S. Technology ETF
      'MTK',   // SPDR Morgan Stanley Technology ETF
      'PTF',   // Invesco DWA Technology Momentum ETF
      'IGV',   // iShares Expanded Tech-Software Sector ETF
      'XNTK',  // SPDR NYSE Technology ETF
      'FTEC',  // Fidelity MSCI Information Technology Index ETF
    ],
    "semiconductors": [
      'SMH',   // VanEck Vectors Semiconductor ETF
      'XSD',   // SPDR S&P Semiconductor ETF
      'PSI',   // Invesco Dynamic Semiconductors ETF
      'SOXX',  // iShares Semiconductor ETF
      'SOXQ',  // Invesco PHLX Semiconductor ETF
      'USD',   // ProShares Ultra Semiconductors
      'SSG',   // ProShares UltraShort Semiconductors
    ],
    "communication_infrastructure": [
      'FCOM',  // Fidelity MSCI Communication Services Index ETF
      'XLC',   // Communication Services Select Sector SPDR Fund
      'IYZ',   // iShares U.S. Telecommunications ETF
      'VOX',   // Vanguard Communication Services ETF
      'FIVG',  // Defiance Next Gen Connectivity ETF
      'ISTB',  // iShares Core 1-5 Year USD Bond ETF (included for infrastructure funding exposure)
      'NBCT',  // Neuberger Berman Next Generation Connectivity Fund Inc.
      'NETL',  // NETLease Corporate Real Estate ETF
    ],
    "core_cybersecurity": [
      'HACK',  // ETFMG Prime Cyber Security ETF
      'CIBR',  // First Trust NASDAQ Cybersecurity ETF
      'BUG',   // Global X Cybersecurity ETF
      'IHAK',  // iShares Cybersecurity and Tech ETF
      'WCBR',  // WisdomTree Cybersecurity Fund
      'HAKK',  // ETFMG Cyber Security ESG ETF
    ],
    "network_security": [
      'SOCL',  // Global X Social Media ETF
      'SNSR',  // Global X Internet of Things ETF
      'IPAY',  // ETFMG Prime Mobile Payments ETF
      'FIVG',  // Defiance Next Gen Connectivity ETF
      'ARKW',  // ARK Next Generation Internet ETF
      'VPN',   // Global X Data Center REITs & Digital Infrastructure ETF
      'META',  // Roundhill Ball Metaverse ETF
      'XWEB',  // SPDR S&P Internet ETF
    ],
  };
  
  export const HEALTHCARE_FINANCIAL_ETF_LISTS = {
    "healthcare_broad": [
      'XLV',   // Health Care Select Sector SPDR Fund
      'VHT',   // Vanguard Health Care ETF
      'IYH',   // iShares U.S. Healthcare ETF
      'FHLC',  // Fidelity MSCI Health Care Index ETF
      'RYH',   // Invesco S&P 500 Equal Weight Health Care ETF
      'IXJ',   // iShares Global Healthcare ETF
      'IXV',   // iShares S&P 500 Health Care Sector ETF
      'PTH',   // Invesco DWA Healthcare Momentum ETF
      'XLVS',  // ProShares Ultra Health Care ETF
      'IHI',   // iShares U.S. Medical Devices ETF (moved to medical devices)
    ],
    "biotechnology": [
      'IBB',   // iShares Nasdaq Biotechnology ETF
      'XBI',   // SPDR S&P Biotech ETF
      'BBH',   // VanEck Vectors Biotech ETF
      'ARKG',  // ARK Genomic Revolution ETF
      'PBE',   // Invesco Dynamic Biotechnology & Genome ETF
      'GNOM',  // Global X Genomics & Biotechnology ETF
      'BBC',   // Virtus LifeSci Biotech Clinical Trials ETF
      'BBP',   // Virtus LifeSci Biotech Products ETF
      'SBIO',  // ALPS Medical Breakthroughs ETF
    ],
    "medical_devices": [
      'IHI',   // iShares U.S. Medical Devices ETF
      'XHE',   // SPDR S&P Health Care Equipment ETF
      'PSCH',  // Invesco S&P SmallCap Health Care ETF
      'XHS',   // SPDR S&P Health Care Services ETF
      'HTEC',  // ROBO Global Healthcare Technology and Innovation ETF
      'IEIH',  // iShares Evolved U.S. Innovative Healthcare ETF
      'HEAL',  // iShares U.S. Healthcare Providers ETF
      'DRIV',  // Global X Autonomous & Electric Vehicles ETF (moved to automotive)
    ],
    "financial_services": [
      'XLF',   // Financial Select Sector SPDR Fund
      'VFH',   // Vanguard Financials ETF
      'FNCL',  // Fidelity MSCI Financials Index ETF
      'IYG',   // iShares U.S. Financial Services ETF
      'KCE',   // SPDR S&P Capital Markets ETF
      'RYF',   // Invesco S&P 500 Equal Weight Financials ETF
      'KIE',   // SPDR S&P Insurance ETF
      'IYF',   // iShares U.S. Financials ETF
      'KBE',   // SPDR S&P Bank ETF
      'KRE',   // SPDR S&P Regional Banking ETF
      'IAT',   // iShares U.S. Regional Banks ETF
      'IAI',   // iShares U.S. Broker-Dealers & Securities Exchanges ETF
      'FXO',   // First Trust Financials AlphaDEX Fund
      'BIZD',  // VanEck Vectors BDC Income ETF
      'KBWB',  // Invesco KBW Bank ETF
    ],
  };
  
  export const CONSUMER_INDUSTRIAL_ENERGY_ETF_LISTS = {
    "consumer_discretionary": [
      'XLY',   // Consumer Discretionary Select Sector SPDR Fund
      'VCR',   // Vanguard Consumer Discretionary ETF
      'IYC',   // iShares U.S. Consumer Discretionary ETF
      'FDIS',  // Fidelity MSCI Consumer Discretionary Index ETF
      'RCD',   // Invesco S&P 500 Equal Weight Consumer Discretionary ETF
      'FXD',   // First Trust Consumer Discretionary AlphaDEX Fund
      'XRT',   // SPDR S&P Retail ETF
      'RTH',   // VanEck Vectors Retail ETF
      'BITE',  // Compass EMP U.S. Discovery 500 Enhanced Volatility Weighted Fund
    ],
    "consumer_staples": [
      'XLP',   // Consumer Staples Select Sector SPDR Fund
      'VDC',   // Vanguard Consumer Staples ETF
      'FSTA',  // Fidelity MSCI Consumer Staples Index ETF
      'RHS',   // Invesco S&P 500 Equal Weight Consumer Staples ETF
      'FXG',   // First Trust Consumer Staples AlphaDEX Fund
      'IYK',   // iShares U.S. Consumer Goods ETF
      'PBJ',   // Invesco Dynamic Food & Beverage ETF
      'KXI',   // iShares Global Consumer Staples ETF
    ],
    "renewable_energy": [
      'ICLN',  // iShares Global Clean Energy ETF
      'TAN',   // Invesco Solar ETF
      'QCLN',  // First Trust NASDAQ Clean Edge Green Energy Index Fund
      'ACES',  // ALPS Clean Energy ETF
      'GRID',  // First Trust NASDAQ Clean Edge Smart Grid Infrastructure Index Fund
      'SMOG',  // VanEck Vectors Low Carbon Energy ETF
      'FAN',   // First Trust Global Wind Energy ETF
      'PBW',   // Invesco WilderHill Clean Energy ETF
      'CNRG',  // SPDR S&P Kensho Clean Power ETF
      'BATT',  // Amplify Lithium & Battery Technology ETF
    ],
    "traditional_energy": [
      'XLE',   // Energy Select Sector SPDR Fund
      'VDE',   // Vanguard Energy ETF
      'IYE',   // iShares U.S. Energy ETF
      'PXI',   // Invesco DWA Energy Momentum ETF
      'RYE',   // Invesco S&P 500 Equal Weight Energy ETF
      'OIH',   // VanEck Vectors Oil Services ETF
      'XOP',   // SPDR S&P Oil & Gas Exploration & Production ETF
      'IEO',   // iShares U.S. Oil & Gas Exploration & Production ETF
      'PSCE',  // Invesco S&P SmallCap Energy ETF
      'AMLP',  // Alerian MLP ETF
    ],
    "commercial_aerospace": [
      'ITA',   // iShares U.S. Aerospace & Defense ETF
      'PPA',   // Invesco Aerospace & Defense ETF
      'XAR',   // SPDR S&P Aerospace & Defense ETF
      'DFEN',  // Direxion Daily Aerospace & Defense Bull 3X Shares
      'MISL',  // SPDR S&P Kensho Final Frontiers ETF
      'FLYA',  // Direxion Flight to Safety Strategy ETF
    ],
    "infrastructure": [
      'PAVE',  // Global X U.S. Infrastructure Development ETF
      'IFRA',  // iShares U.S. Infrastructure ETF
      'IGF',   // iShares Global Infrastructure ETF
      'NFRA',  // FlexShares STOXX Global Broad Infrastructure Index Fund
      'TOLZ',  // ProShares DJ Brookfield Global Infrastructure ETF
      'GHII',  // Invesco S&P High Income Infrastructure ETF
      'EMIF',  // iShares Emerging Markets Infrastructure ETF
      'MLPX',  // Global X MLP & Energy Infrastructure ETF
      'UTF',   // Cohen & Steers Infrastructure Fund, Inc.
    ],
    "utilities": [
      'VPU',   // Vanguard Utilities ETF
      'XLU',   // Utilities Select Sector SPDR Fund
      'IDU',   // iShares U.S. Utilities ETF
      'FUTY',  // Fidelity MSCI Utilities Index ETF
      'FXU',   // First Trust Utilities AlphaDEX Fund
      'UTES',  // Virtus Reaves Utilities ETF
      'RYU',   // Invesco S&P 500 Equal Weight Utilities ETF
      'XLU',   // Utilities Select Sector SPDR Fund
    ],
    "automotive": [
      'CARZ',  // First Trust NASDAQ Global Auto Index Fund
      'KARS',  // KraneShares Electric Vehicles and Future Mobility Index ETF
      'IDRV',  // iShares Self-Driving EV and Tech ETF
      'MOTO',  // SmartETFs Smart Transportation & Technology ETF
      'EKAR',  // Capital Link NextGen Vehicles & Technology ETF
      'HAIL',  // SPDR S&P Kensho Smart Mobility ETF
      'FDRV',  // Fidelity Electric Vehicles and Future Transportation ETF
      'VROM',  // T. Rowe Price Blue Chip Growth ETF
    ],
  };
  
  export const MOMENTUM_BASED_ETF_LISTS = {
    "bull_market_leveraged": [
      'UPRO',  // ProShares UltraPro S&P500
      'UMDD',  // ProShares UltraPro MidCap400
      'TQQQ',  // ProShares UltraPro QQQ
      'SPXL',  // Direxion Daily S&P 500 Bull 3X Shares
      'UDOW',  // ProShares UltraPro Dow30
      'URTY',  // ProShares UltraPro Russell2000
      'FNGU',  // MicroSectors FANG+ Index 3X Leveraged ETN
    ],
    "sector_specific_leveraged": [
      'LABU',  // Direxion Daily S&P Biotech Bull 3X Shares
      'DRN',   // Direxion Daily Real Estate Bull 3X Shares
      'FAS',   // Direxion Daily Financial Bull 3X Shares
      'DFEN',  // Direxion Daily Aerospace & Defense Bull 3X Shares
      'CURE',  // Direxion Daily Healthcare Bull 3X Shares
      'NUGT',  // Direxion Daily Gold Miners Index Bull 2X Shares
      'SOXL',  // Direxion Daily Semiconductor Bull 3X Shares
      'TECL',  // Direxion Daily Technology Bull 3X Shares
    ],
    "broad_market_inverse": [
      'SPXU',  // ProShares UltraPro Short S&P500
      'SQQQ',  // ProShares UltraPro Short QQQ
      'TZA',   // Direxion Daily Small Cap Bear 3X Shares
      'SDOW',  // ProShares UltraPro Short Dow30
      'SH',    // ProShares Short S&P500
      'PSQ',   // ProShares Short QQQ
      'DOG',   // ProShares Short Dow30
    ],
    "sector_specific_inverse": [
      'LABD',  // Direxion Daily S&P Biotech Bear 3X Shares
      'FAZ',   // Direxion Daily Financial Bear 3X Shares
      'DUST',  // Direxion Daily Gold Miners Index Bear 2X Shares
      'ERY',   // Direxion Daily Energy Bear 3X Shares
      'SOXS',  // Direxion Daily Semiconductor Bear 3X Shares
      'TECS',  // Direxion Daily Technology Bear 3X Shares
      'WEBS',  // Direxion Daily Dow Jones Internet Bear 3X Shares
    ],
  };

  export const FUNDAMENTALS_BASED_ETF_LISTS = {
    "esg_focus": [
      'ESGU',  // iShares ESG Aware MSCI USA ETF
      'SUSA',  // iShares MSCI USA ESG Select ETF
      'DSI',   // iShares MSCI KLD 400 Social ETF
      'SPYX',  // SPDR S&P 500 Fossil Fuel Reserves Free ETF
      'JUST',  // Goldman Sachs JUST U.S. Large Cap Equity ETF
      'CRBN',  // iShares MSCI ACWI Low Carbon Target ETF
      'NULV',  // Nuveen ESG Large-Cap Value ETF
      'EFAX',  // SPDR MSCI EAFE Fossil Fuel Reserves Free ETF
      'EEMX',  // SPDR MSCI Emerging Markets Fossil Fuel Reserves Free ETF
    ],
    "growth": [
      'MGK',   // Vanguard Mega Cap Growth ETF
      'IUSG',  // iShares Core S&P U.S. Growth ETF
      'IVW',   // iShares S&P 500 Growth ETF
      'VONG',  // Vanguard Russell 1000 Growth ETF
      'SCHG',  // Schwab U.S. Large-Cap Growth ETF
      'MTUM',  // iShares Edge MSCI USA Momentum Factor ETF
      'IWY',   // iShares Russell Top 200 Growth ETF
      'SPYG',  // SPDR Portfolio S&P 500 Growth ETF
      'VUG',   // Vanguard Growth ETF
    ],
    "quality": [
      'QUAL',  // iShares MSCI USA Quality Factor ETF
      'SPHQ',  // Invesco S&P 500 Quality ETF
      'DGRW',  // WisdomTree U.S. Quality Dividend Growth Fund
      'QDF',   // FlexShares Quality Dividend Index Fund
      'OUSA',  // O’Shares U.S. Quality Dividend ETF
      'COWZ',  // Pacer US Cash Cows 100 ETF
      'SPHD',  // Invesco S&P 500 High Dividend Low Volatility ETF
      'EUSA',  // iShares MSCI USA Equal Weighted ETF
    ],
    "value_vs_growth": [
      'VLUE',  // iShares MSCI USA Value Factor ETF
      'IVE',   // iShares S&P 500 Value ETF
      'VONV',  // Vanguard Russell 1000 Value ETF
      'SCHV',  // Schwab U.S. Large-Cap Value ETF
      'VTV',   // Vanguard Value ETF
      'IWD',   // iShares Russell 1000 Value ETF
      'DVP',   // Deep Value ETF
      'FVAL',  // Fidelity Value Factor ETF
    ],
  };
  
  export const FIXED_INCOME_ISSUER_TYPE_ETF_LISTS = {
    "government_bonds": [
      'GOVT',  // iShares U.S. Treasury Bond ETF
      'TLT',   // iShares 20+ Year Treasury Bond ETF
      'SHY',   // iShares 1-3 Year Treasury Bond ETF
      'IEF',   // iShares 7-10 Year Treasury Bond ETF
      'VGIT',  // Vanguard Intermediate-Term Treasury ETF
      'SCHO',  // Schwab Short-Term U.S. Treasury ETF
      'TLH',   // iShares 10-20 Year Treasury Bond ETF
      'EDV',   // Vanguard Extended Duration Treasury ETF
      'SHV',   // iShares Short Treasury Bond ETF
      'SCHR',  // Schwab Intermediate-Term U.S. Treasury ETF
      'GOVT',  // iShares U.S. Treasury Bond ETF
    ],
    "corporate_bonds": [
      'LQD',   // iShares iBoxx $ Investment Grade Corporate Bond ETF
      'VCLT',  // Vanguard Long-Term Corporate Bond ETF
      'VCIT',  // Vanguard Intermediate-Term Corporate Bond ETF
      'HYG',   // iShares iBoxx $ High Yield Corporate Bond ETF
      'JNK',   // SPDR Bloomberg Barclays High Yield Bond ETF
      'IGIB',  // iShares Intermediate-Term Corporate Bond ETF
      'ANGL',  // VanEck Vectors Fallen Angel High Yield Bond ETF
      'USIG',  // iShares Broad USD Investment Grade Corporate Bond ETF
      'LQDH',  // iShares Interest Rate Hedged Corporate Bond ETF
      'SPIB',  // SPDR Portfolio Intermediate Term Corporate Bond ETF
    ],
    "municipal_bonds": [
      'MUB',   // iShares National Muni Bond ETF
      'TFI',   // SPDR Nuveen Bloomberg Barclays Municipal Bond ETF
      'PZA',   // Invesco National AMT-Free Municipal Bond ETF
      'HYD',   // VanEck Vectors High-Yield Municipal Index ETF
      'VTEB',  // Vanguard Tax-Exempt Bond ETF
      'SHM',   // SPDR Nuveen Bloomberg Barclays Short Term Municipal Bond ETF
      'ITM',   // VanEck Vectors AMT-Free Intermediate Municipal Index ETF
      'SUB',   // iShares Short-Term National Muni Bond ETF
    ],
    "international_bonds": [
      'BNDX',  // Vanguard Total International Bond ETF
      'IGOV',  // iShares International Treasury Bond ETF
      'EMB',   // iShares J.P. Morgan USD Emerging Markets Bond ETF
      'VWOB',  // Vanguard Emerging Markets Government Bond ETF
      'PCY',   // Invesco Emerging Markets Sovereign Debt ETF
      'IAGG',  // iShares Core International Aggregate Bond ETF
      'EMLC',  // VanEck Vectors J.P. Morgan EM Local Currency Bond ETF
      'BWX',   // SPDR Bloomberg Barclays International Treasury Bond ETF
      'IBND',  // SPDR Bloomberg Barclays International Corporate Bond ETF
      'IGLB',  // iShares Long-Term Corporate Bond ETF
    ],
  };
  
  export const FIXED_INCOME_MATURITY_ETF_LISTS = {
    "short_term_bonds": [
      'SHY',    // iShares 1-3 Year Treasury Bond ETF
      'BSV',    // Vanguard Short-Term Bond ETF
      'MINT',   // PIMCO Enhanced Short Maturity Active Exchange-Traded Fund
      'JPST',   // JPMorgan Ultra-Short Income ETF
      'ICSH',   // BlackRock Ultra Short-Term Bond ETF
      'BIL',    // SPDR Bloomberg Barclays 1-3 Month T-Bill ETF
      'VGSH',   // Vanguard Short-Term Treasury ETF
      'SPSB',   // SPDR Portfolio Short Term Corporate Bond ETF
      'VCSH',   // Vanguard Short-Term Corporate Bond ETF
      'SHV',    // iShares Short Treasury Bond ETF
    ],
    "intermediate_term_bonds": [
      'IEF',    // iShares 7-10 Year Treasury Bond ETF
      'SCHR',   // Schwab Intermediate-Term U.S. Treasury ETF
      'IGIB',   // iShares Intermediate-Term Corporate Bond ETF
      'VCIT',   // Vanguard Intermediate-Term Corporate Bond ETF
      'BIV',    // Vanguard Intermediate-Term Bond ETF
      'IUSB',   // iShares Core Total USD Bond Market ETF
      'GOVT',   // iShares U.S. Treasury Bond ETF
      'GVI',    // iShares Intermediate Government/Credit Bond ETF
      'AGG',    // iShares Core U.S. Aggregate Bond ETF
      'BND',    // Vanguard Total Bond Market ETF
    ],
    "long_term_bonds": [
      'TLT',    // iShares 20+ Year Treasury Bond ETF
      'BLV',    // Vanguard Long-Term Bond ETF
      'VCLT',   // Vanguard Long-Term Corporate Bond ETF
      'ZROZ',   // PIMCO 25+ Year Zero Coupon U.S. Treasury Index ETF
      'EDV',    // Vanguard Extended Duration Treasury ETF
      'LTPZ',   // PIMCO 15+ Year U.S. TIPS Index Exchange-Traded Fund
      'VGLT',   // Vanguard Long-Term Treasury ETF
      'SPLB',   // SPDR Portfolio Long Term Corporate Bond ETF
      'TLH',    // iShares 10-20 Year Treasury Bond ETF
      'GLJ',    // iPath Series B Bloomberg Commodity Index Total Return ETN (Note: This is a commodity ETN, remove if necessary)
    ],
  };
  
  export const REAL_ESTATE_BASED_ETF_LISTS = {
    "domestic_real_estate": [
      'VNQ',    // Vanguard Real Estate ETF
      'IYR',    // iShares U.S. Real Estate ETF
      'XLRE',   // Real Estate Select Sector SPDR Fund
      'USRT',   // iShares Core U.S. REIT ETF
      'FREL',   // Fidelity MSCI Real Estate Index ETF
      'RWR',    // SPDR Dow Jones REIT ETF
      'SCHH',   // Schwab U.S. REIT ETF
      'REM',    // iShares Mortgage Real Estate ETF
      'MORT',   // VanEck Vectors Mortgage REIT Income ETF
      'PSR',    // Invesco Active U.S. Real Estate ETF
    ],
    "international_real_estate": [
      'VNQI',   // Vanguard Global ex-U.S. Real Estate ETF
      'RWX',    // SPDR Dow Jones International Real Estate ETF
      'IFGL',   // iShares International Developed Real Estate ETF
      'HAUZ',   // Xtrackers International Real Estate ETF
      'WPS',    // iShares International Developed Property ETF
      'IPAC',   // iShares Core MSCI Pacific ETF
    ],
    "diversified_global_real_estate": [
      'REET',   // iShares Global REIT ETF
      'RWO',    // SPDR Dow Jones Global Real Estate ETF
      'DRW',    // WisdomTree Global ex-U.S. Real Estate Fund
      'GRI',    // Cohen & Steers Global Realty Majors ETF
      'RWJ',    // Invesco S&P SmallCap 600 Revenue ETF (Note: Should be in small-cap core, remove if necessary)
      'TAO',    // Invesco China Real Estate ETF
    ],
  };
  
  export const REAL_ESTATE_PROPERTY_TYPE_ETF_LISTS = {
    "residential_real_estate": [
      'REZ',    // iShares Residential and Multisector Real Estate ETF
      'HOMZ',   // Hoya Capital Housing ETF
      'ROOF',   // IQ U.S. Real Estate Small Cap ETF
      'RESI',   // Virtus Real Asset Income ETF
    ],
    "commercial_real_estate": [
      'ICF',    // iShares Cohen & Steers REIT ETF
      'SCHH',   // Schwab U.S. REIT ETF
      'PSR',    // Invesco Active U.S. Real Estate ETF
      'XLRE',   // Real Estate Select Sector SPDR Fund
      'RWR',    // SPDR Dow Jones REIT ETF
      'VNQ',    // Vanguard Real Estate ETF
    ],
    "industrial_real_estate": [
      'INDS',   // Pacer Benchmark Industrial Real Estate SCTR ETF
      'WOOD',   // iShares Global Timber & Forestry ETF
      'CUT',    // Invesco MSCI Global Timber ETF
      'PAVE',   // Global X U.S. Infrastructure Development ETF
      'IFRA',   // iShares U.S. Infrastructure ETF
      'NURE',   // Nuveen Short-Term REIT ETF
    ],
  };
  
  export const COMMODITIES_TYPE_ETF_LISTS = {
    "metals": [
      'GLD',    // SPDR Gold Shares
      'SLV',    // iShares Silver Trust
      'PPLT',   // Aberdeen Standard Physical Platinum Shares ETF
      'IAU',    // iShares Gold Trust
      'PALL',   // Aberdeen Standard Physical Palladium Shares ETF
      'SIVR',   // Aberdeen Standard Physical Silver Shares ETF
      'SGOL',   // Aberdeen Standard Physical Gold Shares ETF
      'OUNZ',   // VanEck Merk Gold Trust
      'GLDM',   // SPDR Gold MiniShares Trust
      'BAR',    // GraniteShares Gold Trust
    ],
    "energy": [
      'USO',    // United States Oil Fund LP
      'UNG',    // United States Natural Gas Fund LP
      'DBE',    // Invesco DB Energy Fund
      'UCO',    // ProShares Ultra Bloomberg Crude Oil
      'BOIL',   // ProShares Ultra Bloomberg Natural Gas
      'BNO',    // United States Brent Oil Fund LP
      'UGA',    // United States Gasoline Fund LP
      'KOLD',   // ProShares UltraShort Bloomberg Natural Gas
      'DRIP',   // Direxion Daily S&P Oil & Gas Exp. & Prod. Bear 2X Shares
      'OILK',   // ProShares K-1 Free Crude Oil Strategy ETF
    ],
    "agriculture": [
      'DBA',    // Invesco DB Agriculture Fund
      'CORN',   // Teucrium Corn Fund
      'WEAT',   // Teucrium Wheat Fund
      'SOYB',   // Teucrium Soybean Fund
      'CANE',   // Teucrium Sugar Fund
      'NIB',    // iPath Bloomberg Cocoa Subindex Total Return ETN
      'JO',     // iPath Series B Bloomberg Coffee Subindex Total Return ETN
      'RJA',    // Elements Rogers International Commodity Index-Agriculture Total Return ETN
      'COW',    // iPath Series B Bloomberg Livestock Subindex Total Return ETN
      'TAGS',   // Teucrium Agricultural Fund
    ],
  };
  
  export const COMMODITIES_INVESTMENT_METHOD_ETF_LISTS = {
    "physical": [
      'GLD',    // SPDR Gold Shares
      'SLV',    // iShares Silver Trust
      'PPLT',   // Aberdeen Standard Physical Platinum Shares ETF
      'IAU',    // iShares Gold Trust
      'SGOL',   // Aberdeen Standard Physical Gold Shares ETF
      'SIVR',   // Aberdeen Standard Physical Silver Shares ETF
      'PALL',   // Aberdeen Standard Physical Palladium Shares ETF
      'BAR',    // GraniteShares Gold Trust
      'OUNZ',   // VanEck Merk Gold Trust
      'GLDM',   // SPDR Gold MiniShares Trust
    ],
    "futures": [
      'USO',    // United States Oil Fund LP
      'UNG',    // United States Natural Gas Fund LP
      'DBA',    // Invesco DB Agriculture Fund
      'CORN',   // Teucrium Corn Fund
      'WEAT',   // Teucrium Wheat Fund
      'UCO',    // ProShares Ultra Bloomberg Crude Oil
      'BOIL',   // ProShares Ultra Bloomberg Natural Gas
      'KOLD',   // ProShares UltraShort Bloomberg Natural Gas
      'USL',    // United States 12 Month Oil Fund LP
      'UCI',    // ETRACS UBS Bloomberg export constant Maturity Commodity Index ETN
    ],
  };
    