import React from 'react';
import { Box, Typography } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ display: 'flex', paddingTop: '50px', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
          }}
        >
          Your privacy is important to us. Here’s how we protect and manage your data.
        </Typography>

        {/* Introduction Section */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            1. Introduction
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            AlgoInvestor Fund ("AlgoInvest," "we," "our," or "us") is committed to protecting your privacy and safeguarding your personal information. This Privacy Policy describes how we collect, use, disclose, and protect the personal information of our clients and website visitors.
          </Typography>
        </Box>

        {/* Information We Collect Section */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            2. Information We Collect
          </Typography>

          {/* Personal Information */}
          <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: '#478778', marginBottom: 1 }}>
            2.1. Personal Information
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            We collect personal information that you provide directly to us, including:
          </Typography>
          <ul style={{ marginLeft: '1.5rem', color: '#333' }}>
            <li>Full name</li>
            <li>Date of birth</li>
            <li>Social Security number or Tax Identification Number</li>
            <li>Contact information (email address, phone number, mailing address)</li>
            <li>Financial information (income, net worth, investment goals)</li>
            <li>Employment information</li>
            <li>Bank/investment account information for funding your investment account</li>
          </ul>

          {/* Automatically Collected Information */}
          <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: '#478778', marginTop: 3, marginBottom: 1 }}>
            2.2. Automatically Collected Information
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            We may automatically collect certain information about your device and how you interact with our Services, including:
          </Typography>
          <ul style={{ marginLeft: '1.5rem', color: '#333' }}>
            <li>IP address</li>
            <li>Device type and operating system</li>
            <li>Browser type and version</li>
            <li>Usage data (pages visited, time spent on site, etc.)</li>
            <li>Location data</li>
          </ul>

          {/* Information from Third Parties */}
          <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: '#478778', marginTop: 3, marginBottom: 1 }}>
            2.3. Information from Third Parties
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            We may receive information about you from third parties, such as credit reporting agencies, identity verification services, and other financial institutions.
          </Typography>
        </Box>

        {/* How We Use Your Information Section */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            3. How We Use Your Information
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            We use your personal information for the following purposes:
          </Typography>
          <ul style={{ marginLeft: '1.5rem', color: '#333' }}>
            <li>To provide and maintain our Services</li>
            <li>To create and manage your investment account</li>
            <li>To comply with legal and regulatory requirements</li>
            <li>To communicate with you about your account and our Services</li>
            <li>To improve and personalize our Services</li>
            <li>To detect and prevent fraud and other unauthorized activities</li>
            <li>For internal research and analysis</li>
          </ul>
        </Box>

        {/* Information Sharing and Disclosure Section */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            4. Information Sharing and Disclosure
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            We may share your personal information in the following circumstances:
          </Typography>
          <ul style={{ marginLeft: '1.5rem', color: '#333' }}>
            <li>With service providers and partners who assist us in providing our Services</li>
            <li>With regulatory authorities, law enforcement agencies, or other third parties when required by law or to protect our rights</li>
            <li>In connection with a merger, acquisition, or sale of all or a portion of our assets</li>
            <li>With your consent or at your direction</li>
          </ul>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginTop: 2 }}>
            We do not sell your personal information to third parties.
          </Typography>
        </Box>

        {/* Data Security Section */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            5. Data Security
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </Typography>
        </Box>

        {/* Your Rights and Choices */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            6. Your Rights and Choices
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Depending on your location, you may have certain rights regarding your personal information, including:
          </Typography>
          <ul style={{ marginLeft: '1.5rem', color: '#333' }}>
            <li>The right to access your personal information</li>
            <li>The right to correct inaccurate or incomplete information</li>
            <li>The right to request deletion of your personal information</li>
            <li>The right to restrict or object to processing of your personal information</li>
            <li>The right to data portability</li>
          </ul>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginTop: 2 }}>
            To exercise these rights, please contact us using the information provided in the "Contact Us" section.
          </Typography>
        </Box>

        {/* Retention of Personal Information */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D',
          marginBottom: 2 }}>
          7. Retention of Personal Information
        </Typography>
        <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
          We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </Typography>
      </Box>

      {/* Children's Privacy */}
      <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
        <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
          8. Children's Privacy
        </Typography>
        <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
          Our Services are not directed to children under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us.
        </Typography>
      </Box>

      {/* Changes to This Privacy Policy */}
      <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
        <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
          9. Changes to This Privacy Policy
        </Typography>
        <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this Privacy Policy.
        </Typography>
      </Box>

      {/* California Privacy Rights */}
      <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
        <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
          10. California Privacy Rights
        </Typography>
        <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
          If you are a California resident, you have certain rights under the California Consumer Privacy Act (CCPA). For more information about these rights and how to exercise them, please see our{' '}
          <a href="#" style={{ color: '#478778', textDecoration: 'underline' }}>
            California Privacy Notice
          </a>.
        </Typography>
      </Box>

      {/* Contact Us Section */}
      <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
        <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
          11. Contact Us
        </Typography>
        <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
          If you have any questions about this Privacy Policy or our data practices, please contact us at:
        </Typography>
        <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginTop: 2 }}>
          AlgoInvestor Fund<br />
          Email: <a href="mailto:support@algoinvest.io" style={{ color: '#478778', textDecoration: 'underline' }}>support@algoinvest.io</a><br />
          Phone: (973) 567-2240
        </Typography>
      </Box>

      {/* Agreement Statement */}
      <Box
        sx={{
          padding: '2rem',
          backgroundColor: '#fefefe',
          textAlign: 'center',
          borderRadius: 'md',
          marginTop: 4,
        }}
      >
        <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
          By using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your information as described herein.
        </Typography>
      </Box>
    </Box>
  </Box>
);
};

export default PrivacyPolicy;