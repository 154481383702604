
import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);

  const faqs = [
    {
      question: 'What is AlgoInvest\'s investment philosophy?',
      answer:
        'AlgoInvest leverages advanced statistical models, machine learning, and AI to systematically capture both beta and alpha risk premia across major asset classes. By integrating academic innovation with industry expertise, we aim to deliver sophisticated, transparent, and high-performing investment solutions that provide consistent and superior returns.',
    },
    {
      question: 'How does AlgoInvest utilize AI and machine learning in its investment strategies?',
      answer:
        'We employ AI and machine learning techniques to analyze vast amounts of financial data, including price movements, financial statements, and real-time news processed through Large Language Models (LLMs). This enables us to identify patterns, predict market trends, and generate diversified alpha signals to enhance returns.',
    },
    {
      question: 'What are the Core Fund and the AI Fund, and how do they differ?',
      answer:
        'The Core Fund is our passive investment product designed to capture beta risk premia across major asset classes using risk parity methods and momentum-based price factors. The AI Fund is our active product that incorporates a diverse array of alpha-generating factors, including signals from financial metrics and real-time news data processed through advanced LLMs, to dynamically adjust the portfolio and capture high-value opportunities.',
    },
    {
      question: 'How does AlgoInvest construct its portfolios and manage risk?',
      answer:
        'We use a hierarchical asset structure, organizing assets into clusters based on themes such as regions, sectors, and factors. We employ progressive risk parity refinement, starting from equal weighting and moving towards sophisticated risk models that account for asset variances, covariances, and factor exposures. By balancing risk contributions and integrating alpha signals hierarchically, we ensure diversification and effective risk management at every portfolio level.',
    },
    {
      question: 'How does AlgoInvest capture both beta and alpha risk premia?',
      answer:
        'We capture beta risk premia by systematically balancing exposures across major asset classes using risk parity methods. Alpha risk premia are captured through the integration of diversified alpha signals derived from multiple sources like price momentum, fundamental metrics, and sentiment analysis using LLMs. Combining these approaches within a hierarchical framework enhances returns while effectively managing risk.',
    },
    {
      question: 'What kind of returns can I expect from investing with AlgoInvest?',
      answer:
        'AlgoInvest aims to deliver the highest and most stable returns of any widely available investment product. Our Core Fund expects 6-9% return with a 0.5-1.0 Sharpe whereas our AI Fund expects 9-12% return with a 1.0-1.5 Sharpe. In contrast, you might expect -20-30% returns and a Sharpe of 0.0-0.5 from the S&P 500 or other passive funds.',
    },
    {
      question: 'What differentiates AlgoInvest from other investment firms?',
      answer:
        'Our integration of cutting-edge technology, academic rigor, and industry expertise sets us apart. By using advanced statistical models, machine learning, and AI—particularly the incorporation of LLMs—we dynamically adjust to market changes in real-time. Our hierarchical portfolio construction and transparent investment process provide robustness and adaptability, offering an edge over more static or less data-driven approaches.',
    },
    {
      question: 'What are the benefits of using AlgoInvest\'s Custom Tooling Suite?',
      answer:
        'Our Custom Tooling Suite gives clients access to powerful investment tools that replicate key components of our advanced system. These include modules for asset clustering, risk modeling, portfolio optimization, backtesting, and performance attribution. This empowers clients to leverage sophisticated analytics, customize their investment strategies, and gain greater transparency and control over their portfolios.',
    },
    {
      question: 'What is AlgoInvest\'s future expansion plan?',
      answer:
        'We plan a progressive expansion while maintaining transparency and excellence. Starting with proprietary trading to test and refine our strategies, we will extend services to a trusted network of family and friends. Eventually, we aim to open our platform to the public, democratizing access to sophisticated investment products, and target institutional clients with scalable and customized solutions to solidify our position as a leading institutional investor.',
    },
  ];
  



  

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f9f9f9', 
        py: { xs: 4, md: 8 },
        px: { xs: 2, md: 6 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box sx={{ textAlign: 'left', maxWidth: '25%' }}>
        <Typography level="h4" sx={{ fontSize: '3.5rem', fontWeight: '700', color: '#2f3b52', mb: 1 }}>
          Know More
        </Typography>
        <Typography level="h4" sx={{ fontSize: '3.5rem', fontWeight: '700', color: '#2f3b52', mb: 2 }}>
          Invest Better
        </Typography>
        <Typography level="subtitle1" sx={{ fontSize: '2rem', color: '#5a6b7d', mb: 4 }}>
          Find quick solutions and expert advice to our most frequently asked questions.
        </Typography>
      </Box>
      <Box sx={{ flex: 1, ml: { md: 4 } }}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={() => setExpanded(expanded === index ? false : index)}
            sx={{
              backgroundColor: '#ffffff', // Use a subtle green or white shade
              borderRadius: '30px',
              padding: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              mb: 2,
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{ px: 2, py: 1 }}
            >
              <Typography sx={{ fontSize: '1.8rem', color: '#2f3b52' }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 2, py: 1 }}>
              <Typography sx={{ fontSize: '1.5rem', color: '#5a6b7d' }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FAQ;
