import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button, Tabs, TabList, Tab, TabPanel } from '@mui/joy';
import { FaUser, FaLock, FaUniversity } from 'react-icons/fa';
import ProfileSection from './ProfileSection';
import SecuritySection from './SecuritySection';
import AccountsSection from './AccountsSection';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import dummyUserData from '../../data/userData';
import { fetchUserData } from '../../utils/api';
import { toast } from 'react-toastify';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUserData();
        if (data) {
          setUserData(data);
        } else {
          throw new Error("User data not found");
        }
      } catch (error) {
        console.error(error);
        toast.error('Failed to load user data. Using dummy data.');
        setUserData(dummyUserData);
      }
    };
    loadUserData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#fefefe' }}>
      <InternalTabBar />

      <Box sx={{ flexGrow: 1, marginLeft: '11%', padding: 0 }}>
        <Box
          sx={{
            maxWidth: '1200px',
            margin: '0 auto',
            backgroundColor: '#fefefe',
            padding: { xs: 2, md: 4 },
          }}
        >
          <Typography
            level="h4"
            gutterBottom
            sx={{ fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Settings
          </Typography>

          <Box sx={{ textAlign: 'right', marginBottom: 3 }}>
            <Button
              component={RouterLink}
              to="/profile"
              variant="outlined"
              color="neutral"
              startDecorator={<FaUser />}
              sx={{
                fontSize: '1rem',
                textTransform: 'none',
                borderRadius: '30px',
                '&:hover': {
                  backgroundColor: '#e6f7f2',
                },
              }}
            >
              Back to Profile
            </Button>
          </Box>

          <Tabs value={activeTab} onChange={handleTabChange} sx={{ backgroundColor: '#fefefe' }}>
            <TabList sx={{ justifyContent: 'center', marginBottom: 2 }}>
              <Tab value={0} sx={{ padding: '12px 16px', minHeight: '48px' }}>
                <FaUser style={{ marginRight: 8 }} />
                Personal
              </Tab>
              <Tab value={1} sx={{ padding: '12px 16px', minHeight: '48px' }}>
                <FaLock style={{ marginRight: 8 }} />
                Security
              </Tab>
              <Tab value={2} sx={{ padding: '12px 16px', minHeight: '48px' }}>
                <FaUniversity style={{ marginRight: 8 }} />
                Accounts
              </Tab>
            </TabList>

            <TabPanel value={0} sx={{ padding: 0 }}>
              <ProfileSection userData={userData} />
            </TabPanel>
            <TabPanel value={1} sx={{ padding: 0 }}>
              <SecuritySection userData={userData} />
            </TabPanel>
            <TabPanel value={2} sx={{ padding: 0 }}>
              <AccountsSection userData={userData} />
            </TabPanel>
          </Tabs>
        </Box>
        <ToastContainer />
      </Box>
    </Box>
  );
};

export default Settings;