import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Input, Button, Stack } from '@mui/joy';
import { FaSave, FaUndo } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { updateUserData } from '../../utils/api';

const ProfileSection = ({ userData }) => {
  const [initialValues, setInitialValues] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (userData) {
      setInitialValues(userData);
    }
  }, [userData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      firstname: '',
      lastname: '',
      email: '',
      phone_number: '',
      street_addr: '',
      city_addr: '',
      state_addr: '',
      country_addr: '',
      zip_code: '',
      dob: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      phone_number: Yup.string().required('Phone number is required'),
      street_addr: Yup.string().required('Street address is required'),
      city_addr: Yup.string().required('City is required'),
      state_addr: Yup.string().required('State is required'),
      country_addr: Yup.string().required('Country is required'),
      zip_code: Yup.string().required('ZIP code is required'),
      dob: Yup.date().required('Date of birth is required'),
    }),
    onSubmit: async (values) => {
      try {
        await updateUserData(values);
        toast.success('Profile updated successfully!');
        setIsChanged(false);
      } catch (error) {
        console.error('Failed to update user data:', error);
        toast.error('Failed to update profile. Please try again.');
      }
    },
  });

  useEffect(() => {
    if (initialValues) {
      const isDifferent = Object.keys(initialValues).some(
        (key) => initialValues[key] !== formik.values[key]
      );
      setIsChanged(isDifferent);
    }
  }, [formik.values, initialValues]);

  const handleReset = () => {
    formik.resetForm();
    setIsChanged(false);
    toast.info('Changes have been reset.');
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#fefefe' }}>
      <Typography level="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#40826D', marginBottom: 3 }}>
        Profile Information
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {[
            { label: 'First Name', name: 'firstname' },
            { label: 'Last Name', name: 'lastname' },
            { label: 'Email', name: 'email' },
            { label: 'Phone Number', name: 'phone_number' },
            { label: 'Street Address', name: 'street_addr' },
            { label: 'City', name: 'city_addr' },
            { label: 'State', name: 'state_addr' },
            { label: 'Country', name: 'country_addr' },
            { label: 'ZIP Code', name: 'zip_code' },
            { label: 'Date of Birth', name: 'dob', type: 'date' },
          ].map((field) => (
            <Grid item xs={12} sm={6} key={field.name}>
              <Input
                fullWidth
                id={field.name}
                name={field.name}
                type={field.type || 'text'}
                placeholder={field.label}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                helperText={formik.touched[field.name] && formik.errors[field.name]}
                sx={{ backgroundColor: '#ffffff' }}
              />
            </Grid>
          ))}
        </Grid>

        <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            startDecorator={<FaSave />}
            disabled={!isChanged}
          >
            Save Changes
          </Button>
          {isChanged && (
            <Button
              type="button"
              variant="outlined"
              color="neutral"
              startDecorator={<FaUndo />}
              onClick={handleReset}
              sx={{
                borderRadius: '30px',
                padding: '8px 20px',
                fontSize: '1rem',
                textTransform: 'none',
                fontWeight: '600',
                '&:hover': {
                  backgroundColor: '#e6f7f2',
                },
              }}
            >
              Reset Changes
            </Button>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default ProfileSection;