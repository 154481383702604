import { populateSubgroups, aggregateGroupData, getRandomPerformanceData, createETFList } from "./assetClassDataGenerators";
import { GLOBAL_ETF_LISTS, DEVELOPED_MARKETS_ETF_LISTS, EMERGING_MARKETS_ETF_LISTS, LARGE_CAP_ETF_LISTS, MID_CAP_ETF_LISTS, SMALL_CAP_ETF_LISTS, SMALL_MID_CAP_ETF_LISTS, TECHNOLOGY_COMMUNICATION_ETF_LISTS, HEALTHCARE_FINANCIAL_ETF_LISTS, CONSUMER_INDUSTRIAL_ENERGY_ETF_LISTS, MOMENTUM_BASED_ETF_LISTS, FUNDAMENTALS_BASED_ETF_LISTS, FIXED_INCOME_ISSUER_TYPE_ETF_LISTS, FIXED_INCOME_MATURITY_ETF_LISTS, REAL_ESTATE_BASED_ETF_LISTS, REAL_ESTATE_PROPERTY_TYPE_ETF_LISTS, COMMODITIES_TYPE_ETF_LISTS, COMMODITIES_INVESTMENT_METHOD_ETF_LISTS } from "./assetLists";

export const expectedReturns = {
  'AI Fund': 0.08,
  Equities: 0.1,
  'Fixed Income': 0.04,
  'Real Estate': 0.06,
  Commodities: 0.05,
};

export const expectedVolatilities = {
  'AI Fund': 0.12,
  Equities: 0.18,
  'Fixed Income': 0.05,
  'Real Estate': 0.15,
  Commodities: 0.2,
};

// Subgroup codes and display names
export const subgroupCodes = {
  // Global subgroups
  global_broad: 'GB',
  global_specialized: 'GS',

  // Developed Markets subgroups
  north_america: 'NA',
  europe: 'EU',
  asia_pacific: 'AP',

  // Emerging Markets subgroups
  emerging_markets_broad: 'EMB',
  emerging_markets_asia: 'EMA',
  emerging_markets_latin_america_africa: 'EMLA',
  emerging_markets_small_cap: 'EMSC',

  // Size-based subgroups
  large_cap_core: 'LC_CORE',
  large_cap_growth: 'LC_GROWTH',
  large_cap_value: 'LC_VALUE',
  large_cap_specialized: 'LC_SPEC',
  large_cap_tech_focus: 'LC_TECH',
  mid_cap_core: 'MC_CORE',
  mid_cap_growth: 'MC_GROWTH',
  mid_cap_value: 'MC_VALUE',
  small_cap_core: 'SC_CORE',
  small_cap_growth: 'SC_GROWTH',
  small_cap_value: 'SC_VALUE',
  small_mid_cap_diversified: 'SC_MC_DIV',
  small_mid_cap_growth_focus: 'SC_MC_GROWTH',
  small_mid_cap_value_focus: 'SC_MC_VALUE',

  // Technology & Communication sector-based subgroups
  technology: 'TECH',
  semiconductors: 'SEMI',
  communication_infrastructure: 'COM_INFRA',
  core_cybersecurity: 'CYBER',
  network_security: 'NET_SEC',

  // Financials & Healthcare sector-based subgroups
  financial_services: 'FIN',
  healthcare_broad: 'HEALTH',
  biotechnology: 'BIOTECH',
  medical_devices: 'MED_DEV',

  // Consumer, Industrials, & Energy sector-based subgroups
  consumer_discretionary: 'CONS_DIS',
  consumer_staples: 'CONS_STP',
  industrials: 'INDUSTRIAL',
  traditional_energy: 'ENERGY',
  commercial_aerospace: 'AERO',
  renewable_energy: 'RENEW',
  infrastructure: 'INFRA',
  utilities: 'UTIL',
  automotive: 'AUTO',

  // Factor-based subgroups
  bull_market_leveraged: 'BML',
  sector_specific_leveraged: 'SSL',
  broad_market_inverse: 'BMI',
  sector_specific_inverse: 'SSI',
  esg_focus: 'ESG',
  growth: 'GRW',
  quality: 'QLT',
  value_vs_growth: 'VVG',

  // Real Estate Region-based subgroups
  domestic_real_estate: 'DRE',
  international_real_estate: 'IRE',
  diversified_global_real_estate: 'DGRE',
  // Real Estate Property Type
  residential_real_estate: 'RRE',
  commercial_real_estate: 'CRE',
  industrial_real_estate: 'INRE',
  // Fixed Income Issuer Type
  government_bonds: 'GB',
  corporate_bonds: 'CB',
  municipal_bonds: 'MB',
  international_bonds: 'IB',
  // Fixed Income Maturity
  short_term_bonds: 'STB',
  intermediate_term_bonds: 'ITB',
  long_term_bonds: 'LTB',
  // Commodities Type
  metals: 'MTL',
  energy: 'NRG',
  agriculture: 'AGR',
  // Commodities Investment Method
  physical: 'PHY',
  futures: 'FTR',
};

export const subgroupDisplayNames = {
  // Factor-based subgroups
  bull_market_leveraged: 'Leverage Bull',
  sector_specific_leveraged: 'Leverage Sector',
  broad_market_inverse: 'Inverse Bull',
  sector_specific_inverse: 'Inverse Sector',
  esg_focus: 'ESG Focus',
  growth: 'Growth',
  quality: 'Quality',
  value_vs_growth: 'Value vs Growth',

  // Global subgroups
  global_broad: 'Global Broad',
  global_specialized: 'Global Specialized',

  // Developed Markets subgroups
  north_america: 'North America',
  europe: 'Europe',
  asia_pacific: 'Asia Pacific',

  // Emerging Markets subgroups
  emerging_markets_broad: 'Emerging Markets Broad',
  emerging_markets_asia: 'Emerging Asia',
  emerging_markets_latin_america_africa: 'Emerging Lat Am & Africa',
  emerging_markets_small_cap: 'Emerging Small Cap',

  // Size-based subgroups
  large_cap_core: 'Large Cap Core',
  large_cap_growth: 'Large Cap Growth',
  large_cap_value: 'Large Cap Value',
  large_cap_specialized: 'Large Cap Specialized',
  large_cap_tech_focus: 'Large Cap Tech Focus',
  mid_cap_core: 'Mid Cap Core',
  mid_cap_growth: 'Mid Cap Growth',
  mid_cap_value: 'Mid Cap Value',
  small_cap_core: 'Small Cap Core',
  small_cap_growth: 'Small Cap Growth',
  small_cap_value: 'Small Cap Value',
  small_mid_cap_diversified: 'Small & Mid Cap Diversified',
  small_mid_cap_growth_focus: 'Small & Mid Cap Growth Focus',
  small_mid_cap_value_focus: 'Small & Mid Cap Value Focus',

  // Tech & Communication sector-based subgroups
  technology: 'Technology',
  semiconductors: 'Semiconductors',
  communication_infrastructure: 'Communication Infrastructure',
  core_cybersecurity: 'Cybersecurity',
  network_security: 'Network Security',

  // Financials & Healthcare sector-based subgroups
  financial_services: 'Financial Services',
  healthcare_broad: 'Healthcare',
  biotechnology: 'Biotechnology',
  medical_devices: 'Medical Devices',

  // Consumer, Industrials, & Energy sector-based subgroups
  consumer_discretionary: 'Consumer Discretionary',
  consumer_staples: 'Consumer Staples',
  industrials: 'Industrials',
  traditional_energy: 'Traditional Energy',
  commercial_aerospace: 'Commercial Aerospace',
  renewable_energy: 'Renewable Energy',
  infrastructure: 'Infrastructure',
  utilities: 'Utilities',
  automotive: 'Automotive',

  // Real Estate Region-based subgroups
  domestic_real_estate: 'Domestic',
  international_real_estate: 'International',
  diversified_global_real_estate: 'Diversified Global',
  // Real Estate Property Type
  residential_real_estate: 'Residential',
  commercial_real_estate: 'Commercial',
  industrial_real_estate: 'Industrial',
  // Fixed Income Issuer Type
  government_bonds: 'Government Bonds',
  corporate_bonds: 'Corporate Bonds',
  municipal_bonds: 'Municipal Bonds',
  international_bonds: 'International Bonds',
  // Fixed Income Maturity
  short_term_bonds: 'Short-Term Bonds',
  intermediate_term_bonds: 'Intermediate-Term Bonds',
  long_term_bonds: 'Long-Term Bonds',
  // Commodities Type
  metals: 'Metals',
  energy: 'Energy',
  agriculture: 'Agriculture',
  // Commodities Investment Method
  physical: 'Physical',
  futures: 'Futures',
};

// Equities Data
export const equitiesData = {
  name: 'Equities',
  description:
    'Equities represent ownership in companies and offer growth potential.',
  longDescription:
    'Equities, also known as stocks, represent ownership shares in corporations. They offer investors the potential for capital appreciation and dividends. Equities can be classified based on various factors such as region, size, sector, and investment style, providing a diverse range of investment opportunities.',
  
  // Updated subclasses based on categories you've defined
  subclasses: [
    'Global', 
    'Developed', 
    'Emerging', 
    'Large', 
    'Mid', 
    'Small', 
    'Small & Mid',
    'Tech & Communication', 
    'Financials & Healthcare', 
    'Consumer, Indust, & Energy', 
    'Momentum',
    'Fundamentals'
  ],
  
  performanceData: [], // Will be populated later
  
  groups: {
    Global: {
      description: 'Equities with global exposure, including broad and specialized global ETFs.',
      subgroups: {},
    },
    Developed: {
      description: 'Equities from developed markets, including North America, Europe, and Asia-Pacific.',
      subgroups: {},
    },
    Emerging: {
      description: 'Equities from emerging markets, including Asia, Latin America, and Africa.',
      subgroups: {},
    },
    Large: {
      description: 'Large-cap equities categorized into core, growth, and value ETFs.',
      subgroups: {},
    },
    Mid: {
      description: 'Mid-cap equities categorized into core, growth, and value ETFs.',
      subgroups: {},
    },
    Small: {
      description: 'Small-cap equities categorized into core, growth, and value ETFs.',
      subgroups: {},
    },
    "Small & Mid": {
      description: 'Diversified small and mid-cap equities, as well as growth and value-focused ETFs.',
      subgroups: {},
    },
    "Tech & Communication": {
      description: 'Equities focused on technology, communication infrastructure, and semiconductors.',
      subgroups: {},
    },
    "Financials & Healthcare": {
      description: 'Equities from the financial and healthcare sectors, including biotechnology and medical devices.',
      subgroups: {},
    },
    "Consumer, Indust, & Energy": {
      description: 'Equities from consumer discretionary, industrials, energy, and related sectors.',
      subgroups: {},
    },
    Momentum: {
      description: 'Equities emphasizing momentum factors through leverage and inverse exposures.',
      subgroups: {},
    },
    Fundamentals: {
      description: 'Equities emphasizing fundamental factors like value, quality, and company growth.',
      subgroups: {},
    }
  },
};


// Fixed Income Data
export const fixedIncomeData = {
  name: 'Fixed Income',
  description: 'Investments in debt securities that provide regular income.',
  longDescription:
    'Fixed income securities provide a return in the form of fixed periodic payments and the eventual return of principal at maturity. They are typically issued by governments, corporations, and other entities to finance operations and projects. Fixed income investments are considered less risky than equities and are used to diversify portfolios, generate income, and preserve capital.',
  subclasses: ['Issuer Type', 'Maturity'],
  performanceData: [], // Will be populated later
  groups: {
    'Issuer Type': {
      description: 'Fixed income investments categorized by issuer type.',
      subgroups: {},
    },
    Maturity: {
      description: 'Fixed income investments categorized by maturity duration.',
      subgroups: {},
    },
  },
};

// Real Estate Data
export const realEstateData = {
  name: 'Real Estate',
  description:
    'Investments in real estate through Real Estate Investment Trusts (REITs).',
  longDescription:
    'Real estate investments provide exposure to the property market without the need to directly own or manage properties. REITs are companies that own, operate, or finance income-generating real estate across various property sectors. They offer investors regular income streams, diversification, and long-term capital appreciation potential.',
  subclasses: ['Region', 'Property Type'],
  performanceData: [], // Will be populated later
  groups: {
    Region: {
      description: 'Real estate investments categorized by geographical focus.',
      subgroups: {},
    },
    'Property Type': {
      description: 'Real estate investments categorized by property type.',
      subgroups: {},
    },
  },
};

// Commodities Data
export const commoditiesData = {
  name: 'Commodities',
  description:
    'Investments in physical goods like metals, energy resources, and agricultural products.',
  longDescription:
    'Commodities are basic goods used in commerce that are interchangeable with other goods of the same type. Investors can gain exposure to commodities through commodity-linked ETFs, which invest in futures contracts or the physical goods themselves. Commodities can act as a hedge against inflation and add diversification to a portfolio.',
  subclasses: ['Commodity Type', 'Investment Method'],
  performanceData: [], // Will be populated later
  groups: {
    'Commodity Type': {
      description: 'Commodities categorized by type.',
      subgroups: {},
    },
    'Investment Method': {
      description: 'Commodities categorized by investment method.',
      subgroups: {},
    },
  },
};

// Populate Equities Subgroups

// Global Equities
populateSubgroups(
  equitiesData.groups.Global,
  GLOBAL_ETF_LISTS,
  'global equities'
);

// Developed Markets Equities
populateSubgroups(
  equitiesData.groups.Developed,
  DEVELOPED_MARKETS_ETF_LISTS,
  'developed markets equities'
);

// Emerging Markets Equities
populateSubgroups(
  equitiesData.groups.Emerging,
  EMERGING_MARKETS_ETF_LISTS,
  'emerging markets equities'
);

// Large Cap Equities
populateSubgroups(
  equitiesData.groups.Large,
  LARGE_CAP_ETF_LISTS,
  'large-cap equities'
);

// Mid Cap Equities
populateSubgroups(
  equitiesData.groups.Mid,
  MID_CAP_ETF_LISTS,
  'mid-cap equities'
);

// Small Cap Equities
populateSubgroups(
  equitiesData.groups.Small,
  SMALL_CAP_ETF_LISTS,
  'small-cap equities'
);

// Small & Mid Cap Equities
populateSubgroups(
  equitiesData.groups["Small & Mid"],
  SMALL_MID_CAP_ETF_LISTS,
  'small and mid-cap equities'
);

// Technology & Communication Sector Equities
populateSubgroups(
  equitiesData.groups["Tech & Communication"],
  TECHNOLOGY_COMMUNICATION_ETF_LISTS,
  'technology and communication equities'
);

// Financials & Healthcare Sector Equities
populateSubgroups(
  equitiesData.groups["Financials & Healthcare"],
  HEALTHCARE_FINANCIAL_ETF_LISTS,
  'financials and healthcare equities'
);

// Consumer, Indust, & Energy Sector Equities
populateSubgroups(
  equitiesData.groups["Consumer, Indust, & Energy"],
  CONSUMER_INDUSTRIAL_ENERGY_ETF_LISTS,
  'consumer, industrials, and energy equities'
);

// Factor-Based Equities
populateSubgroups(
  equitiesData.groups.Momentum,
  MOMENTUM_BASED_ETF_LISTS,
  'momentum-based equities'
);

populateSubgroups(
  equitiesData.groups.Fundamentals,
  FUNDAMENTALS_BASED_ETF_LISTS,
  'fundamentals-based equities'
);

// Populate Fixed Income Subgroups
populateSubgroups(
  fixedIncomeData.groups['Issuer Type'],
  FIXED_INCOME_ISSUER_TYPE_ETF_LISTS,
  'issuer types'
);
populateSubgroups(
  fixedIncomeData.groups.Maturity,
  FIXED_INCOME_MATURITY_ETF_LISTS,
  'maturity durations'
);

// Populate Real Estate Subgroups
populateSubgroups(
  realEstateData.groups.Region,
  REAL_ESTATE_BASED_ETF_LISTS,
  'geographical regions'
);
populateSubgroups(
  realEstateData.groups['Property Type'],
  REAL_ESTATE_PROPERTY_TYPE_ETF_LISTS,
  'property types'
);

// Populate Commodities Subgroups
populateSubgroups(
  commoditiesData.groups['Commodity Type'],
  COMMODITIES_TYPE_ETF_LISTS,
  'commodity types'
);
populateSubgroups(
  commoditiesData.groups['Investment Method'],
  COMMODITIES_INVESTMENT_METHOD_ETF_LISTS,
  'investment methods'
);

// Aggregate Group Data
Object.values(equitiesData.groups).forEach((group) => {
  aggregateGroupData(group);
});
Object.values(fixedIncomeData.groups).forEach((group) => {
  aggregateGroupData(group);
});
Object.values(realEstateData.groups).forEach((group) => {
  aggregateGroupData(group);
});
Object.values(commoditiesData.groups).forEach((group) => {
  aggregateGroupData(group);
});

export const assetClasses = [
  'Equities',
  'Fixed Income',
  'Real Estate',
  'Commodities',
];