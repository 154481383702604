import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import beginnerFriendlyIcon from '../../assets/images/beginner-friendly-icon.png';
import maximizeGrowthIcon from '../../assets/images/maximize-growth-icon.png';
import riskBudgetingIcon from '../../assets/images/risk-budgeting-icon.png';

const Approach = () => {
  const approachItems = [
    {
      label: 'CORE',
      img: maximizeGrowthIcon, // Change the image accordingly
      title: 'Market Exposure',
      description:
        'Our Core Fund provides balanced exposure to a wide range of asset classes, including equities, fixed-income, real estate, and commodities. By using advanced risk budgeting models across assets and factors, the Core Fund ensures consistent growth and stability, making it an ideal choice for passive long-term investing.',
    },
    {
      label: 'AI',
      img: riskBudgetingIcon, // Change the image accordingly
      title: 'Systematic Strategies',
      description:
        'Our AI Fund leverages cutting-edge machine learning and large language models (LLMs) to actively manage portfolios. By integrating real-time news data processing and advanced AI strategies, the AI Fund aims to deliver superior stock-picking insights and market-beating performance.',
    },
    {
      label: 'TOOLS',
      img: beginnerFriendlyIcon, // Change the image accordingly
      title: 'Custom Investing Suite',
      description:
        'Our proprietary Tooling Suite offers clients institutional-grade investment tools, including asset clustering, risk modeling, portfolio optimization, and backtesting. This suite replicates the key components of our advanced investment system, giving users the ability to manage portfolios with precision and transparency.',
    },
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        bgcolor: '#f9f9f9',
        py: { xs: 6, md: 10 },
        textAlign: 'center',
        px: { xs: 2, md: 4 },
      }}
    >
      <Typography
        level="h4"
        sx={{ fontSize: { xs: '2.5rem', md: '3rem' }, color: '#2f3b52', mb: 8, fontWeight: 'bold' }}
      >
        Our Approach to Investing
      </Typography>
      <Grid container spacing={4} sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        {approachItems.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Box
              sx={{
                bgcolor: '#ffffff',
                borderRadius: '100px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                p: { xs: 3, md: 4 },
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              <Typography
                level="subtitle1"
                sx={{
                  fontSize: '1.8rem',
                  fontWeight: 'bold',
                  color: '#40826D',
                  mb: 2,
                }}
              >
                {item.label}
              </Typography>
              <img
                src={item.img}
                alt={item.title}
                style={{ color: '#40826D', width: '150px', height: '120px', marginBottom: '20px' }}
              />
              <Typography level="h5" sx={{ fontSize: '1.8rem', color: '#2f3b52', mb: 2, fontWeight: '600' }}>
                {item.title}
              </Typography>
              <Typography
                level="body1"
                sx={{ color: '#5a6b7d', fontSize: '1.1rem', lineHeight: 1.6, mb: 2, px: { xs: 1, md: 2 } }}
              >
                {item.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Approach;