// Trading.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import InternalTabBar from '../../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Trading = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Trading
        </Typography>

        {/* Main High-Level Description */}
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Aligning Trades with Your Investment Objectives
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
         Trading ensures that the portfolio remains aligned with our investment objectives over time.
          We balance the benefits of portfolio adjustments with the costs involved to maximize efficiency.
        </Typography>

        {/* Secondary Section Header */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Our Trading Strategy
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Our approach to trading balances rebalancing, cost management, and adaptability, ensuring your portfolio
            remains optimal in changing market conditions.
          </Typography>
        </Box>

        {/* Trading Sections */}
        <Box>
          <TradingSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Trading;

// TradingSections Component
const TradingSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Trading Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <TradingCard title="Set Rebalancing Frequency">
          <Typography sx={{ color: '#333' }}>
            We strategically determine rebalancing intervals to ensure portfolio alignment without excessive trading, using periodic, threshold-based, and event-driven approaches.
          </Typography>
        </TradingCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <TradingCard title="Transaction Cost Management">
          <Typography sx={{ color: '#333' }}>
            Balancing transaction costs with expected returns, we employ cost-efficient execution, smart algorithms, and optimized fee structures to enhance portfolio performance.
          </Typography>
        </TradingCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <TradingCard title="Dynamic Adjustment">
          <Typography sx={{ color: '#333' }}>
            Our strategy adjusts dynamically based on market conditions, integrating real-time data and adaptive strategies to maintain flexibility and responsiveness.
          </Typography>
        </TradingCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <TradingCard title="Risk Management">
          <Typography sx={{ color: '#333' }}>
            Risk management is central to our trading, incorporating stop-loss orders, position sizing, and diversification to protect investments and control volatility.
          </Typography>
        </TradingCard>
      </Grid>
    </Grid>
  </Box>
);

// TradingCard Component
const TradingCard = ({ title, children }) => (
    <Box sx={{
        padding: '1.5rem',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'translateY(-5px)',
        },
    }}>
    <Typography
        sx={{
            fontSize: '1.25rem',
            fontWeight: '600',
            color: '#40826D',
            marginBottom: '1rem',
        }}
    >
        {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
        {children}
    </Typography>
    </Box>
);

TradingCard.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
