import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Input,
  List,
  ListItem,
  Button,
  Drawer,
} from '@mui/joy';
import { Add, Delete } from '@mui/icons-material';
import PortfolioWorkspace from './PortfolioWorkspace';
import { etfTreeData } from '../../../data/assetTree';
import InternalTabBar from '../../../components/tabs/InternalTabBar';

const PortfolioArena = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [activePortfolioIndex, setActivePortfolioIndex] = useState(null);

  const getAllSymbols = (tree) => {
    let symbols = [];
    const traverse = (node) => {
      for (const key in node) {
        if (node.hasOwnProperty(key)) {
          const subNode = node[key];
          if (Array.isArray(subNode)) {
            symbols = symbols.concat(subNode);
          } else {
            traverse(subNode);
          }
        }
      }
    };
    if (tree.hasOwnProperty('Portfolio')) {
      traverse(tree.Portfolio);
    }
    return symbols;
  };

  const handleAddPortfolio = () => {
    const allSymbols = getAllSymbols(etfTreeData);
    const newPortfolio = {
      name: `Portfolio ${portfolios.length + 1}`,
      selectedAssets: allSymbols,
      clusteringOptions: { assetHierarchy: etfTreeData },
      riskModelOptions: {},
      alphaModelOptions: {},
      portfolioConstructionOptions: {},
      tradingOptions: {},
      backtestOptions: {},
      backtestResults: {},
    };
    setPortfolios([...portfolios, newPortfolio]);
    setActivePortfolioIndex(portfolios.length);
  };

  const handleSelectPortfolio = (index) => {
    setActivePortfolioIndex(index);
  };

  const handleRemovePortfolio = (index) => {
    const newPortfolios = portfolios.filter((_, i) => i !== index);
    setPortfolios(newPortfolios);
    if (activePortfolioIndex === index) {
      setActivePortfolioIndex(newPortfolios.length > 0 ? 0 : null);
    } else if (activePortfolioIndex > index) {
      setActivePortfolioIndex(activePortfolioIndex - 1);
    }
  };

  const handleRenamePortfolio = (index, newName) => {
    const newPortfolios = [...portfolios];
    newPortfolios[index].name = newName;
    setPortfolios(newPortfolios);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#fefefe' }}>
      {/* Left Sidebar (Internal Tab Bar) */}
      <InternalTabBar />

      {/* Main Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          marginLeft: '11%',
          maxWidth: 'calc(100% - 300px)', // Adjusting for right drawer width
          overflowY: 'auto',
        }}
      >
        <Box sx={{ flexGrow: 1, padding: 4 }}>
          {portfolios.length > 0 && activePortfolioIndex !== null ? (
            <PortfolioWorkspace
              portfolio={portfolios[activePortfolioIndex]}
              updatePortfolio={(updatedPortfolio) => {
                const newPortfolios = [...portfolios];
                newPortfolios[activePortfolioIndex] = updatedPortfolio;
                setPortfolios(newPortfolios);
              }}
            />
          ) : (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography level="h4">Welcome to Portfolio Arena</Typography>
              <Typography sx={{ mt: 2 }}>
                Click "New Portfolio" on the right to start building your investment portfolio.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Right Sidebar (Portfolio Management) - Visible by Default */}
      <Box
        sx={{
          width: 300,
          borderLeft: '1px solid #ccc',
          padding: 2,
          backgroundColor: '#fff',
        }}
      >
        <Typography level="h5" sx={{ mb: 2 }}>
          Portfolios
        </Typography>
        <Button
          variant="solid"
          startDecorator={<Add />}
          onClick={handleAddPortfolio}
          fullWidth
        >
          New Portfolio
        </Button>
        <List sx={{ mt: 2 }}>
          {portfolios.map((portfolio, index) => (
            <ListItem
              key={index}
              selected={index === activePortfolioIndex}
              onClick={() => handleSelectPortfolio(index)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                mb: 1,
                backgroundColor:
                  index === activePortfolioIndex ? '#eee' : 'transparent',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#eee',
                },
              }}
            >
              <Input
                variant="plain"
                value={portfolio.name}
                onChange={(e) => handleRenamePortfolio(index, e.target.value)}
                sx={{ flexGrow: 1 }}
              />
              <IconButton
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemovePortfolio(index);
                }}
              >
                <Delete />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default PortfolioArena;



// // src/pages/PortfolioArena/PortfolioArena.js

// import React, { useState } from 'react';
// import {
//   Box,
//   Typography,
//   IconButton,
//   Input,
//   List,
//   ListItem,
//   Button,
// } from '@mui/joy';
// import { Add, Delete } from '@mui/icons-material';
// import PortfolioWorkspace from './PortfolioWorkspace';
// import { etfTreeData } from '../../../data/assetTree'; // Ensure the path is correct
// import InternalTabBar from '../../../components/tabs/InternalTabBar';

// const PortfolioArena = () => {
//   const [portfolios, setPortfolios] = useState([]);
//   const [activePortfolioIndex, setActivePortfolioIndex] = useState(null);

//   const getAllSymbols = (tree) => {
//     let symbols = [];
//     const traverse = (node) => {
//       for (const key in node) {
//         if (node.hasOwnProperty(key)) {
//           const subNode = node[key];
//           if (Array.isArray(subNode)) {
//             symbols = symbols.concat(subNode);
//           } else {
//             traverse(subNode);
//           }
//         }
//       }
//     };
//     // Start traversing from "Portfolio"
//     if (tree.hasOwnProperty('Portfolio')) {
//       traverse(tree.Portfolio);
//     }
//     return symbols;
//   };

//   const handleAddPortfolio = () => {
//     const allSymbols = getAllSymbols(etfTreeData);
//     const newPortfolio = {
//       name: `Portfolio ${portfolios.length + 1}`,
//       selectedAssets: allSymbols, // Initialize with all assets selected
//       clusteringOptions: { assetHierarchy: etfTreeData }, // Initialize with default hierarchy
//       riskModelOptions: {},
//       alphaModelOptions: {},
//       portfolioConstructionOptions: {},
//       tradingOptions: {}, // Initialize tradingOptions
//       backtestOptions: {},
//       backtestResults: {}, // Initialize as an empty object
//     };
//     setPortfolios([...portfolios, newPortfolio]);
//     setActivePortfolioIndex(portfolios.length); // Activate the new portfolio
//   };

//   const handleSelectPortfolio = (index) => {
//     setActivePortfolioIndex(index);
//   };

//   const handleRemovePortfolio = (index) => {
//     const newPortfolios = portfolios.filter((_, i) => i !== index);
//     setPortfolios(newPortfolios);
//     if (activePortfolioIndex === index) {
//       setActivePortfolioIndex(newPortfolios.length > 0 ? 0 : null);
//     } else if (activePortfolioIndex > index) {
//       setActivePortfolioIndex(activePortfolioIndex - 1);
//     }
//   };

//   const handleRenamePortfolio = (index, newName) => {
//     const newPortfolios = [...portfolios];
//     newPortfolios[index].name = newName;
//     setPortfolios(newPortfolios);
//   };

//   return (
//     <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#fefefe' }}>
//       {/* Sidebar for Portfolio List */}
//         {/* Internal Sidebar */}
//         <InternalTabBar />

//       {/* Main Content */}
//       <Box
//         sx={{
//           maxWidth: '100%',
//           margin: '0 auto',
//           marginLeft: '11%', // Account for the tab bar
//           flexGrow: 1,
//           padding: { xs: 2, md: 4 },
//         }}
//       >
//       <Box
//         sx={{
//           width: '250px',
//           borderRight: '1px solid #ccc',
//           padding: 2,
//           overflowY: 'auto',
//         }}
//       >
//         <Typography level="h5" sx={{ mb: 2 }}>
//           Portfolios
//         </Typography>
//         <Button
//           variant="solid"
//           startDecorator={<Add />}
//           onClick={handleAddPortfolio}
//           fullWidth
//         >
//           New Portfolio
//         </Button>
//         <List sx={{ mt: 2 }}>
//           {portfolios.map((portfolio, index) => (
//             <ListItem
//               key={index}
//               selected={index === activePortfolioIndex}
//               onClick={() => handleSelectPortfolio(index)}
//               sx={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 cursor: 'pointer',
//                 mb: 1,
//                 backgroundColor:
//                   index === activePortfolioIndex ? '#eee' : 'transparent',
//                 borderRadius: '4px',
//                 '&:hover': {
//                   backgroundColor: '#eee',
//                 },
//               }}
//             >
//               <Input
//                 variant="plain"
//                 value={portfolio.name}
//                 onChange={(e) => handleRenamePortfolio(index, e.target.value)}
//                 sx={{ flexGrow: 1 }}
//               />
//               <IconButton
//                 size="sm"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handleRemovePortfolio(index);
//                 }}
//               >
//                 <Delete />
//               </IconButton>
//             </ListItem>
//           ))}
//         </List>
//       </Box>

//       {/* Main Content Area */}
//       <Box sx={{ flexGrow: 1, padding: 4 }}>
//         {portfolios.length > 0 && activePortfolioIndex !== null ? (
//           <PortfolioWorkspace
//             portfolio={portfolios[activePortfolioIndex]}
//             updatePortfolio={(updatedPortfolio) => {
//               const newPortfolios = [...portfolios];
//               newPortfolios[activePortfolioIndex] = updatedPortfolio;
//               setPortfolios(newPortfolios);
//             }}
//           />
//         ) : (
//           <Box sx={{ textAlign: 'center', mt: 4 }}>
//             <Typography level="h4">Welcome to Portfolio Arena</Typography>
//             <Typography sx={{ mt: 2 }}>
//               Click "New Portfolio" to start building your investment portfolio.
//             </Typography>
//           </Box>
//         )}
//       </Box>
//       </Box>
//     </Box>
//   );
// };

// export default PortfolioArena;