// Overview.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import InternalTabBar from '../../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Overview = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Overview
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Explore the systematic approach we take to build and manage your investment portfolio.
        </Typography>

        {/* Introductory Explanation */}
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          At AlgoInvest, our investment process is meticulously designed to ensure robust performance, risk management,
          and alignment with your financial goals. Here's how we achieve excellence through our structured approach.
        </Typography>

        {/* Explanation Section */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Our Investment Workflow
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Our investment workflow encompasses several critical steps, each designed to optimize your portfolio's
            performance and ensure comprehensive risk management:
          </Typography>
        </Box>

        {/* Overview Sections */}
        <Box>
          <OverviewSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;

// OverviewSections Component
const OverviewSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Overview Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Asset Selection">
          <Typography sx={{ color: '#333' }}>
            We begin by meticulously selecting a diverse range of assets across major classes:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Diverse Asset Classes:</strong> Equities, fixed income, real estate, and commodities.</li>
              <li><strong>Thematic Grouping:</strong> Assets are organized into themes like regional markets, size-based segments, sector themes, and factor-based investments.</li>
              <li><strong>Granular Selection:</strong> We select assets at a granular level to capture specific market opportunities and characteristics.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Asset Clustering">
          <Typography sx={{ color: '#333' }}>
            After selection, we apply <strong>Asset Clustering</strong> to organize assets into a hierarchical structure:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Create Hierarchical Structures:</strong> Assets are grouped based on economic relationships, sectors, regions, and statistical properties.</li>
              <li><strong>Flexible Grouping:</strong> Combining manual insights with statistical clustering algorithms to achieve balanced groupings.</li>
              <li><strong>Clustering at Every Level:</strong> Enhances our ability to analyze, manage, and optimize the portfolio both globally and within specific themes.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Risk Modeling">
          <Typography sx={{ color: '#333' }}>
            Understanding and managing risk is paramount. Our <strong>Risk Modeling</strong> process involves:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Build Custom Risk Models:</strong> Constructing risk models for the entire portfolio and individual clusters.</li>
              <li><strong>Advanced Analytics:</strong> Utilizing methods like Principal Component Analysis (PCA) and factor models to identify and assess risk factors.</li>
              <li><strong>Adjust Risk Parameters:</strong> Setting desired risk levels and constraints to align with investment objectives and risk tolerance.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Alpha Generation">
          <Typography sx={{ color: '#333' }}>
            To enhance returns, we focus on <strong>Alpha Generation</strong> by:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Integrate Alpha Models:</strong> Applying advanced alpha strategies at each level of the asset hierarchy.</li>
              <li><strong>Diverse Alpha Sources:</strong> Leveraging momentum models (ICA methods), fundamental analysis (financial statements), and AI-driven sentiment analysis (LLMs processing news data).</li>
              <li><strong>Customize Factors:</strong> Tailoring alpha strategies to specific asset clusters enhances the relevance and effectiveness of our alpha capture.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Rho Integration">
          <Typography sx={{ color: '#333' }}>
            Introducing <strong>Rho: Incorporating the Risk-Free Rate</strong>:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Strategic Allocation:</strong> Allocating a portion of the portfolio to risk-free assets, such as high-yield savings accounts.</li>
              <li><strong>Offset Drawdowns:</strong> Using Rho to mitigate losses in underperforming themes.</li>
              <li><strong>Enhance Stability:</strong> Providing a cushion during market volatility.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Portfolio Optimization">
          <Typography sx={{ color: '#333' }}>
            Our <strong>Portfolio Optimization</strong> process ensures that we maximize returns while managing risk:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Select Optimization Methods:</strong> Utilizing techniques like mean-variance optimization, risk parity, and hierarchical risk parity.</li>
              <li><strong>Set Constraints and Preferences:</strong> Implementing guidelines on asset weights, sector exposures, turnover limits, and other investment constraints.</li>
              <li><strong>Adjust Alpha Aggressiveness:</strong> Balancing the pursuit of higher returns with maintaining desired risk levels and controlling for estimation errors.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Trading Strategy">
          <Typography sx={{ color: '#333' }}>
            Defining our <strong>Trading Strategy</strong> to implement the portfolio efficiently:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Set Rebalancing Frequency:</strong> Determining optimal intervals to adjust the portfolio based on expected benefits and transaction costs.</li>
              <li><strong>Define Trading Constraints:</strong> Managing transaction costs, market impact, and liquidity requirements.</li>
              <li><strong>Adjust Tracking Error Target:</strong> Balancing active management with adherence to the benchmark or risk parity portfolio.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Tax Optimization">
          <Typography sx={{ color: '#333' }}>
            Enhancing after-tax returns through <strong>Tax Optimization</strong>:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Select Account Types:</strong> Strategically utilizing taxable, tax-deferred, or tax-exempt accounts.</li>
              <li><strong>Implement Tax Strategies:</strong> Employing techniques like tax-loss harvesting and asset location strategies.</li>
              <li><strong>Optimize Asset Location:</strong> Placing assets in accounts that maximize tax efficiency based on their characteristics.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Backtesting">
          <Typography sx={{ color: '#333' }}>
            Evaluating our strategies through <strong>Backtesting</strong>:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Simulate Historical Performance:</strong> Testing strategies using historical data to assess their effectiveness.</li>
              <li><strong>Adjust Parameters:</strong> Setting realistic conditions and refining models based on backtest results.</li>
              <li><strong>Analyze Results:</strong> Reviewing performance metrics, risk-adjusted returns, and conducting factor attribution.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <OverviewCard title="Deployment">
          <Typography sx={{ color: '#333' }}>
            Finally, <strong>Deployment</strong> puts our investment philosophy into action:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Implement Strategies:</strong> Executing trades and allocating assets as per the optimized portfolio.</li>
              <li><strong>Monitor and Adjust:</strong> Continuously tracking performance, market conditions, and making necessary adjustments.</li>
              <li><strong>Client Alignment:</strong> Ensuring that our strategies remain aligned with client goals and adapting to any changes in objectives or constraints.</li>
            </ul>
          </Typography>
        </OverviewCard>
      </Grid>
    </Grid>
  </Box>
);

// OverviewCard Component
const OverviewCard = ({ title, children }) => (
  <Box sx={{
    padding: '1.5rem',
    backgroundColor: '#fefefe',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // Removed borderRadius and boxShadow to eliminate borders
  }}>
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);


OverviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
