import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import piggyBankIcon from '../../assets/images/piggy-bank-icon.png';
import graphIcon from '../../assets/images/graph-icon.png';

const Systematic = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        bgcolor: '#f9f9f9',
        py: { xs: 4, md: 8 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {/* Feature 1 - Piggy Bank */}
      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 8, md: 12 },
        }}
      >
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={piggyBankIcon} alt="Piggy Bank Icon" style={{ maxWidth: '80%', height: 'auto' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography level="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: '#2f3b52', mb: 2 }}>
            Passive Management
          </Typography>
          <Typography level="body1" sx={{ fontSize: '1.8rem', color: '#40826D', fontWeight: 'bold', mb: 2 }}>
            Core Fund
          </Typography>
          <Typography level="body2" sx={{ fontSize: '1.45rem', color: '#5a6b7d', lineHeight: 1.6 }}>
            A single product that gives market exposure to equities (stocks), fixed-income (bonds), real estate, and commodities.
            Our intricate understanding of risk factors and price momentum factors help us build safe portfolios that are adaptive to recent market conditions.
            This is a superior alternative to the S&P 500 or funds from passive firms like Blackrock, Vanguard, or Fidelity.
          </Typography>
        </Grid>
      </Grid>

      {/* Feature 2 - Graph */}
      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={graphIcon} alt="Graph Icon" style={{ maxWidth: '80%', height: 'auto' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography level="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: '#2f3b52', mb: 2 }}>
            Active Management 
          </Typography>
          <Typography level="body1" sx={{ fontSize: '1.8rem', color: '#40826D', fontWeight: 'bold', mb: 2 }}>
            AI Fund
          </Typography>
          <Typography level="body2" sx={{ fontSize: '1.45rem', color: '#5a6b7d', lineHeight: 1.6 }}>
            A single product that gives significant outperformance and market exposure across the same assets as the Core Fund.
            Alpha comes from our statistical, machine learning, and AI interpretations of financials and news data. This puts
            active strategies like at Citadel or Renaissance Technologies on top of our Core Fund baseline.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Systematic;