
import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactFlow, {
  ReactFlowProvider,
  Background,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { useNavigate } from 'react-router-dom';
import dagre from 'dagre';
import etfTreeData from '../../data/assetTree.yaml';
import InternalTabBar from '../../components/tabs/InternalTabBar';

const treeGuidance = {
  'Fund':
    'The Fund is a diversified asset structure that encompasses a wide range of asset classes and investment themes, providing broad exposure to various markets and strategies.',
  Regional:
    'This section represents ETFs categorized by geographic regions, offering investors exposure to global markets, including developed and emerging economies across the world.',
  Size_Based:
    'This section showcases ETFs based on company market capitalization, allowing investors to focus on large-cap, mid-cap, small-cap, or a blend of sizes to match their investment objectives.',
  Sector_Based:
    'This section displays ETFs grouped by specific economic sectors, enabling investors to target industries such as technology, healthcare, finance, and more for sector-specific investment strategies.',
  Factor_Based:
    'This section includes ETFs that focus on specific investment factors like momentum, value, quality, and growth. These factor-based strategies aim to capture systematic risk premiums and enhance returns.',
  Fixed_Income:
    'This section represents bond ETFs categorized by issuer type and maturity, providing options ranging from government and corporate bonds to municipal and international debt across different durations.',
  Real_Estate:
    'This section shows ETFs focused on real estate investments across various regions and property types, including residential, commercial, and industrial real estate opportunities.',
  Commodities:
    'This section includes ETFs that invest in various commodities like precious metals, energy resources, and agricultural products, offering diversification and a hedge against inflation.',
};

const mainCategories = [
  'Regional',
  'Size_Based',
  'Sector_Based',
  'Factor_Based',
  'Fixed_Income',
  'Real_Estate',
  'Commodities',
];

const nodeWidth = 200;
const nodeHeight = 50;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
    };
  });

  return { nodes: layoutedNodes, edges };
};

// Convert YAML data into a tree structure with only themes (top-level children)
const convertYamlToTreeData = (yamlData, categoryName) => {
  const categoryData = yamlData.ETF_Tree[categoryName];
  if (!categoryData) return null;

  // Only extract the first level themes
  const themes = Object.keys(categoryData);

  return {
    name: categoryName.replace(/_/g, ' '),
    children: themes.map((themeName) => ({
      name: themeName.replace(/_/g, ' '),
    })),
  };
};

// Build nodes and edges for React Flow from tree data
const buildTreeElements = (treeData) => {
  let nodes = [];
  let edges = [];
  let idCounter = 0;

  const buildElements = (data, parentId = null) => {
    const nodeId = `node-${idCounter++}`;
    const nodeLabel = data.name;
    nodes.push({
      id: nodeId,
      data: { label: nodeLabel },
      style: {
        cursor: 'pointer',
        backgroundColor: parentId ? '#e8f5e9' : '#a5d6a7',
        border: '1px solid #777',
        width: nodeWidth,
        height: nodeHeight,
      },
      position: { x: 0, y: 0 },
    });

    if (parentId) {
      edges.push({
        id: `edge-${parentId}-${nodeId}`,
        source: parentId,
        target: nodeId,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        type: 'smoothstep',
      });
    }

    if (data.children) {
      data.children.forEach((child) => buildElements(child, nodeId));
    }
  };

  buildElements(treeData);
  return getLayoutedElements(nodes, edges);
};

const Portfolio = () => {
  const navigate = useNavigate();

  const onNodeClick = (event, node) => {
    const nodeName = node.data.label;
    // const path = `/performance/${nodeName.toLowerCase().replace(/\s+/g, '-')}`;
    const path = `/performance/`; // temporary for YC demo where you'll click on core fund to go from portfolio to plots
    navigate(path);
  };

  // Core Fund section remains as previously implemented
  const coreFundTreeData = {
    name: 'Fund',
    children: mainCategories.map((categoryName) => ({
      name: categoryName.replace(/_/g, ' '),
    })),
  };
  const { nodes: coreFundNodes, edges: coreFundEdges } =
    buildTreeElements(coreFundTreeData);

  // Build category elements with only themes
  const categoryElements = mainCategories
    .map((categoryName) => {
      const treeData = convertYamlToTreeData(etfTreeData, categoryName);
      if (!treeData) return null;
      const { nodes, edges } = buildTreeElements(treeData);
      return { categoryName, nodes, edges };
    })
    .filter(Boolean);

  return (

    <Box sx={{ display: 'flex', backgroundColor: '#fefefe' }}>
      <InternalTabBar />

    <Box sx={{
      maxWidth: '89%',
      margin: '0 auto',
      marginLeft: '11%',
      flexGrow: 1,
    //   padding: { xs: 2, md: 4 },
      marginTop: '-40px', 
      textAlign: 'center',
    }}>

      <Typography
        sx={{
          marginBottom: 2,
          marginTop: '85px',
          fontSize: '2.5rem',
          fontWeight: 'bold',
          color: '#40826D',
          textAlign: 'center',
        }}
      >
        Portfolio Structure
      </Typography>
      <Typography
        sx={{
          marginBottom: 4,
          fontSize: '1.25rem',
          color: '#333',
          textAlign: 'center',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        This page represents the asset allocation of the
        AlgoInvest Core & AI Fund portfolios. You can click on any node to
        investigate the assets, risk factors, specific components, alpha
        factors, and portfolio weights of each theme in more detail.
      </Typography>

      <Box sx={{ marginBottom: '50px', height: '500px' }}>
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2rem',
            fontWeight: 'bold',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Fund
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1rem',
            color: '#333',
            textAlign: 'center',
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          {treeGuidance['Fund']}
        </Typography>
        <ReactFlowProvider>
          <div style={{ width: '100%', height: '400px' }}>
            <ReactFlow
              nodes={coreFundNodes}
              edges={coreFundEdges}
              onNodeClick={onNodeClick}
              nodesDraggable={false}
              nodesConnectable={false}
              zoomOnScroll={false}
              panOnScroll={false}
              panOnDrag={false}
              elementsSelectable={false}
              fitView
            >
              <Background color="#aaa" gap={16} />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </Box>

      {categoryElements.map(({ categoryName, nodes, edges }, index) => (
        <Box key={index} sx={{ marginBottom: '50px', height: '500px' }}>
          <Typography
            sx={{
              marginBottom: 2,
              fontSize: '2rem',
              fontWeight: 'bold',
              color: '#478778',
              textAlign: 'center',
            }}
          >
            {categoryName.replace(/_/g, ' ')}
          </Typography>
          <Typography
            sx={{
              marginBottom: 4,
              fontSize: '1rem',
              color: '#333',
              textAlign: 'center',
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            {treeGuidance[categoryName] ||
              'Explore this category to discover various investment opportunities.'}
          </Typography>
          <ReactFlowProvider>
            <div style={{ width: '100%', height: '400px' }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                // Continuing from where it left off:
                onNodeClick={onNodeClick}
                nodesDraggable={false}
                nodesConnectable={false}
                zoomOnScroll={false}
                panOnScroll={false}
                panOnDrag={false}
                elementsSelectable={false}
                fitView
                >
                <Background color="#aaa" gap={16} />
                </ReactFlow>
            </div>
            </ReactFlowProvider>
        </Box>
        ))}

            <Typography
            sx={{
            marginTop: 4,
            fontSize: '1rem',
            color: '#333',
            textAlign: 'center',
            }}
            >
                Click on the nodes to view performance of individual components.
            </Typography>
        </Box>
    </Box>

    );
};

export default Portfolio;

// import React from 'react';
// import { Box, Typography } from '@mui/material';
// import Tree from 'react-d3-tree';
// import { useNavigate } from 'react-router-dom';
// import etfTreeData from '../../data/assetTree.yaml';

// const treeContainerStyles = {
//   width: '100%',
//   height: '500px',
//   marginBottom: '50px',
// };

// const treeGuidance = {
//   'Fund':
//     'The Fund is a diversified asset structure that encompasses a wide range of asset classes and investment themes, providing broad exposure to various markets and strategies.',
//   Regional:
//     'This section represents ETFs categorized by geographic regions, offering investors exposure to global markets, including developed and emerging economies across the world.',
//   Size_Based:
//     'This section showcases ETFs based on company market capitalization, allowing investors to focus on large-cap, mid-cap, small-cap, or a blend of sizes to match their investment objectives.',
//   Sector_Based:
//     'This section displays ETFs grouped by specific economic sectors, enabling investors to target industries such as technology, healthcare, finance, and more for sector-specific investment strategies.',
//   Factor_Based:
//     'This section includes ETFs that focus on specific investment factors like momentum, value, quality, and growth. These factor-based strategies aim to capture systematic risk premiums and enhance returns.',
//   Fixed_Income:
//     'This section represents bond ETFs categorized by issuer type and maturity, providing options ranging from government and corporate bonds to municipal and international debt across different durations.',
//   Real_Estate:
//     'This section shows ETFs focused on real estate investments across various regions and property types, including residential, commercial, and industrial real estate opportunities.',
//   Commodities:
//     'This section includes ETFs that invest in various commodities like precious metals, energy resources, and agricultural products, offering diversification and a hedge against inflation.',
// };

// const mainCategories = [
//   'Regional',
//   'Size_Based',
//   'Sector_Based',
//   'Factor_Based',
//   'Fixed_Income',
//   'Real_Estate',
//   'Commodities',
// ];

// const Portfolio = () => {
//   const navigate = useNavigate();

//   const handleNodeClick = (nodeData) => {
//     if (!nodeData.children || mainCategories.includes(nodeData.name.replace(/ /g, '_'))) {
//       const path = `/performance/${nodeData.name.toLowerCase().replace(/\s+/g, '-')}`;
//       navigate(path);
//     }
//   };

//   // Custom render function for larger tree nodes with updated styling
//   const renderNode = ({ nodeDatum }) => (
//     <g>
//       <circle
//         r={60} // Increased radius for larger nodes
//         fill="#478778"
//         stroke="#40826D"
//         strokeWidth="3"
//         onClick={() => handleNodeClick(nodeDatum)}
//         style={{ cursor: 'pointer' }}
//       />
//       <text
//         fill="#ffffff"
//         x={-nodeDatum.name.length * 4} // Adjusted for text alignment
//         y={5}
//         fontSize={18} // Increased font size
//         fontWeight="bold"
//         textAnchor="middle"
//         onClick={() => handleNodeClick(nodeDatum)}
//         style={{ cursor: 'pointer', fontFamily: 'Arial, sans-serif' }}
//       >
//         {nodeDatum.name.replace(/_/g, ' ')}
//       </text>
//     </g>
//   );

//   const convertYamlToTreeData = (yamlData, categoryName) => {
//     const categoryData = yamlData.ETF_Tree[categoryName];
//     if (!categoryData) return null;

//     const buildTree = (data) => {
//       if (Array.isArray(data)) {
//         return data.map((etf) => ({ name: etf }));
//       } else {
//         return Object.entries(data).map(([key, value]) => ({
//           name: key,
//           children: buildTree(value),
//         }));
//       }
//     };

//     return {
//       name: categoryName.replace(/_/g, ' '),
//       children: buildTree(categoryData),
//     };
//   };

//   const coreFundTree = {
//     name: 'Fund',
//     children: mainCategories.map((categoryName) => ({
//       name: categoryName.replace(/_/g, ' '),
//     })),
//   };

//   const getTreeTranslate = () => ({
//     x: window.innerWidth / 2,
//     y: 100,
//   });

//   return (
//     <Box sx={{ padding: 4, backgroundColor: '#fefefe' }}>
//       <Typography
//         sx={{
//           marginBottom: 2,
//           marginTop: '85px',
//           fontSize: '2.5rem',
//           fontWeight: 'bold',
//           color: '#40826D',
//           textAlign: 'center',
//         }}
//       >
//         Portfolio Structure
//       </Typography>
//       <Typography
//         sx={{
//           marginBottom: 4,
//           fontSize: '1.25rem',
//           color: '#333',
//           textAlign: 'center',
//           maxWidth: '800px',
//           margin: '0 auto',
//         }}
//       >
//         This page represents the performance for all of the components of the
//         AlgoInvest Core Fund portfolio. You can click on any node to
//         investigate the assets, risk factors, specific components, alpha
//         factors, and portfolio weights of each theme in more detail.
//       </Typography>

//       {/* Core Fund Tree */}
//       <Box sx={{ marginBottom: '50px' }}>
//         <Typography
//           sx={{
//             marginBottom: 2,
//             fontSize: '2rem',
//             fontWeight: 'bold',
//             color: '#478778',
//             textAlign: 'center',
//           }}
//         >
//           Core Fund
//         </Typography>
//         <Typography
//           sx={{
//             marginBottom: 4,
//             fontSize: '1rem',
//             color: '#333',
//             textAlign: 'center',
//             maxWidth: '800px',
//             margin: '0 auto',
//           }}
//         >
//           {treeGuidance['Fund']}
//         </Typography>
//         <div style={treeContainerStyles}>
//           <Tree
//             data={coreFundTree}
//             orientation="vertical"
//             pathFunc="elbow"
//             translate={getTreeTranslate()}
//             nodeSize={{ x: 320, y: 160 }}
//             renderCustomNodeElement={renderNode}
//             zoom={0.4} // Start zoomed out for the Core Fund section
//             zoomable={true}
//             collapsible={false}
//             depthFactor={200}
//           />
//         </div>
//       </Box>

//       {/* Individual Trees for Each Category */}
//       {mainCategories.map((categoryName, index) => {
//         const treeData = convertYamlToTreeData(etfTreeData, categoryName);
//         if (!treeData) return null;

//         return (
//           <Box key={index} sx={{ marginBottom: '50px' }}>
//             <Typography
//               sx={{
//                 marginBottom: 2,
//                 fontSize: '2rem',
//                 fontWeight: 'bold',
//                 color: '#478778',
//                 textAlign: 'center',
//               }}
//             >
//               {categoryName.replace(/_/g, ' ')}
//             </Typography>
//             <Typography
//               sx={{
//                 marginBottom: 4,
//                 fontSize: '1rem',
//                 color: '#333',
//                 textAlign: 'center',
//                 maxWidth: '800px',
//                 margin: '0 auto',
//               }}
//             >
//               {treeGuidance[categoryName] ||
//                 'Explore this category to discover various investment opportunities.'}
//             </Typography>
//             <div id={`tree-container-${index}`} style={treeContainerStyles}>
//               <Tree
//                 data={treeData}
//                 orientation="vertical"
//                 pathFunc="elbow"
//                 translate={getTreeTranslate()}
//                 nodeSize={{ x: 320, y: 160 }}
//                 renderCustomNodeElement={renderNode}
//                 zoom={0.25} // Start even more zoomed out for larger category trees
//                 zoomable={true}
//                 initialDepth={2}
//                 depthFactor={300}
//               />
//             </div>
//           </Box>
//         );
//       })}
//       <Typography
//         sx={{
//           marginTop: 4,
//           fontSize: '1rem',
//           color: '#333',
//           textAlign: 'center',
//         }}
//       >
//         Click on the nodes to view performance of individual components.
//       </Typography>
//     </Box>
//   );
// };

// export default Portfolio;