

// src/pages/PortfolioArena/RiskModeling.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  Option,
  CircularProgress,
  Slider,
} from '@mui/joy';
import PerformanceChart from '../../../components/charts/PerformanceChart';
import CorrelationHeatmap from '../../../components/charts/CorrelationHeatmap';
import MetricsList from '../../../components/charts/MetricsList';
import ConfigurableInlineAssetFolder from './ConfigurableInlineAssetFolder';
import {
  generateInvestmentData,
  generateCorrelationMatrix,
  getRandomStatistics,
  granularityOptions,
  generateRiskFactorReturns,
  generateIdiosyncraticReturns,
} from '../../../data/assetClassDataGenerators';

const BetaModeling = ({
  selectedAssets = [],
  clusteringOptions,
  riskModelOptions,
  setRiskModelOptions,
}) => {
  const [granularity, setGranularity] = useState('1Y');
  const [riskFactorRatio, setRiskFactorRatio] = useState(50); // Default to 50%
  const [runningTests, setRunningTests] = useState({});
  const [nodeResults, setNodeResults] = useState({});
  const [selectedModelRun, setSelectedModelRun] = useState('');
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [returnType, setReturnType] = useState('idiosyncratic'); // 'idiosyncratic' or 'riskFactor'

  useEffect(() => {
    if (riskModelOptions) {
      setNodeResults((prev) => ({ ...prev, ...riskModelOptions }));
      const modelRunPaths = Object.keys(riskModelOptions);
      if (modelRunPaths.length > 0) {
        setSelectedModelRun(modelRunPaths[modelRunPaths.length - 1]);
      }
    }
  }, [riskModelOptions]);

  const toggleNode = (pathKey) => {
    setExpandedNodes((prev) => {
      const next = new Set(prev);
      if (next.has(pathKey)) {
        next.delete(pathKey);
      } else {
        next.add(pathKey);
      }
      return next;
    });
  };

  const handleRunModelForNode = (nodePath, assets) => {
    if (!assets || assets.length === 0) return;

    setRunningTests((prev) => ({ ...prev, [nodePath]: true }));

    // Simulate risk modeling using data generators
    setTimeout(() => {
      // Generate Idiosyncratic Returns
      const idiosyncraticData = {
        chartData: assets.map((asset) => ({
          label: asset,
          data: generateIdiosyncraticReturns(asset, granularity),
        })),
        correlationData: generateCorrelationMatrix(assets),
        metrics: assets.map((asset) => ({
          name: asset,
          ...getRandomStatistics(),
        })),
      };

      // Generate Risk Factor Returns based on riskFactorRatio
      const factorRatioDecimal = riskFactorRatio / 100;
      const n_assets = assets.length;
      const n_factors = Math.min(
        Math.max(1, Math.round(n_assets * factorRatioDecimal)),
        n_assets
      ); // Ensure n_factors <= n_assets
      const riskFactorReturns = generateRiskFactorReturns(n_factors, granularity);
      const riskFactorLabels = riskFactorReturns.map((d) => d.label);
      const riskFactorData = {
        chartData: riskFactorReturns,
        correlationData: generateCorrelationMatrix(riskFactorLabels),
        metrics: riskFactorLabels.map((factor) => ({
          name: factor,
          ...getRandomStatistics(),
        })),
      };

      // Update results specific to this node
      setRiskModelOptions({
        ...riskModelOptions,
        [nodePath]: {
          idiosyncratic: idiosyncraticData,
          riskFactor: riskFactorData,
        },
      });

      setRunningTests((prev) => ({ ...prev, [nodePath]: false }));
    }, 2000);
  };

  const handleSelectModelRun = (event, newValue) => {
    setSelectedModelRun(newValue);
    setReturnType('idiosyncratic'); // Default to idiosyncratic when selecting a new run
  };

  const handleReturnTypeChange = (event, newValue) => {
    setReturnType(newValue);
  };

  const handleRiskFactorRatioChange = (event, newValue) => {
    setRiskFactorRatio(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography level="h5" sx={{ mb: 2 }}>
        Risk Modeling
      </Typography>

      {/* Risk Factor Ratio Selector */}
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 1 }}>Risk Factor to Asset Ratio: {riskFactorRatio}%</Typography>
        <Slider
          value={riskFactorRatio}
          min={1}
          max={100}
          step={1}
          onChange={handleRiskFactorRatioChange}
          valueLabelDisplay="auto"
          marks={[
            { value: 25, label: '25%' },
            { value: 50, label: '50%' },
            { value: 75, label: '75%' },
            { value: 100, label: '100%' },
          ]}
        />
      </Box>

      {/* Granularity Selector */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            placeholder="Select Granularity"
            value={granularity}
            onChange={(e, newValue) => setGranularity(newValue)}
          >
            {granularityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Folder Structure Display */}
      <Box sx={{ mb: 4 }}>
        {clusteringOptions?.assetHierarchy ? (
          <ConfigurableInlineAssetFolder
            node={clusteringOptions.assetHierarchy}
            expandedNodes={expandedNodes}
            toggleNode={toggleNode}
            handleRunModelForNode={handleRunModelForNode}
            runningTests={runningTests}
            selectedAssets={selectedAssets}
          />
        ) : (
          <Typography>No asset hierarchy available.</Typography>
        )}
      </Box>

      {/* Model Run Dropdown Filter */}
      {Object.keys(nodeResults).length > 0 && (
        <Box sx={{ mb: 4 }}>
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              placeholder="Select Model Run to View"
              value={selectedModelRun}
              onChange={handleSelectModelRun}
            >
              {Object.keys(nodeResults).map((nodePath) => (
                <Option key={nodePath} value={nodePath}>
                  {nodePath}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Return Type Selector */}
      {selectedModelRun && (
        <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              placeholder="Select Return Type"
              value={returnType}
              onChange={handleReturnTypeChange}
            >
              <Option value="idiosyncratic">Idiosyncratic Returns</Option>
              <Option value="riskFactor">Risk Factor Returns</Option>
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Display Selected Model Run Results */}
      {selectedModelRun &&
        nodeResults[selectedModelRun] &&
        nodeResults[selectedModelRun][returnType] && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {selectedModelRun}
            </Typography>
            <Box sx={{ height: 600, marginBottom: 4 }}>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#000000',
                }}
              >
                {returnType === 'idiosyncratic'
                  ? 'Idiosyncratic Returns'
                  : 'Risk Factor Returns'}
              </Typography>
              <PerformanceChart
                data={nodeResults[selectedModelRun][returnType].chartData}
                granularity={granularity}
                yAxisLabel="Return"
                tooltipFormat={(value) => `${(value * 100).toFixed(2)}%`}
              />
            </Box>

            {nodeResults[selectedModelRun][returnType].correlationData.length > 1 && (
              <Box sx={{ marginBottom: 4 }}>
                <Typography
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: '#40826D',
                    marginBottom: 2,
                  }}
                >
                  Correlation Heatmap
                </Typography>
                <CorrelationHeatmap
                  correlations={nodeResults[selectedModelRun][returnType].correlationData}
                />
              </Box>
            )}

            <Box sx={{ marginBottom: 4 }}>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#40826D',
                  marginBottom: 2,
                }}
              >
                Metrics
              </Typography>
              <MetricsList metrics={nodeResults[selectedModelRun][returnType].metrics} />
            </Box>
          </Box>
        )}

      {/* Display Loading Indicator for Running Tests */}
      {Object.values(runningTests).some((isRunning) => isRunning) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default BetaModeling;