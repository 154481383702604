import React from 'react';
import { Box, Typography, Link } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar'; // Ensure this component is compatible with Joy UI
import logo from '../../assets/images/logo.png';

const ApplicationSubmitted = () => {
  return (
    <Box sx={{ display: 'flex', paddingTop: '50px', backgroundColor: '#fefefe', minHeight: '100vh' }}>
      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Application Submitted
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
          }}
        >
          Thank you for applying to join AlgoInvest! We are excited to onboard you to the future of investments.
        </Typography>

        {/* Introductory Explanation */}
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          At AlgoInvest, we are a fully systematic market risk manager providing diversified portfolios across all asset classes accessible and liquid on public exchanges like the NYSE. We also incorporate elements of "alpha" typically found at hedge funds, while avoiding risky shorting or leverage strategies.
        </Typography>

        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4 }}
        >
          Our team is reviewing your application, and we'll get back to you shortly. If you have any questions, feel free to reach out to{' '}
          <Link
            href="mailto:support@algoinvest.io"
            sx={{ color: '#007BFF', textDecoration: 'none' }}
          >
            support@algoinvest.io
          </Link>
          .
        </Typography>

        {/* Logo */}
        <Box sx={{ marginTop: 4 }}>
          <Link href="/" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo} alt="Algoinvest Logo" style={{ height: '36px' }} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ApplicationSubmitted;