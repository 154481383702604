import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Input,
} from '@mui/joy';
import { assetNames } from '../../../data/assetNames';
import { etfTreeData } from '../../../data/assetTree';
import SortableTree, {
  changeNodeAtPath,
  addNodeUnderParent,
} from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css'; // Import the styles

const AssetClustering = ({
  selectedAssets,
  clusteringOptions,
  updateClusteringOptions,
}) => {
  const [treeData, setTreeData] = useState([]);

  // Function to build initial hierarchy based on selectedAssets
  const buildInitialHierarchy = (selectedAssets) => {
    const traverse = (node) => {
      const result = {};
      for (const key in node) {
        if (node.hasOwnProperty(key)) {
          const subNode = node[key];
          if (Array.isArray(subNode)) {
            // This is an array of assets
            const filteredAssets = subNode.filter((symbol) =>
              selectedAssets.includes(symbol)
            );
            if (filteredAssets.length > 0) {
              result[key] = filteredAssets;
            }
          } else {
            // This is a subcategory
            const subResult = traverse(subNode);
            if (Object.keys(subResult).length > 0) {
              result[key] = subResult;
            }
          }
        }
      }
      return result;
    };

    const hierarchy = traverse(etfTreeData['Portfolio']);
    return { Portfolio: hierarchy };
  };

  // Load initial hierarchy from clusteringOptions
  useEffect(() => {
    if (clusteringOptions.assetHierarchy) {
      const initialTreeData = transformHierarchyToTreeData(
        clusteringOptions.assetHierarchy
      );
      setTreeData(initialTreeData);
    } else {
      // Initialize with default hierarchy based on selectedAssets
      const initialHierarchy = buildInitialHierarchy(selectedAssets);
      const initialTreeData = transformHierarchyToTreeData(initialHierarchy);
      setTreeData(initialTreeData);
      // Update clusteringOptions with the initial hierarchy
      updateClusteringOptions({
        ...clusteringOptions,
        assetHierarchy: initialHierarchy,
      });
    }
  }, [clusteringOptions.assetHierarchy, selectedAssets]);

  // Transform assetHierarchy into treeData for react-sortable-tree
  const transformHierarchyToTreeData = (hierarchy) => {
    const transformNode = (node) => {
      const result = [];
      for (const key in node) {
        if (node.hasOwnProperty(key)) {
          const subNode = node[key];
          if (Array.isArray(subNode)) {
            // This is an array of assets
            const assets = subNode
              .filter((symbol) => selectedAssets.includes(symbol))
              .map((symbol) => ({
                title: `${symbol} - ${assetNames[symbol] || 'Unknown Asset'}`,
                subtitle: symbol,
                expanded: true,
                isAsset: true, // Custom property to identify asset nodes
              }));
            if (assets.length > 0) {
              result.push({
                title: key.replace(/_/g, ' '),
                expanded: true,
                children: assets,
                isAsset: false,
              });
            }
          } else {
            // This is a subcategory
            const children = transformNode(subNode);
            if (children.length > 0) {
              result.push({
                title: key.replace(/_/g, ' '),
                expanded: true,
                children: children,
                isAsset: false,
              });
            }
          }
        }
      }
      return result;
    };

    // Wrap the hierarchy under 'Portfolio'
    const treeData = [
      {
        title: 'Portfolio',
        expanded: true,
        children: transformNode(hierarchy['Portfolio']),
        isAsset: false,
      },
    ];

    return treeData;
  };

  // Transform treeData back into assetHierarchy
  const transformTreeDataToHierarchy = (treeData) => {
    const transformNode = (nodes) => {
      const result = {};
      nodes.forEach((node) => {
        if (node.isAsset) {
          // Assets should not be at the root level
          return;
        }
        const key = node.title.replace(/ /g, '_');
        if (node.children && node.children.length > 0) {
          // Check if all children are assets
          const allChildrenAreAssets = node.children.every(
            (child) => child.isAsset
          );
          if (allChildrenAreAssets) {
            // This node's children are assets
            result[key] = node.children.map((child) => child.subtitle);
          } else {
            // This node has subcategories
            result[key] = transformNode(node.children);
          }
        } else {
          // Empty category
          result[key] = [];
        }
      });
      return result;
    };

    // Assume that treeData has 'Portfolio' at root
    if (
      treeData.length === 1 &&
      treeData[0].title.replace(/ /g, '_') === 'Portfolio'
    ) {
      const rootNode = treeData[0];
      const hierarchy = transformNode(rootNode.children || []);
      return { Portfolio: hierarchy };
    } else {
      // No 'Portfolio' root, process as before
      return transformNode(treeData);
    }
  };

  // Handle tree data change (e.g., drag and drop)
  const handleTreeOnChange = (newTreeData) => {
    setTreeData(newTreeData);

    // Update the clusteringOptions with the new hierarchy
    const newHierarchy = transformTreeDataToHierarchy(newTreeData);
    updateClusteringOptions({
      ...clusteringOptions,
      assetHierarchy: newHierarchy,
    });
  };

  // Allow renaming of categories
  const renderNode = ({ node, path }) => ({
    title: node.isAsset ? (
      <Typography variant="body2">{node.title}</Typography>
    ) : (
      <Input
        value={node.title}
        onChange={(event) => {
          const name = event.target.value;
          const newNode = { ...node, title: name };
          const newTree = changeNodeAtPath({
            treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: newNode,
          });
          setTreeData(newTree);
          const newHierarchy = transformTreeDataToHierarchy(newTree);
          updateClusteringOptions({
            ...clusteringOptions,
            assetHierarchy: newHierarchy,
          });
        }}
        variant="plain"
        sx={{ flexGrow: 1 }}
      />
    ),
    // No remove button, as asset removal is handled on the AssetSelection page
    buttons: [],
  });

  // Allow creation of new categories
  const addCategory = () => {
    const newTreeData = addNodeUnderParent({
      treeData,
      parentKey: treeData[0].treeIndex || 0, // Add under 'Portfolio' node
      expandParent: true,
      newNode: {
        title: 'New Category',
        expanded: true,
        children: [],
        isAsset: false,
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
    }).treeData;
    setTreeData(newTreeData);
    const newHierarchy = transformTreeDataToHierarchy(newTreeData);
    updateClusteringOptions({
      ...clusteringOptions,
      assetHierarchy: newHierarchy,
    });
  };

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: '#fefefe',
        maxHeight: '80vh',
        overflowY: 'auto',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: 'bold', color: '#40826D', mb: 3 }}
      >
        Asset Clustering
      </Typography>

      <Typography>
        Adjust the default clustering of your selected assets. You can drag and
        drop assets and categories to reorganize the hierarchy. You can also
        rename categories or create new ones.
      </Typography>

      <Button variant="solid" sx={{ mt: 2 }} onClick={addCategory}>
        Add New Category
      </Button>

      <Box sx={{ height: '70vh', mt: 4 }}>
        <SortableTree
          treeData={treeData}
          onChange={handleTreeOnChange}
          generateNodeProps={renderNode}
        />
      </Box>
    </Box>
  );
};

export default AssetClustering;